// ======================================================
// Layout :: Coupons
// ======================================================

// Include components
// ------

@import "seasonalList";

.seasonal {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/seasonal/freestocks-twVS-YjQn9Y-unsplash.webp");
  }
  .newsForm {
    &--btn {
      padding: 8px 30px;
      @include breakpoint-up(sm) {
        margin-top: 20px;
      }
    }
    &--img1,
    &--img2 {
      background-size: cover;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
      z-index: -1;
    }
    &--img1 {
      background-image: url("../../../assets/images/about/stephen.png");
    }
    &--img2 {
      background-image: url("../../../assets/images/about/eldad.png");
      right: 20%;
      @include breakpoint-down(sm) {
        right: 10%;
        margin-top: 50px;
      }
    }
    &--icon1,
    &--icon2,
    &--icon3,
    &--icon4,
    &--icon5 {
      box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
      position: absolute;
      border: 1px solid transparent;
      z-index: -1;
    }
    &--icon1 {
      left: 15%;
      margin-top: -120px;
      background-color: rgba(172, 30, 222, 0.05);
      svg {
        @include size(30px, 30px);
        fill: red;
      }
    }
    &--icon2 {
      left: 30%;
      margin-top: -30px;
      background-color: white;
      svg {
        @include size(30px, 30px);
        fill: #0084ff;
      }
    }
    &--icon3 {
      right: 17%;
      margin-top: -120px;
      background-color: white;
      svg {
        @include size(30px, 30px);
      }
    }
    &--icon4 {
      left: 62%;
      margin-top: -40px;
      background-color: rgba(172, 30, 222, 0.05);
      svg {
        @include size(30px, 30px);
        fill: #329a8f;
      }
    }
    &--icon5 {
      left: 58%;
      margin-top: -140px;
      background-color: rgba(172, 30, 222, 0.2);
      width: 40px;
      height: 40px;
    }
  }
  .labelCheckbox {
    margin-right: 10px;
    p,
    input {
      user-select: none;
    }
  }
}
