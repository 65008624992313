// ======================================================
// Section :: Privacy Policy
// ======================================================

.privacyPolicy {
  .info {
    &--p {
      color: getTheme(text-light);
      font-size: $fontSize-large;
      line-height: 28px;
      margin-bottom: 1rem;
      font-weight: 400;
      text-align: center;
      padding: 10px;
    }
    &--h3 {
      color: getTheme(text);
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 1.5rem;
      font-weight: 600;
    }
    .info-card--p {
      color: #a1a1a1;
      font-size: $fontSize-large;
      line-height: 28px;
      margin-bottom: 1rem;
      font-weight: 400;
    }
    @include breakpoint-down("sm") {
      &--p {
        padding: 3px;
      }
      &--h3 {
        font-size: 24px;
        line-height: 28px;
      }
      .info-card--p {
        font-size: $fontSize-medium;
        line-height: 24px;
      }
    }
    @include breakpoint-down("xs") {
      &--p {
        font-size: $fontSize-base;
        line-height: 22px;
      }
      &--h3 {
        font-size: 22px;
        line-height: 24px;
      }
      .info-card--p {
        font-size: $fontSize-base;
        line-height: 20px;
      }
    }
    .text-icon {
      &--svg {
        svg {
          @include size(50px, 50px);
          #Rectangle_1804,
          #Path_11651,
          #Path_11638 > path:last-child,
          #Polygon_21 > path:last-child {
            fill: getTheme(bg) !important;
          }
        }
      }
      &--p {
        color: getTheme(text);
        font-size: 26px;
        font-weight: 600;
        margin-left: 5px;
      }
      @include breakpoint-down("sm") {
        &--svg {
          svg {
            @include size(35px, 35px);
          }
        }
        &--p {
          font-size: 22px;
        }
      }
    }
  }
}
