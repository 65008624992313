// ======================================================
// Component :: topics
// ======================================================

.helpCenter {
  .topics {
    &--h3 {
      color: getTheme(text-light);
      text-align: center;
      font-size: 2.6rem;
      line-height: 3rem;
      margin-bottom: 1.5rem;
      font-weight: 500;
      &::after {
        display: block;
        content: "";
        width: 70px;
        margin: auto;
        padding-top: 3px;
        border-bottom: 3px solid getTheme(text-light);
      }
    }
    @include breakpoint-up(sm) {
      .topicsLink--h3_1 {
        text-align: left !important;
        &::after {
          margin-left: 0 !important;
        }
      }
      .topicsLink--h3_2 {
        text-align: right !important;
        &::after {
          margin-right: 0 !important;
        }
      }
    }
    .topicsLink {
      margin: 40px 0;
    }
    .accordion {
      padding: 5px 15px;
      @include box-shadow(
        " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important"
      );
      &--action {
        justify-content: flex-end;
        flex-flow: row-reverse;
        h4 {
          font-size: 23px;
          line-height: 26px;
          font-weight: 600;
          color: getTheme(text-light);
        }
        button {
          font-size: 25px;
          margin-right: 10px;
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }

      &--details {
        margin-left: 30px;
        padding: 10px;
        p {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }

    .linkBox {
      @include display(flex);
      justify-content: flex-start !important;
      flex-flow: row !important;
    }

    @include breakpoint-down("sm") {
      .topicsLink {
        margin: 20px 0;
      }
      .accordion {
        padding: 5px;
        &--action {
          h4 {
            font-size: 18px;
            line-height: 20px;
          }
          button {
            font-size: 25px;
            margin-right: 5px;
          }
        }
        &--details {
          margin-left: 20px;
          padding: 5px;
          p {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      &--h3 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0.8rem;
      }
    }
  }
}

[data-isOpen="true"] .accordion--action {
  button {
    svg > * {
      fill: $primary;
      stroke: $primary;
    }
  }
}

:root[data-theme="dark"] .helpCenter {
  .topics {
    .accordion {
      h4 {
        color: $white;
      }
    }
  }
}
