// ======================================================
// Section :: info
// ======================================================

.trends {
  .info {
    &--h4 {
      color: getTheme(text);
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      @include breakpoint-down(sm) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    background-color: #fdf8fe;
    .btn {
      border-radius: 40px;
      width: 400px;
      font-weight: 400;
      svg {
        fill: white;
        margin-right: 20px;
      }
      @include breakpoint-down(sm) {
        width: 300px;
      }
      @include breakpoint-down(sm) {
        width: 250px;
      }
    }
    &__btn-2 {
      background-color: white !important;
      color: $primary;
      svg {
        path {
          fill: $primary;
        }
        margin-right: 20px;
      }
      @extend .shadow-1;
    }
  }
}
