// ======================================================
// Components :: Wrap Border
// ======================================================

.wrap-border {
  &.smaller {
    @include size(25px, 25px);
  }
  &.small {
    @include size(50px, 50px);
  }
  &.medium {
    @include size(100px, 100px);
  }
  &.large {
    @include size(150px, 150px);
  }
  &.larger {
    @include size(200px, 200px);
  }
  &.circle {
    border: 1px solid getTheme(border);
    @include size(80px, 80px);
    padding: 16px;
    border-radius: 50%;
    @extend .shadow-33;
    @include display(flex);
    justify-content: center;
    align-items: center;
    @include breakpoint-down("xs") {
      @include size(60px, 60px);
      padding: 15px;
    }
  }

  &.fill {
    background-color: $primary;
    border-radius: 5px;
    @include display(flex);
    justify-content: center;
    align-items: center;
  }
}
