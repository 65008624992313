// ======================================================
// Section :: coupons details
// ======================================================

.coupons {
  &--details {
    @include display(flex);
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background-color: #fff;
    border-radius: 20px;
    z-index: 9999;
    position: relative;
    margin: 50px !important;
    padding: 30px 40px;
    text-align: center;
    img {
      width: auto;
      max-height: 70px;
      background-color: $white;
    }
    &--logo {
      @extend .shadow-25;
      @include display(flex);
      justify-content: center;
      align-items: center;
      margin: 5px;
      padding: 5px;
      border-radius: 4px;
      background-color: #fff;
    }
    &--close {
      position: absolute;
      top: 12px;
      right: 12px;
      border-radius: 5px;
      padding: 6px;
      @extend .shadow-45;
      background-color: #fff;
      svg {
        fill: $primary;
        @include size(23px, 23px);
      }
    }

    @include breakpoint-down(sm) {
      padding: 10px 20px;
    }

    &--h3 {
      color: getTheme(text);
      font-size: 21px;
      line-height: 21px;
      font-weight: 700;
      text-transform: capitalize;

      @include breakpoint-down(md) {
        font-size: 17px;
        line-height: 18px;
      }
      @include breakpoint-down(xs) {
        font-size: 12px;
        line-height: 13px;
      }
    }

    &--h5 {
      color: #b6b6b6;
      font-size: 16px;
      font-weight: 500;
      @include breakpoint-down("sm") {
        font-size: 15px;
      }
      @include breakpoint-down("xs") {
        font-size: 10px;
        line-height: 10px;
        margin-top: 5px;
      }
    }

    &--copy {
      color: getTheme(text);
      font-size: 14px;
      line-height: 15px;
      font-weight: 600;
      @include breakpoint-down(md) {
        font-size: 12px;
        line-height: 14px;
      }
      @include breakpoint-down(xs) {
        font-size: 10px;
        line-height: 13px;
      }
    }

    &--visible {
      color: getTheme(text);
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      @include breakpoint-down(md) {
        font-size: 13px;
        line-height: 17px;
      }
      @include breakpoint-down(xs) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    &--code {
      color: $primary;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      padding: 10px 50px;
      border: 2px dashed $primary;
      border-radius: 5px;

      @include breakpoint-down(md) {
        padding: 10px 30px;
        font-size: 15px;
        line-height: 17px;
      }
      @include breakpoint-down(xs) {
        padding: 5px 20px;
        font-size: 12px;
        line-height: 13px;
      }
    }
    &--valid {
      color: #b6b6b6;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      @include breakpoint-down(md) {
        font-size: 12px;
        line-height: 14px;
      }
      @include breakpoint-down(xs) {
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
}

:root[data-theme="dark"] .coupons {
  &--details {
    background-color: getTheme(border);
    &--close {
      box-shadow: none;
    }
  }
}
