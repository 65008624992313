// ======================================================
// Components :: Logo
// ======================================================

.logo {
  @include display(inline-flex);
  justify-content: center;
  align-items: center;
  padding: 5px;
  @include size(160px, 60px);
  &--img {
    @include size(100%, 100%);

    &_color {
      background-image: url("../../assets/images/logo/dark.svg");
    }

    &_scroll {
      background-image: url("../../assets/images/logo/primary.svg");
      // margin: 5px;
    }

    &_transparent {
      background-image: url("../../assets/images/logo/light.svg");
    }
  }
  @include breakpoint-down(xs) {
    width: 130px;
    height: 40px;
  }
}

:root[data-theme="dark"] .logo {
  &--img {
    &_color {
      background-image: url("../../assets/images/logo/light.svg");
    }

    &_scroll {
      background-image: url("../../assets/images/logo/primary.svg");
    }
  }
}
