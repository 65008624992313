.adsFormat {
  &--mobile {
    .mobile--img1 {
      background-image: url("../../../assets/images/about/PcDark-01.webp");
    }
    .mobile--img2 {
      background-image: url("../../../assets/images/about/Phone\ Dark-01.png");
    }
    .mobile--img3 {
      background-image: url("../../../assets/images/about/iPad\ Dark-01.webp");
    }
    .mobile--img4 {
      background-image: url("../../../assets/images/about/Laptop\ Dark-01.webp");
    }
    @include display(flex);
    justify-content: center;
    align-items: center;
    flex-flow: column;

    .imgContent {
      width: 450px;
      height: 250px;
      margin-top: 50px;
      @include breakpoint-down(sm) {
        height: 150px;
        margin-top: 30px;
      }
    }
    .tabs--btn {
      padding: 10px 20px !important;
      svg {
        height: 100px !important;
        width: 100% !important;
        path {
          fill: getTheme(text) !important;
        }
      }
      &:hover > svg {
        path {
          fill: $primary !important;
        }
      }
      &--active {
        svg {
          path {
            fill: $primary !important;
          }
        }
      }
    }

    &--box {
      &-2 {
        order: 3;
        @include breakpoint-down(sm) {
          order: 0;
        }
      }
      &-4 {
        order: 3;
        @include breakpoint-down(sm) {
          order: 0;
        }
      }
    }

    &--icons {
      margin-top: 20px;
      @include display(flex);
      justify-content: flex-start;
      align-items: flex-end;
      svg {
        @include size(70px, 70px);
        fill: getTheme("border");
        margin: 10px;
        @include breakpoint-down(sm) {
          @include size(50px, 50px);
        }
      }
    }

    h3 {
      color: $primary;
      font-size: 1.9rem;
      margin-bottom: 1rem;
      text-transform: capitalize;
    }
    P {
      color: getTheme("text");
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
    @include breakpoint-down(sm) {
      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      P {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}

:root[data-theme="dark"] .adsFormat {
  &--mobile {
    .mobile--img1 {
      background-image: url("../../../assets/images/about/Pc\ Light-01.webp");
    }
    .mobile--img2 {
      background-image: url("../../../assets/images/about/Phone\ Light-01.png");
    }
    .mobile--img3 {
      background-image: url("../../../assets/images/about/iPad\ Light-01.webp");
    }
    .mobile--img4 {
      background-image: url("../../../assets/images/about/Laptop\ Light-01.webp");
    }
  }
}
