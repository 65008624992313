// ======================================================
// Layout :: Our company
// ======================================================

.ourCompany {
  background-image: linear-gradient(
      0deg,
      rgba(24, 25, 35, 0.4),
      rgba(24, 25, 35, 0.4)
    ),
    url("../../assets/images/home/t-q-GAxttmVKQtI-unsplash.webp");
  height: auto;

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    @include display("flex");
    justify-content: center;
    align-items: center;
    top: 50%;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    z-index: 5;
    padding: 8px;
    &::before {
      content: none;
    }
    svg {
      padding: 5px;
      polyline {
        stroke: $primary;
      }
    }
    @include transition("background-color 0.2s ease-in");
    &:hover {
      background-color: #fff;
    }
  }
  .slick-prev {
    left: -20px;
    polyline {
      stroke-width: 6;
    }
    &:hover polyline,
    &:focus polyline {
      stroke-width: 8;
    }
    &:active polyline {
      stroke-width: 10;
      transition: all 100ms ease-in-out;
    }
  }

  .slick-next {
    right: -20px;
    polyline {
      stroke-width: 6;
    }
    &:hover polyline,
    &:focus polyline {
      stroke-width: 7;
    }
    &:active polyline {
      stroke-width: 10;
      transition: all 100ms ease-in-out;
    }
  }

  .slick-track {
    div:nth-child(even) {
      .partners--content {
        &--boxC {
          order: 1;
          &--border {
            border: 1px solid getTheme(border);
            border-radius: 0 15px 15px 0;
            border-left: 0;
          }
        }
        .img {
          border-radius: 15px 0 15px 15px;
        }
        @include breakpoint-down("sm") {
          &--img {
            order: 2;
          }
        }
      }
    }
  }
  .partners {
    &--h2 {
      color: #fff;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 10px;
      text-align: left;
    }
    &--content {
      padding: 10px;
      background-color: $white;
      border-radius: 20px;
      border: none;
      margin: 20px;
      &--h4 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 6px;
      }
      &--link {
        font-size: 12px;
        line-height: 15px;
      }
      &--p {
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
      }
      .btn {
        width: 84%;
        border-radius: 5px !important;
        margin: 20px auto;
        @include breakpoint-down(xs) {
          margin-bottom: 40px;
        }
      }
      &--img {
        background-size: cover;
        height: 380px;
        border-radius: 0 15px 15px 15px;

        @include breakpoint-down(md) {
          height: 420px;
          border-radius: 15px !important;
          background-size: cover;
          background-position: top;
        }
        @include breakpoint-down(sm) {
          height: 350px;
        }
        @include breakpoint-down(xs) {
          height: 270px;
        }
      }
      div:first-child {
        padding: 0px;
        justify-content: space-between;
      }
      & > div:first-child div {
        border: 1px solid getTheme(border);
        padding: 10px;
        border-radius: 15px 0 0 15px;
        border-right: 0;
        @include breakpoint-down(xs) {
          border: none;
        }
      }
      @include breakpoint-down(md) {
        margin: 10px;
      }
    }
    &--tab--btn {
      margin-bottom: 25px;

      i {
        font-size: 25px;
        svg {
          fill: #707070;
        }
      }
      button {
        svg {
          @include size(100px, 60px);

          text,
          rect,
          path {
            fill: #707070;
          }
        }
        @include breakpoint-down(sm) {
          svg {
            @include size(70px, 30px);
          }
        }
        @include breakpoint-down(xs) {
          svg {
            @include size(50px, 25px);
          }
        }
      }
      &--active {
        i > svg {
          fill: $white;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $white;
          }
        }
      }
      &:hover {
        i > svg {
          fill: $white;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $white;
          }
        }
      }

      @include breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}

:root[data-theme="dark"] .ourCompany {
  background-image: linear-gradient(
      0deg,
      rgba(24, 25, 35, 0.4),
      rgba(24, 25, 35, 0.4)
    ),
    url("../../assets/images/home/jr-korpa-6uEtb1fLX7E-unsplash.webp");

  .slick-arrow {
    svg {
      polyline {
        stroke: #fff;
      }
    }
    &:hover {
      background-color: $primary;
    }
  }
  .slick-dots li button {
    background-color: #fff;
  }
  .slick-dots li.slick-active {
    border-color: $primary;
    button {
      background-color: $primary;
    }
  }
}
