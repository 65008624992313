// ======================================================
// Layout :: service
// ======================================================

// Include components
// ------

@import "cardServices";
@import "ourSuccess";
@import "topCards";

.service {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.47),
        rgba(24, 25, 35, 0.47)
      ),
      url("../../../assets/images/service/Path-426.webp");
    height: 600px;
    clip-path: polygon(100% 0, 100% 75%, 30% 100%, 0 75%, 0 0);
    padding-top: 90px;

    @include breakpoint-down(lg) {
      clip-path: polygon(100% 0, 100% 80%, 30% 100%, 0 80%, 0 0);
      height: 550px;
      padding-top: 70px;
    }
    @include breakpoint-down(md) {
      clip-path: none;
      &--h1 {
        font-size: 3rem;
        line-height: 4rem;
      }
    }

    @include breakpoint-down(sm) {
      height: 400px;
      &--h1 {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
}
