// ======================================================
// Section :: Form
// ======================================================

.events {
  .form {
    .wrapInput {
      &--form {
        border-radius: 10px;
        padding: 6px 3px;
        @include box-shadow(" rgba(0, 0, 0, 0.05) 0px 0px 0px 1px");
        &--icon {
          svg {
            @include size(16px, 14px);
          }
        }
        &--input {
          &::placeholder {
            font-weight: 500;
          }
        }
      }
      &--label {
        margin: 4px 10px;
      }
      &--error {
        margin: 2px 10px;
        display: flex;
      }
    }
    &--box {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;

      .btn {
        border-radius: 40px;
        margin: 20px;
        padding: 4px 44px;
        font-size: 18px;
      }
      .input--tooltip--error {
        display: none;
      }
    }
  }
}
