// ======================================================
// Component :: ourValues
// ======================================================

.history {
  .ourValues {
    &--card {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 10px;

      &--icon {
        margin: 10px;
        svg {
          @include size(100px, 100px);
        }
        margin-bottom: 20px;
      }
      &--h4 {
        font-weight: 600;
        font-size: 19px;
        margin-bottom: 10px;
        color: getTheme(text);
        text-align: center;
      }
      &--p {
        text-align: center;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 10px;
        color: getTheme(text-light);
      }
    }
  }
}
