// ======================================================
// Section :: info
// ======================================================

.csvMapper {
  .info {
    &--h2 {
      color: getTheme(text);
      text-align: center;
      font-size: 3rem;
      margin-bottom: 2rem;
      font-weight: 600;
      @include breakpoint-down("md") {
        margin-bottom: 1.4rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
    }
    &--box {
      @include display("flex");
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      &--img {
        background-image: url("../../../assets/images/resources/404343-PD54FO-309.webp");
        height: 450px;
      }
      &--p {
        margin-bottom: 1rem;
        font-size: 14px;
        line-height: 17px;
        color: #979797;
        @include transition("color 0.7s ease-in-out");
        &:hover {
          color: #444444;
        }
      }

      @include breakpoint-down("sm") {
        &--p {
          margin-bottom: 0.5rem;
          font-size: 14px;
          line-height: 16px;
        }
        &--img {
          height: 300px;
        }
      }
    }
  }
}

:root[data-theme="dark"] .csvMapper {
  .info {
    &--box {
      &--p:hover {
        color: $primary;
      }
    }
  }
}
