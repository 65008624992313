// ======================================================
// Base :: Global
// ======================================================
body {
  font-size: 14px;
  font-family: $fontPoppins;
  margin: 0;
  background-color: getTheme(bg);
}
:root {
  @include transition("background-color 0.6s ease, color 0.6s ease");
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}

html {
  scroll-behavior: smooth;
}

.full_bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: auto;
}

@media only screen and (max-device-width: 1366px) {
  .full_bg {
    background-attachment: scroll;
  }
}

.img {
  vertical-align: middle;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: border-box;
}

// @include keyframes(polygons) {
//   0% {
//     clip-path: ellipse(1% 1% at 1% 1%) !important;
//   }
//   50% {
//     clip-path: ellipse(60% 60% at 1% 1%) !important;
//   }
//   100% {
//     clip-path: ellipse(100% 100% at 1% 1%) !important;
//   }
// }

.fade {
  position: absolute;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}
