// ======================================================
// Section :: business
// ======================================================

.retargeting {
  .business {
    &--img_1 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/markus-spiske-5gGcn2PRrtc-unsplash.jpg");
    }

    &--img_2 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/fabio-silva-nmTm7knUnqs-unsplash.jpg");
    }

    &--img_3 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/mika-baumeister-PZao9UjlbMY-unsplash.jpg");
    }

    &--img_4 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/kon-karampelas-7Hv7183ZNFM-unsplash.jpg");
    }

    &--img_5 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/forecast/what-is-forex_body_what_is_forex.jpg");
    }

    &--img_6 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/ashkan-forouzani-ignxm3E1Rg4-unsplash.jpg");
    }

    &--img_7 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/kon-karampelas-peLs78VbU50-unsplash.jpg");
    }

    &--img_8 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.5),
          rgba(24, 25, 35, 0.5)
        ),
        url("../../../assets/images/retargeting/austin-distel-Imc-IoZDMXc-unsplash.jpg");
    }
    .img {
      width: 100%;
      height: 200px;
      background-size: cover;
    }

    &--card {
      @include display("flex");
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 0px;
      border-radius: 10px;
      .circle {
        @include size(60px, 60px);
        background-color: #fff;
        svg {
          fill: $primary;
          @include size(25px, 25px);
        }
      }
    }
    &--p {
      margin: 10px 0;
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 1rem;
      font-weight: 400;
      @include breakpoint-down("sm") {
        font-size: 14px;
      }
    }
  }
}

:root[data-theme="dark"] .retargeting {
  .business {
    &--card {
      .circle {
        border-color: transparent;
      }
    }
  }
}
