// ======================================================
// Components :: SubMenu
// ======================================================

.submenu {
  &--item {
    @include display(flex);
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    &--circle {
      @include size(50px, 50px);
      padding: 14px;
      border: 1px solid#d6d6d6;
      border-radius: 50%;
      @extend .shadow-33;
      @include display(flex);
      justify-content: center;
      align-items: center;
      @include transition("border-color 0.5s ease-in-out");
      svg {
        width: 20px;
        height: 20px;
      }
      svg > * {
        fill: #d6d6d6;
        @include transition("fill 0.5s ease-in-out, stroke 0.5s ease-in-out");
      }
      svg > * > path {
        fill: #d6d6d6;
        @include transition("fill 0.5s ease-in-out, stroke 0.5s ease-in-out");
      }
      #self-serve > g > path {
        fill: none;
      }
      #self-serve > g > path:nth-child(4) {
        fill: #d6d6d6;
      }
      #retarget > * {
        fill: none !important;
        stroke: #d6d6d6;
      }
    }
    &--h4 {
      color: #474747;
      font-weight: 600;
      margin-bottom: 6px;
      margin-top: 3px;
      font-size: 16px;
      line-height: 14px;
      text-transform: capitalize;
      @include transition("color 0.5s ease-in-out");
    }

    &--p {
      color: #969696;
      font-size: 13px;
      line-height: 14px;
      text-transform: capitalize;
      &:after {
        display: block;
        content: "";
        margin-top: 3px;
        border-bottom: 2px solid $primary;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        @include transition("transform 0.4s ease-in-out");
      }
    }
    &:hover &--h4,
    .active &--h4 {
      color: $primary;
    }
    &:hover > &--circle {
      border-color: $primary;
    }
    &:hover > &--circle > svg > *,
    &:hover > &--circle > svg > * > path,
    &:hover > &--circle > #adsformat > g > path:nth-child(2) {
      fill: $primary;
    }
    &:hover > &--circle > #adsformat > g:nth-child(1),
    &:hover > &--circle > #self-serve > g > path:nth-child(1),
    &:hover > &--circle > #self-serve > g > path:nth-child(2),
    &:hover > &--circle > #self-serve > g > path:nth-child(4),
    &:hover > &--circle > #retarget > g:nth-child(1),
    &:hover > &--circle > #retarget > g:nth-child(2),
    &:hover > &--circle > #retarget > path:nth-child(4) {
      stroke: $primary !important;
    }
    &:hover &--p:after,
    .active &--p:after {
      transform: scaleX(1);
      transform-origin: 0% 50% !important;
    }
    .active &--circle {
      border-color: $primary;
      svg > *,
      svg > * > path,
      #adsformat > g > path:nth-child(2) {
        fill: $primary;
      }
      #adsformat > g:nth-child(1),
      #self-serve > g > path:nth-child(1),
      #self-serve > g > path:nth-child(2),
      #self-serve > g > path:nth-child(4),
      #retarget > g:nth-child(1),
      #retarget > g:nth-child(2),
      #retarget > path:nth-child(4) {
        stroke: $primary !important;
      }
    }
  }
}

:root[data-theme="dark"] .submenu {
  &--item--h4 {
    color: #d7d7d7;
  }
}
