// ======================================================
// Section :: Privacy Policy
// ======================================================

.termsOfUse {
  .info {
    &--p {
      color: getTheme(text-light);
      font-size: $fontSize-large;
      line-height: 28px;
      margin-bottom: 1rem;
      font-weight: 400;
      text-align: center;
      padding: 10px;
    }
    .info-card--p {
      color: #a1a1a1;
      font-size: $fontSize-large;
      line-height: 28px;
      margin-bottom: 1rem;
      font-weight: 400;
    }
    @include breakpoint-down("sm") {
      &--p {
        padding: 3px;
      }
      .info-card--p {
        font-size: $fontSize-medium;
        line-height: 24px;
      }
    }
    .text-icon {
      &--svg {
        svg {
          @include size(50px, 50px);
          fill: $primary;
          #Path_11638 > path:last-child {
            fill: getTheme(bg) !important;
          }
        }
      }
      &--p {
        color: getTheme(text);
        font-size: 26px;
        font-weight: 600;
        margin-left: 5px;
      }
      @include breakpoint-down("sm") {
        &--svg {
          svg {
            @include size(35px, 35px);
          }
        }
        &--p {
          font-size: 22px;
        }
      }
    }
  }
}

:root[data-them="dark"] .privacyPolicy {
  .info {
    .text-icon {
      &--p {
        color: $primary;
      }
    }
  }
}
