// ======================================================
// Layout :: SignUp
// ======================================================

// Include components
// ------

@import "banner";
@import "advertiser";
@import "mainForm";

.signUp {
  .bannerLeft {
    position: absolute;
    left: 0;
    width: 47%;
    z-index: -1;
    .imgBG {
      background-image: linear-gradient(
          0deg,
          rgba(15, 15, 32, 0.7),
          rgba(15, 15, 32, 0.7)
        ),
        url("../../../assets/images/signup/michael-mouritz-WXX_DhjlmD4-unsplash.webp");
      width: 100%;
      background-size: cover;
      // height: 1900px;
    }

    @include breakpoint-down(lg) {
      width: 100%;
    }
    // @include breakpoint-down(sm) {
    //   height: 100vw;
    // }
  }

  // .toolbar--container {
  //   .navbar {
  //     & > :nth-child(4) li > button {
  //       color: #000 !important;
  //       svg {
  //         fill: #000;
  //       }
  //     }
  //     & > :nth-child(5) a {
  //       color: #000 !important;
  //     }
  //     & > :nth-child(6) li > button {
  //       color: #000 !important;
  //       svg {
  //         fill: #000;
  //       }
  //     }
  //     & > :nth-child(7) a {
  //       color: #000 !important;
  //     }
  //   }
  // }
}

:root[data-theme="light"] .signUp {
  .toolbar--container {
    .navMobile {
      & > :nth-child(1) {
        color: #000 !important;
      }
    }
    .navbar {
      & > :nth-child(4) li > button {
        color: #000 !important;
        svg {
          fill: #000;
        }
      }
      & > :nth-child(5) a {
        color: #000 !important;
      }
      & > :nth-child(6) li > button {
        color: #000 !important;
        svg {
          fill: #000;
        }
      }
      & > :nth-child(7) a {
        color: #000 !important;
      }
    }

    @include breakpoint-down(lg) {
      .navMobile {
        & > :nth-child(1) {
          color: #fff !important;
        }
      }
      .navbar {
        & > :nth-child(4) li > button {
          color: #fff !important;
          svg {
            fill: #fff;
          }
        }
        & > :nth-child(5) a {
          color: #fff !important;
        }
        & > :nth-child(6) li > button {
          color: #fff !important;
          svg {
            fill: #fff;
          }
        }
        & > :nth-child(7) a {
          color: #fff !important;
        }
      }
    }
  }
}
