// ======================================================
// Layout :: Banner
// ======================================================

.home-banner {
  background-image: linear-gradient(
      0deg,
      rgba(24, 25, 35, 0.4),
      rgba(24, 25, 35, 0.4)
    ),
    url("../../../assets/images/home/banner.webp");
  height: 700px;
  clip-path: polygon(100% 0, 100% 80%, 67.5% 100%, 0 80%, 0 0);
  padding-top: 90px;

  &--h1 {
    margin: 0 auto;
    text-align: center;
    padding: 1rem 0;
    color: $white;
    font-weight: bold;
  }
  &--h4 {
    margin: 0 auto;
    text-align: center;
    padding: 1rem 0;
    color: #eeeeee;
  }
  .btn {
    font-size: 19px;
  }
  @include breakpoint-down(lg) {
    clip-path: polygon(100% 0, 100% 90%, 67.5% 100%, 0 90%, 0 0);
    height: 650px;
    padding-top: 70px;
  }
  @include breakpoint-down(md) {
    clip-path: none;
  }
  @include breakpoint-down(sm) {
    height: 500px;
  }
}
:root[data-theme="dark"] .home-banner {
  background-image: linear-gradient(
      0deg,
      rgba(24, 25, 35, 0.4),
      rgba(24, 25, 35, 0.4)
    ),
    url("../../../assets/images/home/bannerdark.webp");
}
