// ======================================================
// Section :: admarket list
// ======================================================

.admarket {
  .admarketList {
    &--card {
      border: 1px solid getTheme(border);
      border-radius: 25px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 180px;
      }
      .btn {
        border-radius: 30px;
        margin: 0 10px;
      }
      &--brand {
        h4 {
          color: #ac1ede;
          text-align: center;
          font-size: 1rem;
          line-height: 1.2rem;
          font-weight: 500;
          padding: 2px 14px;
          border: 1px solid #ac1ede;
          border-radius: 10px;
          @include breakpoint-down("xs") {
            font-size: 0.7rem;
          }
        }

        h5 {
          color: getTheme(text);
          text-align: center;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 500;
          @include breakpoint-down("xs") {
            font-size: 0.7rem;
          }
        }
      }
      &--desc {
        h5 {
          color: getTheme(text);
          font-size: 1.4rem;
          font-weight: 600;
          @include breakpoint-down("md") {
            font-size: 1.2rem;
          }
          @include breakpoint-down("xs") {
            font-size: 0.8rem;
          }
        }
        p {
          color: getTheme(text-light2);
          font-size: 0.93rem;
          font-weight: 400;
          line-height: 1.35rem;
          @include breakpoint-down("md") {
            line-height: 1.2rem;
            font-size: 0.8rem;
          }
          @include breakpoint-down("xs") {
            font-size: 0.7rem;
          }
        }
        h6 {
          color: getTheme(text-light);
          font-size: 1rem;
          font-weight: 400;
          @include breakpoint-down("md") {
            font-size: 0.9rem;
          }
          @include breakpoint-down("xs") {
            font-size: 0.7rem;
          }
        }

        &--flags {
          > svg {
            width: 30px;
            margin: 0 5px;
            height: auto;
            border-radius: 4px;
          }
        }
      }

      &--details {
        .MuiBadge-anchorOriginTopRightRectangle {
          color: white;
          background-color: $primary;
        }
        h5 {
          color: getTheme(text);
          font-size: 1.3rem;
          font-weight: 600;
          margin-bottom: 1.2rem;
          @include breakpoint-down("md") {
            font-size: 1rem;
          }
          @include breakpoint-down("xs") {
            font-size: 0.8rem;
          }
          b {
            font-weight: 600;
            color: $primary;
          }
        }

        p {
          color: getTheme(text-light2);
          font-size: 1.05rem;
          font-weight: 400;
          line-height: 1.4rem;
          @include breakpoint-down("md") {
            line-height: 1.2rem;
            font-size: 0.7rem;
          }
          @include breakpoint-down("xs") {
            font-size: 0.6rem;
            line-height: 0.8rem;
          }
          span {
            color: getTheme(text);
          }
        }
        &--badge {
          svg {
            width: auto;
            height: 30px;
          }
          p {
            color: getTheme(text-light);
            font-size: 0.8rem;
            font-weight: 400;
            @include breakpoint-down("md") {
              font-size: 0.7rem;
            }
            @include breakpoint-down("xs") {
              font-size: 0.6rem;
            }
          }
          @include breakpoint-down("sm") {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

:root[data-theme="dark"] .admarket {
  .admarketList {
    &--card {
      background-color: getTheme(border);
    }
    .MuiPaginationItem-root {
      color: white !important;
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: #6d6d6d;
    }
    .MuiPaginationItem-page:hover {
      background-color: #6d6d6d;
    }

    #text-link,
    #computer-line,
    #Component_37_3 {
      fill: white;
    }
  }
}
