// ======================================================
// Layout :: contact
// ======================================================

// Include components
// ------

@import "sliderGallery";

.about {
  &--p {
    color: getTheme(text-light2);
    text-align: center;
    font-weight: 400;
    font-size: 19px;
    margin-bottom: 1rem;
    @include breakpoint-down("md") {
      font-size: 17px;
    }
    @include breakpoint-down("xs") {
      font-size: 15px;
    }
  }
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/about/Rectangle-305.webp");
  }
  .btn {
    margin-right: 10px;
  }
  &--btn {
    border: 4px solid #999;
    border-radius: 50%;
    background-color: #fff;
    padding: 15px !important;
    svg {
      width: 25px;
      height: 25px;
      fill: #000;
    }
    &:hover {
      @include box-shadow("0 0 0 8px transparent");
      @include animation("pulse 1.5s");
      background-color: #fff;
    }
  }
  .banner--h4 {
    @include breakpoint-up(sm) {
      text-align: left !important ;
    }
  }
  .sliderTeam {
    background-image: linear-gradient(
      0deg,
      rgba(172, 30, 222, 0.05),
      rgba(172, 30, 222, 0.05)
    );

    &--img {
      background-image: url("../../../assets/images/history/Group\ 3160.png");
      width: 450px;
      height: 350px;
      position: absolute;
      right: 0;
      margin-top: -70px;
    }
  }
}

@include keyframes(pulse) {
  from {
    @include box-shadow(0 0 0 0 #999);
  }
}
