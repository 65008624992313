// ======================================================
// Component :: question
// ======================================================

.anyQuestions {
  .formatAd {
    .shapCircle1 {
      background: linear-gradient(
        0deg,
        rgba(68, 15, 87, 0.2),
        rgba(68, 15, 87, 0.4)
      );
      width: 460px;
      height: 460px;
      border-radius: 50%;
      position: absolute;
      left: -200px;
      margin-top: 50px;
      @include breakpoint-down(md) {
        width: 200px;
        height: 200px;
      }
      @include breakpoint-down(sm) {
        left: -50px;
        width: 100px;
        height: 100px;
      }
    }
    .shapCircle2 {
      background: linear-gradient(
        0deg,
        rgba(68, 15, 87, 0.2),
        rgba(68, 15, 87, 0.4)
      );
      width: 200px;
      height: 200px;
      border-radius: 50%;
      position: absolute;
      right: -100px;
      margin-top: 50px;
      @include breakpoint-down(sm) {
        margin-top: 20px;
        right: -25px;
        width: 50px;
        height: 50px;
      }
    }
    .shapCircle3 {
      background: linear-gradient(
        0deg,
        rgba(68, 15, 87, 0.2),
        rgba(68, 15, 87, 0.4)
      );
      width: 120px;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      margin-top: 200px;
      @include breakpoint-down(sm) {
        margin-top: 100px;
        width: 30px;
        height: 30px;
      }
    }
    .square1,
    .square2 {
      background-image: url("../../../assets/images/about/1.png");
      width: 150px;
      height: 150px;
      position: absolute;
      @include breakpoint-down(sm) {
        width: 80px;
        height: 50px;
      }
    }
    .square1 {
      right: 0;
    }

    .square2 {
      left: 0;
      margin-top: -20px;
    }
    &--imgContent {
      .img {
        width: 100%;
        height: 200px;
        margin-top: 50px;
        @include breakpoint-down(sm) {
          height: 150px;
          margin-top: 30px;
        }
      }
    }
    &--img1 {
      background-image: url("../../../assets/images/about/PC.png");
    }
    &--img2 {
      background-image: url("../../../assets/images/about/Phone.png");
    }
    &--img3 {
      background-image: url("../../../assets/images/about/tablet.png");
    }
    &--img4 {
      background-image: url("../../../assets/images/about/Laptop.png");
    }
    &--card {
      @include display(flex);
      justify-content: space-evenly;
      align-items: flex-start;
      flex-flow: column;
      padding-bottom: 2rem;
      &--h2 {
        color: #fff;
        font-size: 1.9rem;
        text-transform: capitalize;
        @include breakpoint-down(sm) {
          font-size: 1.5rem;
        }
      }
      &--p {
        color: #aaa;
        font-size: 1rem;
        line-height: 1.5rem;
        @include breakpoint-down(sm) {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
      &--h4 {
        color: #fff;
        font-size: 1.6rem;
        text-transform: capitalize;
        margin: 10px 20px;
        @include breakpoint-down(sm) {
          font-size: 1.1rem;
        }
      }
      @include breakpoint-up(md) {
        padding-bottom: 6rem !important;
      }
    }
    .banner--h1 {
      color: #fff;
      font-weight: 400;
    }
    background-color: #181923;
    .navTab {
      &--navList {
        .navlink {
          color: #fff !important;
          cursor: pointer;
        }
      }
      @include breakpoint-up(md) {
        @include display(flex);
        justify-content: center;
        align-items: center;
      }
    }
  }
}
