// ======================================================
// Section :: top cards
// ======================================================

.service {
  .topCards {
    .btn {
      border-radius: 20px;
    }
    .slick-arrow {
      top: 100%;
      margin: 20px 10px;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: #fff;
      @include display(flex);
      justify-content: center;
      align-items: center;
      background-color: #aaa;
      svg {
        width: 28px;
        height: 28px;
        polyline {
          stroke-width: 8px !important;
          stroke: #444;
        }
      }
      &:hover {
        background-color: $primary;
      }
      &:hover {
        svg {
          polyline {
            stroke: #fff;
          }
        }
      }
    }
    .slick-next {
      right: 40px;
    }
    .slick-prev {
      right: 110px;
      left: auto;
    }
    .slide_1 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.8),
          rgba(24, 25, 35, 0.8)
        ),
        url("../../../assets/images/service/Path-435df.jpg");
    }
    .slide_2 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.8),
          rgba(24, 25, 35, 0.8)
        ),
        url("../../../assets/images/service/Rectangle-376.jpg");
    }
    .slide_3 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.8),
          rgba(24, 25, 35, 0.8)
        ),
        url("../../../assets/images/service/Rectangle-378.jpg");
    }
    .slide_4 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.8),
          rgba(24, 25, 35, 0.8)
        ),
        url("../../../assets/images/service/Rectangle-376.jpg");
    }
    &--slideCard {
      margin: 15px;
      height: 200px;
      background-size: auto;

      @include transition("height 0.5s ease-in");
      &--card {
        @include display(flex);
        justify-content: space-evenly;
        align-items: flex-start;
        flex-flow: column;
        padding: 10px;
        margin: 10px;

        &--title {
          font-size: 19px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 10px;
        }
        &--desc {
          font-size: 13px;
          line-height: 22px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 10px;
          @include breakpoint-down("xs") {
            line-height: 17px;
          }

          @include transition(
            "font-size 0.5s ease-in, line-height 0.5s ease-in"
          );
          &:hover {
            font-size: 15px;
            line-height: 23px;
          }
        }
        &--p {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 10px;
        }
        .link {
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          color: #fff;
          &:hover {
            color: $primary;
          }
        }
      }
      &:hover {
        border-radius: 10px;
        height: 240px;
      }
      @include breakpoint-down("sm") {
        &:hover {
          height: 250px;
        }
      }
    }
  }
}
