// ======================================================
// Section :: filter
// ======================================================

.trends {
  .filter {
    &--p {
      color: getTheme(text);
      font-size: 19px;
      line-height: 22px;
      font-weight: 500;
      @include breakpoint-down("sm") {
        font-size: 18px;
        line-height: 22px;
      }
      @include breakpoint-down("xs") {
        font-size: 16px;
        line-height: 22px;
      }
    }
    &--form {
      @include display(flex);
      justify-content: space-evenly;
      align-items: center;
      flex-flow: row;

      .input--tooltip {
        min-width: 240px;
        width: 100%;
        &--form {
          border-radius: 20px;
          &--label {
            display: none;
          }
          &--icon {
            svg {
              fill: #fff;
              path {
                @include transition("fill 0.3s ease-in, stroke 0.3s ease-in");
              }
            }
          }
          @include transition("background-color 0.3s ease-in");

          .selectInput--action {
            @include transition("background-color 0.3s ease-in");
            p {
              font-size: 12px;
              // white-space: nowrap;
              @include transition("color 0.3s ease-in");
            }
            span > svg > path {
              @include transition("fill 0.3s ease-in, stroke 0.3s ease-in");
            }
          }
          &:hover {
            background-color: $primary;
          }
          &:hover .selectInput--action {
            background-color: $primary;
          }
          &:hover .selectInput--action {
            p {
              color: #fff;
            }
            span svg path {
              fill: #fff;
            }
          }
          &:hover .input--tooltip--form--icon svg path {
            fill: #fff !important;
          }
        }
      }
      @include breakpoint-down("md") {
        .selectInput--action {
          p {
            font-size: 12px;
          }
        }
      }
      @include breakpoint-down("sm") {
        justify-content: center;
        flex-flow: wrap;
        .input--tooltip {
          width: 240px;
          padding: 2px;
          .selectInput--action {
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
