// ======================================================
// Section :: Premium support
// ======================================================

.premium {
  .support {
    @include display(flex);
    justify-content: center;
    flex-flow: column;

    div:nth-child(2),
    div:nth-child(4) {
      & > .img {
        order: 1;
      }
      .support--box {
        align-items: flex-end;
        h1,
        p {
          text-align: right;
        }
      }
    }
    @include breakpoint-down("sm") {
      .img {
        order: 1;
      }
    }

    .img_1 {
      background-image: url("../../../assets/images/premium/thom-holmes-J2e34-1CVVs-unsplash.webp");
    }
    .img_2 {
      background-image: url("../../../assets/images/resources/jonathan-kemper-IZcbhj6tbos-unsplash.webp");
    }
    .img_3 {
      background-image: url("../../../assets/images/resources/austin-distel-4r72LPFh4Ik-unsplash.webp");
    }
    .img_4 {
      background-image: url("../../../assets/images/premium/caspar-camille-rubin-XA0v5hbb7HY-unsplash.webp");
    }
    &--img {
      height: 450px;
      width: 100%;
      background-size: cover;
      opacity: 0.9;
      &:hover {
        opacity: 1;
        @include transition("opacity 0.5s ease-in");
      }
      @include breakpoint-down("sm") {
        height: 300px;
      }
    }
    &--box {
      @include display(flex);
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
      h1,
      p {
        text-align: left;
      }
      @include breakpoint-down("sm") {
        align-items: flex-start;
        h1,
        p {
          text-align: left;
        }
      }
    }
    &--h1 {
      color: getTheme(text);
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 1rem;
      font-weight: 500;
      text-align: center;
    }
    &--p {
      color: getTheme(text-light2);
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 1rem;
      font-weight: 400;
      text-align: center;
    }
    @include breakpoint-down("sm") {
      &--p {
        font-size: $fontSize-base;
        line-height: 22px;
      }
      &--h1 {
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
}
