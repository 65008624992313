// ======================================================
// Layout :: Publishers
// ======================================================

// Include components
// ------

@import "topGeo";
@import "campaign";

.publishersPage {
  &--h2 {
    color: getTheme(text);
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
    @include breakpoint-down("md") {
      margin-bottom: 1.4rem;
    }
    @include breakpoint-down("xs") {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.6),
        rgba(24, 25, 35, 0.6)
      ),
      url("../../../assets/images/home/banner.webp");
    height: 620px;

    &--h4 {
      text-align: left;
    }
    .btn--outline {
      border: 1px solid #fff;
      padding: 5px 36px;
      color: #fff;
      &:hover {
        color: $primary;
        background-color: #fff !important;
      }
    }
    .btn {
      margin: 10px 5px;
    }

    @include breakpoint-down(md) {
      height: 500px;
    }
    @include breakpoint-down(sm) {
      height: 600px;
    }
  }
  .bannerImg {
    background-image: url("../../../assets/images/publishers/Image-43.jpg");
    height: 270px;
    width: 100%;
    @include breakpoint-down(md) {
      height: 200px;
    }
  }

  &--infoImg {
    background-image: url("../../../assets/images/publishers/Path-280.jpg");
    border-radius: 0 20px 0 20px;
    width: 100%;
    height: 450px;
    background-size: cover;
  }

  .statisticCard {
    flex-flow: column;
    width: 300px;
  }
  .publishCards {
    .iconCard {
      width: 250px;
      margin: 20px auto;
      align-items: flex-start;
      border: 1px solid getTheme(border);
      &--h4,
      &--p {
        text-align: left;
      }
      .circle {
        margin-left: 0;
      }
    }
  }
}
