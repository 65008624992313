// ======================================================
// Section :: lifestyle
// ======================================================

.lastnews {
  .lifestyle {
    &--box {
      &--img_1 {
        background-image: url("../../../assets/images/career/drew-taylor-7liDpl93wt4-unsplash.jpg");
      }
      &--img_2 {
        background-image: url("../../../assets/images/career/louis-hansel-shotsoflouis-phEaeqe555M-unsplash.webp");
      }
      &--img_3 {
        background-image: url("../../../assets/images/career/jaelynn-castillo-xfNeB1stZ_0-unsplash.jpg");
      }
      &--img_4 {
        background-image: url("../../../assets/images/career/anastase-maragos-fG0p4Qh_aWI-unsplash.jpg");
      }
      &--img_5 {
        background-image: url("../../../assets/images/career/drew-taylor-7liDpl93wt4-unsplash.jpg");
      }
      .img {
        height: 200px;
        width: 100%;
        border-radius: 10px 10px 0 0;
        background-size: cover;
      }
      &--card {
        background-color: #fff;
        &--text {
          border-radius: 0 0 10px 10px;
          border: 1px solid #ddd;
          @include display(flex);
          justify-content: space-evenly;
          align-items: flex-start;
          flex-flow: column;
          padding: 10px;
          height: 120px;
          border-top-color: transparent;
        }

        &--h4 {
          color: getTheme(text-light);
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
          font-weight: 400;
          @include breakpoint-down("sm") {
            margin-bottom: 5px;
            font-size: 14px;
          }
        }
      }

      .slick-arrow {
        cursor: pointer;
        position: absolute;
        @include display("flex");
        justify-content: center;
        align-items: center;
        background-color: $primary;
        top: 45%;
        width: 35px;
        height: 45px;
        border-radius: 5px;
        z-index: 5;
        padding: 8px;
        &::before {
          content: none;
        }
        svg {
          padding: 5px;
          polyline {
            stroke: $white;
          }
        }

        @include transition("background-color 0.2s ease-in");
        &:hover {
          background-color: #ddd;
        }
        &:hover svg {
          polyline {
            stroke: $primary;
          }
        }
      }
      .slick-prev {
        left: 40px;
        polyline {
          stroke-width: 6;
        }
        &:hover polyline,
        &:focus polyline {
          stroke-width: 8;
        }
        &:active polyline {
          stroke-width: 10;
          transition: all 100ms ease-in-out;
        }
        @include breakpoint-down("sm") {
          left: 10px;
        }
      }

      .slick-next {
        right: 40px;
        polyline {
          stroke-width: 6;
        }
        &:hover polyline,
        &:focus polyline {
          stroke-width: 7;
        }
        &:active polyline {
          stroke-width: 10;
          transition: all 100ms ease-in-out;
        }
        @include breakpoint-down("sm") {
          right: 10px;
        }
      }
    }
  }
}
