.btnStore {
  @include display(flex);
  justify-content: center;
  align-items: center;
  flex-flow: row;
  border-radius: 8px;
  margin: 10px !important;
  padding: 10px 24px !important;
  z-index: 50;
  p {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
  }
  svg {
    @include size(30px, 30px);
    margin-right: 10px;
    path {
      fill: #fff;
    }
  }
  @include breakpoint-down(sm) {
    padding: 4px 18px;
    p {
      font-size: 11px;
      line-height: 13px;
    }
    svg {
      @include size(20px, 20px);
      margin-right: 10px;
    }
  }
}
