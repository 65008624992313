// ======================================================
// Section :: seasonal List
// ======================================================

.seasonal {
  .seasonalList {
    &--card {
      img {
        border-radius: 10px;
      }
      hr {
        width: 100%;
        border-color: getTheme(border);
      }
      &--title {
        color: getTheme(text);
        text-align: left;
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        text-transform: capitalize;
        @include breakpoint-down(md) {
          font-size: 17px;
          line-height: 18px;
        }
        @include breakpoint-down(xs) {
          font-size: 12px;
          line-height: 13px;
        }
      }
      &--month {
        color: getTheme(text-light2);
        text-align: left;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        text-transform: capitalize;
        @include breakpoint-down(md) {
          font-size: 17px;
          line-height: 18px;
        }
        @include breakpoint-down(xs) {
          font-size: 12px;
          line-height: 13px;
        }
        &::after {
          display: block;
          content: "";
          width: 10px;
          margin-left: 0;
          padding-top: 3px;
          border-bottom: 3px solid getTheme(text-light2);
        }
      }
      &--day {
        color: $primary;
        text-align: left;
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
        text-transform: uppercase;
        @include breakpoint-down(md) {
          font-size: 17px;
          line-height: 18px;
        }
        @include breakpoint-down(xs) {
          font-size: 12px;
          line-height: 13px;
        }
      }

      &--desc {
        color: #b6b6b6;
        text-align: left;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        @include breakpoint-down("sm") {
          font-size: 13px;
        }
        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
          margin-top: 5px;
        }
      }
      &--btn {
        @include display(flex);
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row;
        color: getTheme(text);
        font-size: 16px;
        font-weight: 600;
        @include breakpoint-down("sm") {
          font-size: 15px;
        }
        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
          margin-top: 5px;
        }
        svg {
          fill: $primary;
          margin-left: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  &--form {
    flex-flow: row;
    margin: 0.7rem 0;
    padding-bottom: 20px;

    .iconFilter {
      margin-right: 20px;
      @include size(22px, 22px);

      @include breakpoint-down("sm") {
        margin-right: 20px;
        @include size(20px, 20px);
      }

      @include breakpoint-down("xss") {
        margin-right: 20px;
        @include size(18px, 18px);
      }
    }

    &--box {
      flex-flow: row;
      &--input {
        width: 400px;
        margin-right: 20px;
        line-height: 15px;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid getTheme(border);
        border-radius: 25px;
        @include transition("box-shadow 0.4s ease-in, transform 0.4s ease-in");
        .iconSearch {
          @include size(18px, 18px);
          margin-right: 10px;
          margin-left: 5px;
          @include transition("stroke 0.4s ease-in");
        }
        input {
          width: 100%;
          background-color: transparent;
          &::placeholder {
            line-height: 17px;
            color: #797979;
            vertical-align: middle;
            font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
              "Lucida Sans", Arial, sans-serif;
            letter-spacing: 1px;
          }
        }

        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
          @extend .shadow-6;
          transform: scale(1.001);
        }
        @include breakpoint-down("sm") {
          width: 100% !important;
          padding: 7px;
          .iconSearch {
            @include size(15px, 15px);
          }
          @include on-event() {
            box-shadow: none;
            transform: none;
          }
        }
        @include breakpoint-down("xss") {
          width: 100% !important;
          padding: 6px;
          .iconSearch {
            @include size(14px, 14px);
          }
        }
      }
      &--btn {
        padding: 8px 45px;
        text-transform: capitalize;
        border-radius: 25px;
        font-weight: 500;
        font-size: 14px;

        @include breakpoint-down("sm") {
          padding: 5px 29px;
          line-height: 21px;
          font-weight: 400;
          font-size: 13px;
        }
        @include breakpoint-down("xss") {
          padding: 4px 26px;
        }
      }
    }
  }
}

:root[data-theme="dark"] .seasonal {
  .seasonalList {
    .MuiPaginationItem-root {
      color: white !important;
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: #6d6d6d;
    }
    .MuiPaginationItem-page:hover {
      background-color: #6d6d6d;
    }
    &--card {
      background-color: getTheme(border);
      box-shadow: none;
    }
    &--box2 {
      &--btn {
        .btn {
          &:hover p {
            color: white;
          }
        }
      }
    }
  }
  &--form {
    &--box {
      &--input {
        background-color: getTheme(border);
        input {
          background-color: getTheme(border);
          color: $white;
          &::placeholder {
            color: $white;
          }
        }
        .iconSearch {
          stroke: $white;
        }
        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
        }
      }
    }
  }
}
