// ======================================================
// Components :: Select phone Number
// ======================================================

.selectNumber {
  margin-left: 20px;
  .active {
    color: $white;
    background-color: $primary;
  }
  &--item {
    background-color: getTheme("card");
    color: getTheme(text);
    margin: 2px 0;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    padding: 3px;
    @include display(flex);
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    border-radius: 4px;
    &:hover {
      color: $white;
    }
    .img {
      height: 15px;
      width: 17px;
      margin-right: 4px;
      @include breakpoint-down(sm) {
        // margin-right: 10px;
      }
    }

    &_en-GB {
      background-image: url("../../../assets/flags/en-GB.svg");
    }
    &_nl-NL {
      background-image: url("../../../assets/flags/nl-NL.svg");
    }
    &_de-DE {
      background-image: url("../../../assets/flags/de-DE.svg");
    }
  }
  &--btnIcon {
    cursor: pointer;
    svg {
      @include size(21px, 21px);
      @include breakpoint-down(xs) {
        @include size(18px, 18px);
      }
    }
    svg > path {
      fill: $white;
      @include transition("fill 0.4s ease-in");
    }
    &:hover {
      svg > path {
        fill: $primary;
      }
    }
  }
  .popover {
    &--wrap--overlay {
      bottom: 100%;
      top: auto;
    }
    @include breakpoint-down(sm) {
      &--wrap--content {
        right: -38px;
        justify-content: flex-end;
      }
    }
    &--content {
      width: 170px;
      border-radius: 10px;
      margin: 5px;
      bottom: 100%;
      top: auto;
      padding: 5px;
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;

      &::before,
      &:after {
        transform: rotate(180deg);
        bottom: auto;
        top: 100%;
      }
      &:after {
        border-width: 7px;
        margin-left: -7px;
      }
      &:before {
        border-width: 8px;
        margin-left: -8px;
      }
      @include breakpoint-down(sm) {
        &::before,
        &::after {
          left: initial;
          right: 20%;
        }
      }
    }
  }
}
