// ======================================================
// Layout :: History
// ======================================================

// Include components
// ------

@import "info";
@import "ourValues";
@import "ourWork";
@import "slide";
@import "ourLeadership";

.history {
  .newsForm {
    &--btn {
      padding: 8px 30px;
      @include breakpoint-up(sm) {
        margin-top: 20px;
      }
    }
    &--img1,
    &--img2 {
      background-size: cover;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
      z-index: -1;
    }
    &--img1 {
      background-image: url("../../../assets/images/about/stephen.png");
    }
    &--img2 {
      background-image: url("../../../assets/images/about/eldad.png");
      right: 20%;
      @include breakpoint-down(sm) {
        right: 10%;
        margin-top: 50px;
      }
    }
    &--icon1,
    &--icon2,
    &--icon3,
    &--icon4,
    &--icon5 {
      box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
      position: absolute;
      border: 1px solid transparent;
      z-index: -1;
    }
    &--icon1 {
      left: 15%;
      margin-top: -120px;
      background-color: rgba(172, 30, 222, 0.05);
      svg {
        @include size(30px, 30px);
        fill: red;
      }
    }
    &--icon2 {
      left: 30%;
      margin-top: -30px;
      background-color: white;
      svg {
        @include size(30px, 30px);
        fill: #0084ff;
      }
    }
    &--icon3 {
      right: 17%;
      margin-top: -120px;
      background-color: white;
      svg {
        @include size(30px, 30px);
      }
    }
    &--icon4 {
      left: 62%;
      margin-top: -40px;
      background-color: rgba(172, 30, 222, 0.05);
      svg {
        @include size(30px, 30px);
        fill: #329a8f;
      }
    }
    &--icon5 {
      left: 58%;
      margin-top: -140px;
      background-color: rgba(172, 30, 222, 0.2);
      width: 40px;
      height: 40px;
    }
  }
  .labelCheckbox {
    margin-right: 10px;
    p,
    input {
      user-select: none;
    }
  }

  &--h2 {
    color: getTheme(text);
    text-align: center;
    font-size: 2.8rem;
    margin-bottom: 2rem;
    font-weight: 600;
    @include breakpoint-down("md") {
      margin-bottom: 1.4rem;
      font-size: 2rem;
    }
    @include breakpoint-down("xs") {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  &--p {
    color: getTheme(text-light);
    text-align: center;
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 2rem;
    @include breakpoint-down("md") {
      margin-bottom: 1.4rem;
      font-size: 17px;
    }
    @include breakpoint-down("xs") {
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.5),
        rgba(24, 25, 35, 0.5)
      ),
      url("../../../assets/images/history/TEAM-\(2\).webp");

    @include breakpoint-up(sm) {
      clip-path: ellipse(100% 100% at 50% -1%);
    }
  }
  .bannerCards {
    .container {
      margin-top: 20px;
      @include breakpoint-up(sm) {
        margin-top: -90px;
      }
    }
    &--cards {
      @include display(flex);
      justify-content: space-evenly;
      flex-flow: row;

      div:nth-child(2) {
        margin-top: 30px;
      }
      div:nth-child(3) {
        margin-top: 45px;
      }
      div:nth-child(4) {
        margin-top: 30px;
      }
      .circle {
        @extend .shadow-15;
        background-color: getTheme(bg);
        @include size(110px, 110px);
        padding: 10px;
        z-index: 4;
        h4 {
          color: $primary;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
        p {
          font-size: 11px;
          line-height: 13px;
          color: #707070;
          text-align: center;
        }
      }
      @include breakpoint-down(sm) {
        flex-flow: wrap row;

        .circle {
          background-color: getTheme(bg);
          @include size(90px, 90px);
          padding: 5px;
        }
        & > div {
          margin: 5px !important;
        }
      }
    }
  }
}
