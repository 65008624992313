// ======================================================
// Component :: ourLeadership
// ======================================================

.history {
  .ourLeadership {
    .labelCheckbox {
      margin-right: 10px;
    }
    &--icon {
      background-image: url("../../../assets/images/history/asset1.png");
      width: 100px;
      height: 200px;
      position: absolute;
      right: 20%;
      margin-top: -100px;
    }
    &--img {
      width: 100%;
      height: 350px;
      border-radius: 20px 120px 20px 20px;
      background-size: cover;
      background-image: url("../../../assets/images/about/oz-seyrek--Ir03_pgpMU-unsplash.png");
      @include breakpoint-down(sm) {
        height: 250px;
      }
    }
    &--box {
      @include display(flex);
      justify-content: space-around;
      align-items: flex-start;
      flex-flow: column;
      h4 {
        font-weight: 600;
        font-size: 19px;
        margin-bottom: 5px;
        color: $primary;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 10px;
        color: getTheme(text-light2);
      }
    }
  }
}
