// ======================================================
// Section :: engineering
// ======================================================

.engineering {
  .info {
    &--engineering_2 {
      @include display(flex);
      justify-content: space-between;
      align-items: stretch;
      flex-flow: column;
      padding: 15px;
      border: 1px solid getTheme(border);
      border-radius: 20px;
      &--img {
        border-radius: 20px;
        height: 140px;
      }

      &--profile {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      &--p {
        color: getTheme(text-light2);
        font-size: 13px;
        line-height: 14px;
        font-weight: 400;
        text-transform: capitalize;
        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
        }
      }
      &--pp {
        color: getTheme(text-light2);
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        text-transform: capitalize;
        @include breakpoint-down("xs") {
          font-size: 11px;
          line-height: 12px;
        }
      }
      &--h4 {
        color: getTheme(text);
        text-align: left;
        font-size: 15px;
        line-height: 17px;
        font-weight: 500;
        @include breakpoint-down(xs) {
          font-size: 12px;
          line-height: 13px;
        }
      }
      &--user {
        color: $primary;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 12px;
        line-height: 13px;
      }
    }
    &--engineering {
      @include display(flex);
      justify-content: space-between;
      align-items: stretch;
      flex-flow: column;
      padding: 15px;
      border: 1px solid getTheme(border);
      border-radius: 20px;
      &--img {
        border-radius: 20px;
        height: 260px;
      }
      &--share {
        svg {
          width: 25px;
          height: 25px;
          fill: $primary;
        }
      }
      &--p {
        color: getTheme(text-light2);
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-transform: capitalize;

        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
        }
      }
      &--user {
        color: $primary;
        font-size: 18px;
        font-weight: 400;
        text-transform: capitalize;
        @include breakpoint-down("sm") {
          font-size: 15px;
        }
        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
        }
      }
      &--profile {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      &--tag {
        color: #aaa;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        text-transform: capitalize;

        @include breakpoint-down("sm") {
          font-size: 10px;
          line-height: 10px;
        }
      }

      &--h4 {
        color: getTheme(text);
        text-align: left;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;

        @include breakpoint-down(md) {
          font-size: 17px;
          line-height: 18px;
        }
        @include breakpoint-down(xs) {
          font-size: 12px;
          line-height: 13px;
        }
      }
      &--h3 {
        color: $primary;
        text-align: left;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;

        @include breakpoint-down(sm) {
          font-size: 17px;
          line-height: 18px;
        }
      }
      @include breakpoint-down("sm") {
        &--img {
          padding: 10px;
          height: 230px;
        }
      }
    }
    &--form {
      flex-flow: row;
      &--input {
        width: 90%;
        margin-right: 20px;
        line-height: 15px;
        margin-left: 0;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid getTheme(border);
        border-radius: 25px;
        @include transition("box-shadow 0.4s ease-in, transform 0.4s ease-in");
        .iconSearch {
          @include size(18px, 18px);
          margin-right: 10px;
          margin-left: 5px;
          @include transition("stroke 0.4s ease-in");
        }
        input {
          width: 100%;
          background-color: transparent;
          &::placeholder {
            line-height: 17px;
            color: #797979;
            vertical-align: middle;
            font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
              "Lucida Sans", Arial, sans-serif;
            letter-spacing: 1px;
          }
        }

        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
          @extend .shadow-6;
          transform: scale(1.001);
        }
        @include breakpoint-down("sm") {
          width: 100% !important;
          padding: 7px;
          .iconSearch {
            @include size(15px, 15px);
          }
          @include on-event() {
            box-shadow: none;
            transform: none;
          }
        }
        @include breakpoint-down("xss") {
          width: 100% !important;
          padding: 6px;
          .iconSearch {
            @include size(14px, 14px);
          }
        }
      }
    }
  }
}

:root[data-theme="dark"] .engineering {
  .info {
    &--form {
      &--input {
        background-color: getTheme(border);
        input {
          background-color: getTheme(border);
          color: $white;
          &::placeholder {
            color: $white;
          }
        }
        .iconSearch {
          stroke: $white;
        }
        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
        }
      }
    }
  }
}
