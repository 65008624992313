// ======================================================
// Components :: Button
// ======================================================

.darkMode {
  @include display("flex");
  justify-content: space-around;
  align-items: center;
  flex-flow: row;
  &--icon {
    margin-right: 4px;

    svg {
      @include size(20px, 20px);
      color: $white;
      @include breakpoint-down(xs) {
        @include size(18px, 18px);
      }
    }
  }
  .select {
    &--action {
      p {
        font-size: 15px;
        color: $white;
        cursor: pointer;
        margin: 0;
        padding: 4px 2px;
        text-transform: capitalize;
      }
      span {
        svg {
          font-size: 16px;
          fill: $white;
        }
      }
    }
    &--content {
      background-color: #333;
      .option {
        padding: 6px 2px;
        font-size: 15px;
        color: $white;
        font-weight: 300;
        width: 100%;

        &:hover {
          background-color: $primary;
        }
      }
    }
  }
}
