// ======================================================
// Section :: release Storie
// ======================================================

.ourStory {
  .releaseStorie {
    &--boxes {
      &--card {
        border: 1px solid getTheme(border);
        padding: 20px;
        border-radius: 20px;
        @include display(flex);
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include transition("border-color 0.5s ease-in");

        &--h4 {
          color: getTheme(text-light);
          font-size: 21px;
          line-height: 26px;
          margin-bottom: 1rem;
        }
        &--p {
          color: getTheme(text-light2);
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 1rem;
        }
        .link {
          font-weight: 500;
        }

        @include breakpoint-down("sm") {
          &--p {
            line-height: 18px;
          }
          &--h4 {
            font-size: 17px;
            line-height: 20px;
          }
        }
        &:hover {
          border-color: $primary;
        }
      }
    }
    .btn {
      border-radius: 25px;
    }
  }
}
