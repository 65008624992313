// ======================================================
// Component :: Banner
// ======================================================

.helpCenter {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/about/jonas-jacobsson-MLSS52p0ze4-unsplash.webp");
    height: 550px;
    padding-top: 90px;

    &--h1 {
      text-align: center;
      color: $white;
      text-transform: capitalize;
      font-weight: 700;
      font-size: 3.3rem;
      line-height: 3.5rem;
      margin-bottom: 1rem;
    }
    &--h4 {
      text-align: center;
      color: $white;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
    .btn {
      padding: 6px 36px;
      margin: 10px 0;
    }

    @include breakpoint-down(lg) {
      height: 450px;
      padding-top: 70px;
    }
    @include breakpoint-down(md) {
      &--h1 {
        font-size: 3rem;
        line-height: 4rem;
      }
    }

    @include breakpoint-down(sm) {
      height: 380px;
      &--h1 {
        font-size: 2rem;
        line-height: 3rem;
      }
      &--h4 {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
    }
  }
}
