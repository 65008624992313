// ======================================================
// Components :: accordion
// ======================================================
.accordion {
  // margin: 20px 0;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  &--action {
    h4 {
      font-size: 1.2rem;
      padding: 10px;
      font-weight: 500;
      color: getTheme(text);
      @include transition("color 0.4s ease-in");
    }
    button {
      svg {
        @include transition("fill 0.4s ease-in");
        fill: getTheme(text);
        @include size(20px, 20px);
      }
    }
    @include breakpoint-down("xs") {
      button {
        svg {
          @include size(15px, 15px);
        }
      }
      h4 {
        font-size: 0.9rem;
      }
    }
    @include breakpoint-down("xs") {
      padding: 5px;
    }
  }
  &--details {
    padding: 20px 10px;
    color: #b3b3b3;
    margin-bottom: 0;
    @include display("flex");
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
  &--acc {
    overflow: hidden;
  }
  &--show {
    visibility: visible;
    @include transition("visibility 0.4s ease-in");
  }
  &--show {
    visibility: hidden;
    @include transition("visibility 0.4s ease-in");
  }
}

[data-isOpen="true"] .accordion--action {
  h4 {
    color: $primary;
  }
  button {
    svg {
      fill: $primary;
    }
  }
}

:root[data-theme="dark"] .accordion {
  box-shadow: none;
  background-color: #444444;
  &--action {
    // border: 1px solid getTheme(border);

    &[data-isOpen="true"] {
      h4 {
        color: $white;
      }
      button {
        svg > * {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }
}
