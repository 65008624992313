// ======================================================
// Layout :: Publisher Arena
// ======================================================

// Include components
// ------

@import "info";
@import "analytics";

.mobileApp {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.67),
        rgba(24, 25, 35, 0.67)
      ),
      url("../../../assets/images/resources/yura-fresh-n31x0hhnzOs-unsplash.webp");
  }
}
