// ======================================================
// Layout :: Visit
// ======================================================

.visit {
  &--h2 {
    color: getTheme(text);
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
    font-weight: 600;
    @include breakpoint-down("md") {
      margin-bottom: 2rem;
    }
    @include breakpoint-down("xs") {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
