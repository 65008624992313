// ======================================================
// Components :: Breadcrumbs
// ======================================================

.employe {
  @include display(flex);
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  border: 1px solid getTheme(border);
  border-radius: 30px;
  margin: 5rem 1.5rem;
  padding: 10px;
  width: 300px;
  background-color: $white;
  @include transition("background-color 0.8s ease-out");

  &--box {
    @include size(130px, 130px);
    border: 8px solid transparent;
    border-radius: 50%;
    margin-top: -55px;
    @include transition("border-color 0.8s ease-in-out");
    &--img {
      border-radius: 50%;
      @include size(100%, 100%);
      background-size: cover;
    }
    @include breakpoint-between("md", "lg") {
      @include size(90px, 90px);
      margin-top: -35px;
    }
  }
  &--h3 {
    color: getTheme(text);
    margin-top: 2rem;
    font-size: 29px;
    line-height: 34px;
    font-weight: 600;
    text-transform: capitalize;
  }
  &--h3 {
    color: #8f8f8f;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }
  &--p {
    padding: 5px;
    text-align: center;
    font-size: 17px;
    line-height: 23px;
    color: $white;
    margin-bottom: 1rem;
  }
  &--collapse {
    overflow: hidden;
  }
  &:hover &--box {
    border-color: $white;
  }
  &:hover {
    background-color: $primary;
  }
  &:hover &--h3,
  &:hover &--h5 {
    color: $white;
  }
  @include breakpoint-down(xs) {
    margin: 3rem 0.2rem;
  }
}

:root[data-theme="dark"] .employe {
  background-color: getTheme(border);
}
