// ======================================================
// Section :: PromoteCard Banner
// ======================================================

.referralProgram {
  .citBanner {
    background-image: url("../../../assets/images/resources/citbanner.webp");
    height: 300px;

    &--h1 {
      color: $white;
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1rem;
    }
    &--h4 {
      color: #dddddd;
      font-size: 1.1rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
    .btn {
      padding: 6px 30px;
      margin: 10px 0;
    }

    @include breakpoint-down(lg) {
      height: 250px;
    }
    @include breakpoint-down(md) {
      &--h1 {
        font-size: 2rem;
        line-height: 3rem;
      }
    }

    @include breakpoint-down(sm) {
      height: 200px;
      &--h1 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      &--h4 {
        font-size: 0.8rem;
        line-height: 1rem;
      }
      .btn {
        padding: 4px 26px;
        margin: 5px 0;
      }
    }
  }
}
