// ======================================================
// Section :: OurTeam Banner
// ======================================================

.ourTeam {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/about/campaign-creators-gMsnXqILjp4-unsplash.webp");
    height: 600px;
    clip-path: polygon(100% 0, 100% 83%, 33% 100%, 0 83%, 0 0);
    padding-top: 90px;

    &--h1 {
      text-align: center;
      color: $white;
      text-transform: uppercase;
      font-family: cursive;
      font-weight: 700;
      font-size: 4rem;
      line-height: 5rem;
      margin-bottom: 1rem;
    }

    @include breakpoint-down(lg) {
      clip-path: polygon(100% 0, 100% 90%, 30% 100%, 0 90%, 0 0);
      height: 550px;
      padding-top: 70px;
    }
    @include breakpoint-down(md) {
      clip-path: none;
      &--h1 {
        font-size: 3rem;
        line-height: 4rem;
      }
    }

    @include breakpoint-down(sm) {
      height: 400px;
      &--h1 {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
}
