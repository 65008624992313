// ======================================================
// Layout :: contact
// ======================================================

// Include components
// ------

@import "location";

.contact {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/contact/l-IC37_iVg9xM-unsplash.webp");
  }
}
