// ======================================================
// Components :: Link
// ======================================================

.link {
  font-size: $fontSize-base;
  cursor: pointer;
  @include transition("all 0.5s ease-in");
  line-height: 20px;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 400;

  &--color {
    color: getTheme(text) !important;
  }
  &--transparent {
    color: $white !important;
  }
  &--scroll {
    color: getTheme(text) !important;
  }

  &--primary {
    color: $primary !important ;
    &:hover,
    &:active {
      color: $primary !important ;
    }
  }

  &--secondary {
    color: $secondary !important ;
    @include on-event() {
      color: $secondary !important ;
    }
  }

  &--light {
    color: $light !important ;
    @include on-event() {
      color: $light !important ;
    }
  }

  &--black {
    color: $black !important ;
    @include on-event() {
      color: $black !important ;
    }
  }

  &--underline {
    &:hover {
      text-decoration: underline;
    }
  }
}
