// ======================================================
// Section :: video
// ======================================================

.trends {
  .video {
    &--wrap {
      border: 1px solid getTheme(border);
      border-radius: 40px;
      @include breakpoint-down("sm") {
        border-radius: 20px;
      }
    }
    &--list {
      background-color: #fdf8fe;
      border-radius: 40px;
      height: 600px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      @include breakpoint-down("sm") {
        &::-webkit-scrollbar {
          width: 3px;
        }
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: #e6e6e6;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(171, 30, 222, 0.801);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 30, 222);
      }
      &--text {
        @include display(flex);
        justify-content: space-evenly;
        align-items: flex-start;
        flex-flow: column;
      }
    }
    &--p {
      color: getTheme(text);
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;
      @include breakpoint-down(sm) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    hr {
      border-color: $primary;
      width: 100%;
      border-width: thin;
      margin-bottom: 10px;
    }
    .img-top {
      border-radius: 20px;
      width: 100%;
      height: 220px;

      @include breakpoint-down(sm) {
        padding: 10px;
        height: 160px;
        background-size: cover;
      }
    }

    &--img {
      border-radius: 20px;
      height: 180px;

      @include breakpoint-down(sm) {
        padding: 10px;
        height: 160px;
        width: 100%;
        background-size: cover;
      }
    }
    &--span {
      color: getTheme(text-light2);
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      margin-bottom: 10px;
      @include display(flex);
      justify-content: flex-start;
      align-items: center;
      flex-flow: row;
      svg {
        path {
          fill: $primary;
        }
      }

      @include breakpoint-down("sm") {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

:root[data-theme="dark"] .trends {
  .video {
    &--list {
      background-color: #222;
    }
  }
}
