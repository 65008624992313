.select {
  cursor: pointer;
  padding: 0 5px;

  &--action {
    user-select: none;
    p {
      font-size: 16px;
      color: getTheme(text);
      @include transition("color 0.2s ease-in");
    }
    span {
      margin-left: 10px;
      svg {
        @include transition("fill 0.3s ease-in");
        fill: getTheme(text);
        @include size("20px", "20px");
      }
    }
  }
  &--wrap--content {
    position: relative;
    box-sizing: border-box;
    @include display(flex);
    justify-content: flex-start;
    align-items: center;
  }
  &--wrap--overlay {
    position: absolute;
    top: 0;
    width: 100%;
  }
  &--content {
    position: relative;
    background-color: getTheme("bg");
    width: 100%;
    bottom: 100%;
    padding: 0;
    margin-top: 5px;
    @include display(flex);
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
  }
}

.selectInput {
  width: 100%;
  padding: 0 5px;
  cursor: pointer;

  &--action {
    @include display(flex);
    justify-content: space-between;
    align-items: center;
    flex-flow: row;
    background-color: #fff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
    p {
      line-height: 16px;
      font-weight: 600;
      color: #aaa;
      vertical-align: middle;
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      letter-spacing: 1px;
    }
    span {
      margin-left: 10px;
      svg {
        @include transition("fill 0.3s ease-in");
        path {
          fill: #aaa;
        }
        @include size("20px", "20px");
      }
    }
  }
  &--wrap--content {
    position: relative;
    box-sizing: border-box;
    @include display(flex);
    justify-content: flex-start;
    align-items: center;
    z-index: 99;
  }
  &--wrap--overlay {
    position: absolute;
    top: 0;
    width: 100%;
  }
  &--scroll {
    &--active {
      height: 136px;
    }
    overflow-x: auto;
    width: 100%;
    top: 0;
    padding: 0;
    margin-top: 5px;
    border-radius: 2px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #e6e6e6;
    }

    &::-webkit-scrollbar-thumb {
      background: #a7a7a7;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #8f8f8f;
    }
  }

  &--content {
    @include display(flex);
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
    width: 100%;
    bottom: 100%;
    padding: 0;
    margin-top: 0px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    option {
      width: 100%;
      line-height: 16px;
      font-weight: 600;
      color: #aaa;
      vertical-align: middle;
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      letter-spacing: 1px;
      padding: 8px;
      text-transform: capitalize;
      border: 1px solid transparent;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

:root[data-theme="dark"] .selectInput {
  width: 100%;
  padding: 0 5px;
  cursor: pointer;

  &--action {
    background-color: #303030;

    @include box-shadow("none");
    color: #fff;
    background-color: #303030;
  }
  &--scroll {
    box-shadow: rgba(41, 42, 43, 0.2) 0px 8px 24px;
  }

  &--content {
    background-color: #303030;
    option {
      color: #aaa;
      border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
