// ======================================================
// Abstracts :: Variables
// ======================================================
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
// Fonts
$fontPoppins: "Poppins", sans-serif;
@font-face {
  $fontCircular: "Circular Std Book";
  src: url("../../assets/font/860c3ec7bbc5da3e97233ccecafe512e.eot"); /* IE9*/
  src: url("../../assets/font/860c3ec7bbc5da3e97233ccecafe512e.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../assets/font/860c3ec7bbc5da3e97233ccecafe512e.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("../../assets/font/860c3ec7bbc5da3e97233ccecafe512e.woff")
      format("woff"),
    /* chrome、firefox */
      url("../../assets/font/860c3ec7bbc5da3e97233ccecafe512e.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("../../assets/font/860c3ec7bbc5da3e97233ccecafe512e.svg#Circular Std Book")
      format("svg"); /* iOS 4.1- */
}

// Paths
$pathImges: "../../../assets/images";

// Font Weights
$fontWeight-thin: 200;
$fontWeight-light: 300;
$fontWeight-base: 400;
$fontWeight-medium: 500;
$fontWeight-medium: 600;

// Font Sizes
$fontSize-extra-small: 10px;
$fontSize-small: 12px;
$fontSize-base: 14px;
$fontSize-medium: 16px;
$fontSize-large: 18px;
$fontSize-larger: 24px;
$fontSize-largest: 28px;
$fontSize-extra-larger: 36px;

// Borders
$r-smaller: 2px !important;
$r-small: 6px !important;
$r-base: 10px !important;
$r-medium: 14px !important;
$r-large: 20px !important;
$r-larger: 24px !important;
$r-hack: 100px !important;

@font-face {
  font-family: "icomoon";
  src: url("../../assets/icomoon/icomoon.eot?7wcfji");
  src: url("../../assets/icomoon/icomoon.eot?7wcfji#iefix")
      format("embedded-opentype"),
    url("../../assets/icomoon/icomoon.ttf?7wcfji") format("truetype"),
    url("../../assets/icomoon/icomoon.woff?7wcfji") format("woff"),
    url("../../assets/icomoon/icomoon.svg?7wcfji#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
