// ======================================================
// Section :: Form
// ======================================================

.events {
  &--h2 {
    font-size: 35px;
    font-weight: 500;
    color: getTheme(text);
    text-align: center;
    @include breakpoint-down(sm) {
      font-size: 28px;
    }
    @include breakpoint-down(xs) {
      font-size: 20px;
    }
  }
  .btn {
    border-radius: 25px;
  }
  &--card {
    @include display("flex");
    justify-content: center;
    align-items: center;
    flex-flow: column;
    border: 1px solid getTheme(border);
    border-radius: 25px;
    text-align: center;
    padding: 15px;
    @include transition("background-color 0.5s ease-in");
    // width: 250px;

    .img {
      border-radius: 25px;
      height: 300px;
      background-size: cover;
      width: 100%;

      @include breakpoint-down(lg) {
        height: 250px;
      }

      @include breakpoint-down(sm) {
        height: 300px;
      }
      @include breakpoint-down(xs) {
        height: 250px;
      }
      @include breakpoint-down(xss) {
        height: 200px;
      }
    }
    .img_1 {
      background-image: url("../../../assets/images/events/tim-mossholder-WE_Kv_ZB1l0-unsplash.jpg");
    }
    .img_2 {
      background-image: url("../../../assets/images/events/Laptop-.jpg");
    }
    .img_3 {
      background-image: url("../../../assets/images/events/Laptop-12.jpg");
    }
    .img_4 {
      background-image: url("../../../assets/images/events/Laptop-.jpg");
    }
    .img_5 {
      background-image: url("../../../assets/images/events/Laptop-12.jpg");
    }
    .img_6 {
      background-image: url("../../../assets/images/events/tim-mossholder-WE_Kv_ZB1l0-unsplash.jpg");
    }
    .img_7 {
      background-image: url("../../../assets/images/events/Laptop-.jpg");
    }
    .img_8 {
      background-image: url("../../../assets/images/events/Laptop-12.jpg");
    }
    .img_9 {
      background-image: url("../../../assets/images/events/tim-mossholder-WE_Kv_ZB1l0-unsplash.jpg");
    }

    &--title {
      font-size: 20px;
      color: getTheme(text);
      margin-bottom: 20px;
      @include transition("color 0.5s ease-in");
    }
    &--date {
      font-size: 20px;
      color: $primary;
      margin-bottom: 25px;
      @include transition("color 0.5s ease-in");
    }
    &--location {
      font-size: 17px;
      color: getTheme(text-light2);
      margin-bottom: 10px;
    }

    &:hover {
      background-color: $primary;
    }
    &:hover &--title {
      color: #fff;
    }
    &:hover &--date {
      color: #fff;
    }
    @include breakpoint-down(sm) {
      margin: 30px;
    }
    @include breakpoint-down(xs) {
      margin: 20px;
    }
    @include breakpoint-down(xs) {
      &--date,
      &--tilte {
        margin-bottom: 10px;
      }
      margin: 10px;
    }
  }
}

:root[data-theme="dark"] .events {
  &--card {
    background-color: getTheme(border);
    &:hover {
      background-color: $primary;
    }

    &:hover &--date {
      color: #fff;
    }
  }
}
