// ======================================================
// Section :: info
// ======================================================

.toolsOverview {
  .info {
    .iconCard {
      &--h4 {
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 1rem;
        text-align: center;
      }
      &--p {
        color: #8a8a8a;
        text-align: center;
        font-weight: 300;
        line-height: 19px;
      }
      .circle svg > path {
        @include transition("fill 0.5s ease-in-out, stroke 0.5s ease-in-out");
      }
      &:hover .circle {
        @include transition(
          "background-color 0.4s ease-in-out ,box-shadow 0.6s ease-in-out,border-color 0.4s ease-in-out "
        );
        @include box-shadow("rgba(149, 157, 165, 0.2) 0px 8px 24px;");
        background-color: $primary;
        svg {
          @include size(25px, 25px);
          path {
            fill: $white;
          }
          #Solutions_designed {
            fill: $primary;
          }
          #Path_11813,
          #Path_11905,
          #Path_11906,
          #Path_11701,
          #Path_11702 {
            fill: none !important;
            stroke: $white;
          }
        }
      }
    }
    &--h2 {
      color: getTheme(text);
      text-align: left;
      font-size: 2rem;
      margin-bottom: 2.2rem;
      font-weight: 600;
      @include breakpoint-down("md") {
        margin-bottom: 1.2rem;
        font-size: 1.7rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1rem;
        margin-bottom: 0.8rem;
      }
    }
    &--box {
      @include display("flex");
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      &--img {
        background-image: url("../../../assets/images/resources/486539-PH7BQV-797.png");
        height: 380px;
      }
      ul {
        list-style: disc !important;
        margin-left: 30px;
        li::marker {
          font-size: 16px;
          color: $primary;
        }
      }

      &--p {
        margin-bottom: 1rem;
        font-size: 17px;
        line-height: 19px;
        color: #444444;
      }

      @include breakpoint-down("sm") {
        &--p {
          margin-bottom: 0.5rem;
          font-size: 14px;
          line-height: 16px;
        }
        &--img {
          height: 260px;
        }
      }
    }
  }
}

:root[data-theme="dark"] .toolsOverview {
  .info {
    &--box {
      &--p:hover {
        color: $primary;
      }
      &--img {
        background-image: url("../../../assets/images/resources/486539-PH7BQV-797--dark.png");
      }
    }
  }
}
