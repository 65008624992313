// ======================================================
// Layout :: Faq Banner
// ======================================================

.faqBanner {
  &--h1 {
    text-align: center;
    color: getTheme(text);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 1rem;
  }
  &--h4 {
    text-transform: capitalize;
    text-align: center;
    color: getTheme(text);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  @include breakpoint-down(md) {
    &--h1 {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
  @include breakpoint-down("xs") {
    &--h1 {
      font-size: 2rem;
      line-height: 3rem;
    }
    &--h4 {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }
}
