// ======================================================
// Component :: monetize
// ======================================================

.monetize {
  .formatAd {
    background-image: url("../../../assets/images/home/bannerdark.webp");
    &--imgContent {
      .img {
        width: 100%;
        height: 200px;
        margin-top: 50px;
        @include breakpoint-down(sm) {
          height: 150px;
          margin-top: 30px;
        }
      }
    }
    &--img1 {
      background-image: url("../../../assets/images/about/PC.png");
    }
    &--img2 {
      background-image: url("../../../assets/images/about/Phone.png");
    }
    &--img3 {
      background-image: url("../../../assets/images/about/tablet.png");
    }
    &--img4 {
      background-image: url("../../../assets/images/about/Laptop.png");
    }
    &--card {
      @include display(flex);
      justify-content: space-evenly;
      align-items: flex-start;
      flex-flow: column;
      padding-bottom: 2rem;
      &--h2 {
        color: #fff;
        font-size: 1.9rem;
        text-transform: capitalize;
        @include breakpoint-down(sm) {
          font-size: 1.5rem;
        }
      }
      &--p {
        color: #aaa;
        font-size: 1rem;
        line-height: 1.5rem;
        @include breakpoint-down(sm) {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
      &--h4 {
        color: #fff;
        font-size: 1.6rem;
        text-transform: capitalize;
        margin: 10px 20px;
        @include breakpoint-down(sm) {
          font-size: 1.1rem;
        }
      }
      @include breakpoint-up(md) {
        padding-bottom: 6rem !important;
      }
    }
    .banner--h1 {
      color: #fff;
      font-weight: 400;
    }
    background-color: #181923;
    .navTab {
      &--navList {
        .navlink {
          color: #fff !important;
          cursor: pointer;
        }
      }
      @include breakpoint-up(md) {
        @include display(flex);
        justify-content: center;
        align-items: center;
      }
    }
  }
}
