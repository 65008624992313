// ======================================================
// Component :: question
// ======================================================

.anyQuestions {
  .question {
    .banner--h1 {
      color: getTheme(text);
      text-align: center;
      margin: 10px auto;
      z-index: 3;
      @include breakpoint-down(sm) {
        font-size: 20px;
      }
    }
    .banner--h4 {
      z-index: 3;
      color: getTheme(text);
      text-align: center;
      margin: 10px auto;
      @include breakpoint-down(sm) {
        font-size: 14px;
      }
    }
    .btn {
      margin: 20px auto;
    }
    padding-top: 70px;
    &--card {
      height: 300px;
      border-radius: 7px;
      text-align: center;
      margin: 0 auto;
      background-color: #fff;
      position: relative;
      @include breakpoint-down(sm) {
        height: 200px;
      }
    }
    .waveOne {
      background-image: url("../../../assets/images/about/Path\ 260.png");
      background-position: left;
      width: 100%;
      position: absolute;
      height: 300px;
      @include breakpoint-down(sm) {
        height: 200px;
      }
    }

    .waveTwo {
      background-image: url("../../../assets/images/about/Path\ 261.png");
      position: absolute;
      width: 100%;
      height: 200px;
      position: absolute;
      width: 100%;
      height: 200px;
      top: 0;
      background-position: right;
      @include breakpoint-down(sm) {
        height: 100px;
      }
    }
  }
  .question::after {
    background: #181923;
    height: 400px;
    content: "";
    position: absolute;
    width: 100%;
    border-radius: 50%;
    z-index: -1;
    margin-top: -200px;
    @include breakpoint-down(sm) {
      height: 200px;
      margin-top: -100px;
    }
  }
}

:root[data-theme="dark"] .anyQuestions {
  .question {
    &--card {
      background-color: #555;
    }
  }
}
