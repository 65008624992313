// ======================================================
// Base :: Display
// ======================================================

.flex {
  @include display(flex);

  &--inline {
    @include display(inline-flex);
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--column-r {
    flex-direction: column-reverse;
  }

  &--row-r {
    flex-direction: row-reverse;
  }

  &--start {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--end {
    justify-content: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-base {
    align-items: baseline;
  }

  &--align-start {
    align-items: flex-start;
  }
}
