// ======================================================
// Section :: Privacy Policy
// ======================================================

.termsOfUse {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/about/bgprivacy.webp");
    height: 500px;
    padding-top: 90px;

    &--h1 {
      text-align: center;
      color: $white;
      text-transform: capitalize;
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1rem;
    }

    @include breakpoint-down(lg) {
      height: 400px;
      padding-top: 70px;
    }
    @include breakpoint-down(md) {
      &--h1 {
        font-size: 2rem;
        line-height: 3rem;
      }
    }

    @include breakpoint-down(sm) {
      height: 320px;
      &--h1 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}
