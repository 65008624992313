// ======================================================
// Components :: Input
// ======================================================
.input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  &::placeholder {
    color: #464646;
  }
}
label {
  user-select: none;
}

.wrapInput {
  padding: 10px 0px;
  &--label {
    margin: 2px 20px;
    color: getTheme(text);
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  &--error {
    color: red;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 2px 20px;
  }

  &--form {
    @include display(flex);
    justify-content: flex-start;
    align-self: center;
    flex-flow: row;
    border: 1px solid getTheme(border);
    @include transition("border-color 0.4s ease-in");
    border-radius: 25px;
    padding: 7px;
    margin: 6px 5px;
    background-color: #fff;

    &--icon {
      @include display(flex);
      justify-content: center;
      align-self: center;
      line-height: 15px;
      margin: 0 5px;
      padding: 3px;
      svg {
        fill: #aaa;
        @include size(20px, 16px);
        #website > path {
          stroke: #aaa !important;
        }
      }
    }
    &--input {
      width: 100%;
      line-height: 16px;
      padding: 5px;
      padding-left: 10px;
      color: #000;
      background-color: #fff;
      &::placeholder {
        line-height: 16px;
        font-weight: 600;
        color: #aaa;
        vertical-align: middle;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
          "Lucida Sans", Arial, sans-serif;
        letter-spacing: 1px;
      }
    }
    &--select {
      .selectInput--action {
        padding: 5px;
        border: none !important;
      }
      .selectInput--scroll {
        margin-top: 10px;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 90px rgb(255, 255, 255) inset !important;
    }
  }
}

.textArea {
  padding: 10px 0px;
  &--label {
    margin: 4px 10px;
    color: getTheme(text);
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  &--error {
    color: red;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 2px 5px;
  }
  &--input {
    @include display(flex);
    border: 1px solid transparent;
    @include transition("border-color 0.4s ease-in");
    @extend .shadow-5;
    border-radius: 5px;
    padding: 10px;
    margin: 6px 5px;
    width: 100%;
    color: #000;
    background-color: #fff;
    &::placeholder {
      text-transform: capitalize;
      line-height: 16px;
      font-weight: 600;
      color: #aaa;
      vertical-align: middle;
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      letter-spacing: 1px;
    }
  }
}

:root[data-theme="dark"] .wrapInput {
  &--form {
    @include box-shadow("none");
    background-color: #303030;
    &--input {
      color: #fff;
      background-color: #303030;
    }
  }
}
:root[data-theme="dark"] .textArea {
  &--input {
    @include box-shadow("none");
    color: #fff;
    background-color: #303030;
  }
}

input[type="checkbox"],
input[type="radio"] {
  --active: #ac1ede;
  --active-inner: #fff;
  --focus: 2px rgba(171, 30, 222, 0.308);
  --border: #bbc1e1;
  --border-hover: #ac1ede;
  --background: #fff;
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  @include transition("background-color .3s, border-color .3s, box-shadow .2s");
  @extend .shadow-5;
  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    @include transition(
      "transform  .3s ease-in var(--d-t-e, ease), opacity var(--d-o, .2s)"
    );
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
}

input[type="checkbox"] {
  &:not(.switch) {
    border-radius: 3px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }
  &.switch {
    width: 38px;
    border-radius: 11px;
    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
}
input[type="radio"] {
  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  &:checked {
    --s: 0.5;
  }
}
.labelCheckbox {
  margin-bottom: 10px;
  cursor: pointer;

  input {
    margin-right: 5px;
  }
  p {
    vertical-align: sub;
    display: inline;
    color: getTheme(text);
  }
}

.input--tooltip {
  padding: 10px 0px;
  @include display(flex);
  justify-content: flex-start;
  align-self: center;
  flex-flow: column;
  &--label {
    margin: 4px 10px;
    color: getTheme(text);
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  &--error {
    @include display(flex);
    justify-content: flex-start;
    align-self: center;
    flex-flow: row;
    .popover {
      @include display(flex);
      justify-content: flex-start;
      align-self: center;
      flex-flow: row;
      &--wrap--overlay {
        left: 30px;
        top: auto;
        position: absolute;
      }
      &--content {
        margin: 5px;
        padding: 0;
        box-shadow: none !important;
        width: auto;
        &::before,
        &::after {
          top: 35%;
          transform: rotate(270deg);
          left: -5px;
        }
        &::before {
          border-bottom-color: red;
          border-width: 5px;
          margin-left: -5px;
        }
        &::after {
          border-bottom-color: red;
          border-width: 5px;
          margin-left: -5px;
        }
      }
    }
    &--message {
      color: red;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      white-space: nowrap;
      padding: 10px 20px;
      background-color: getTheme(bg);
      border-radius: 10px;
      border: 1px solid red;
    }
    &--icon {
      @include display(flex);
      justify-content: center;
      align-self: center;
      line-height: 15px;
      margin: 0 5px;
      padding: 3px;
      position: absolute;
      cursor: pointer;
      svg {
        fill: red;
        @include size(20px, 16px);
        #website > path {
          stroke: red !important;
        }
      }
    }
    @include breakpoint-down(md) {
      display: none;
    }
  }

  &--form {
    @include display(flex);
    justify-content: flex-start;
    align-self: center;
    flex-flow: row;
    width: 100%;
    border: 1px solid transparent;
    @include transition("border-color 0.4s ease-in");
    @include box-shadow(" rgba(0, 0, 0, 0.05) 0px 0px 0px 1px");
    border-radius: 10px;
    padding: 7px;
    margin: 6px 5px;
    background-color: #fff;

    &--icon {
      @include display(flex);
      justify-content: center;
      align-self: center;
      line-height: 15px;
      margin: 0 5px;
      padding: 3px;
      svg {
        @include size(20px, 16px);
        path {
          fill: #aaa;
          @include transition("fill 0.4s ease-in, stroke 0.4s ease-in");
        }
        #website > path {
          stroke: #aaa !important;
        }
      }
    }
    &--input {
      width: 100%;
      line-height: 16px;
      padding: 5px;
      padding-left: 10px;
      color: getTheme(text);
      background-color: transparent;
      &::placeholder {
        line-height: 16px;
        font-weight: 600;
        color: #aaa;
        vertical-align: middle;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
          "Lucida Sans", Arial, sans-serif;
        letter-spacing: 1px;
      }
    }
    &--select {
      .selectInput--action {
        padding: 5px;
        border: none !important;
      }
      .selectInput--scroll {
        margin-top: 10px;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 90px rgb(255, 255, 255) inset !important;
    }
  }
  @include breakpoint-up(md) {
    .wrapInput--error {
      display: none;
    }
  }
  @include breakpoint-down(md) {
    flex-flow: column;
  }
}
.valid {
  .input--tooltip--form,
  .wrapInput--form {
    border-color: $primary;
    box-shadow: none;
    &--icon {
      svg path {
        fill: $primary;
      }
    }
  }
}
.invalid {
  .input--tooltip--form,
  .wrapInput--form {
    border-color: red;
    box-shadow: none;
    &--icon {
      svg path {
        fill: red;
      }
    }
  }
}
:root[data-theme="dark"] .input--tooltip {
  &--error {
    .popover {
      &--content {
        &::before,
        &::after {
          border-bottom-color: red;
        }
      }
    }
  }

  &--form {
    background-color: #333;

    &--input {
      &::placeholder {
        color: #aaa;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: #fff;
    box-shadow: 0 0 0 90px #333 inset !important;
  }
}
