// ======================================================
// Section :: All Features
// ======================================================

.advertisers {
  .allFeatures {
    .btn {
      border-radius: 30px;
    }
    @include breakpoint-down(sm) {
      .advertisers--h1 {
        text-align: center;
        font-size: 21px;
      }
    }
    &--boxes {
      .img {
        width: auto;
        height: 280px;
        background-size: cover;
        @include breakpoint-down(sm) {
          width: 70%;
          margin: 0 auto;
        }
        @include breakpoint-down(xs) {
          width: 84%;
          margin: 0 auto;
        }
      }
      .img_1 {
        background-image: linear-gradient(
            0deg,
            rgba(24, 25, 35, 0.7),
            rgba(24, 25, 35, 0.7)
          ),
          url("../../../assets/images/ads/austin-distel-fEedoypsW_U-unsplash.jpg");
      }
      .img_2 {
        background-image: linear-gradient(
            0deg,
            rgba(24, 25, 35, 0.7),
            rgba(24, 25, 35, 0.7)
          ),
          url("../../../assets/images/ads/mihai-lazar--yA6n_TzTSI-unsplash.jpg");
      }
      .img_3 {
        background-image: linear-gradient(
            0deg,
            rgba(24, 25, 35, 0.7),
            rgba(24, 25, 35, 0.7)
          ),
          url("../../../assets/images/home/City\ \(4\).jpg");
      }
      .img_4 {
        background-image: linear-gradient(
            0deg,
            rgba(24, 25, 35, 0.7),
            rgba(24, 25, 35, 0.7)
          ),
          url("../../../assets/images/home/City\ \(4\).jpg");
      }
      .img_5 {
        background-image: linear-gradient(
            0deg,
            rgba(24, 25, 35, 0.7),
            rgba(24, 25, 35, 0.7)
          ),
          url("../../../assets/images/ads/mihai-lazar--yA6n_TzTSI-unsplash.jpg");
      }
      .img_6 {
        background-image: linear-gradient(
            0deg,
            rgba(24, 25, 35, 0.7),
            rgba(24, 25, 35, 0.7)
          ),
          url("../../../assets/images/ads/austin-distel-fEedoypsW_U-unsplash.jpg");
      }
      &--card {
        border-radius: 25px;
        padding: 20px;
        @include display(flex);
        justify-content: space-between;
        flex-flow: column;
        &--h4 {
          color: $white;
          font-size: 22px;
          line-height: 27px;
          font-weight: 500;
          margin-bottom: 0.8rem;
          @include breakpoint-down("sm") {
            font-size: 22px;
          }
        }
        &--p {
          color: $white;
          font-size: 16px;
          line-height: 29px;
          font-weight: 400;
          margin-bottom: 0.8rem;
          @include breakpoint-down("sm") {
            font-size: 14px;
          }
        }
        .link {
          color: white;
          font-size: 18px;
          & > svg {
            @include display("inline-block");
            fill: white;
            margin: 0 5px;
          }
          &:hover {
            color: $primary;
            & > svg {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}
