// ======================================================
// Components :: Navlink
// ======================================================

.navlink-li {
  @include display(flex);
  justify-content: center;
  width: auto;
  align-items: center;
  list-style: none;
}
.navlink {
  padding: 4px 20px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  text-transform: capitalize;
  white-space: nowrap;
  text-decoration: none !important;
  @include transition("color 0.2s ease-in-out");

  &--color {
    color: getTheme(text) !important;
  }
  &--transparent {
    color: $white !important;
  }
  &--scroll {
    color: getTheme(text) !important;
  }

  &:after {
    display: block;
    content: "";
    margin: 0 7px;
    border-bottom: 2px solid $primary;
    transform: scaleX(0);
    @include transition("transform 0.5s ease-in-out");
  }
  @include breakpoint-down(lg) {
    padding: 4px 10px;
    font-size: 15px;
    line-height: 17px;
  }
  &--active::after {
    transform: scaleX(1);
  }
}
.border-center {
  &:hover:after,
  &:active:after,
  &:focus::after {
    transform: scaleX(1);
  }
}
.border-fromRight {
  @include on-event-after(":after") {
    transform-origin: 100% 50%;
  }
}
.border-fromLeft {
  @include on-event-after(":after") {
    transform-origin: 0% 50%;
  }
}
