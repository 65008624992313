// ======================================================
// Section :: info
// ======================================================

.mobileApp {
  .info {
    &--h2 {
      color: getTheme(text);
      text-align: center;
      font-size: 3rem;
      margin-bottom: 1.5rem;
      font-weight: 600;

      @include breakpoint-down("xs") {
        font-size: 1.3rem;
        margin-bottom: 0.7rem;
      }
    }
    &--h5 {
      color: getTheme(text);
      text-align: center;
      font-size: 1.1rem;
      margin-bottom: 2.5rem;
      font-weight: 500;

      @include breakpoint-down("md") {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
      }
      @include breakpoint-down("xs") {
        font-size: 0.7rem;
        margin-bottom: 1rem;
      }
    }
    &--h4 {
      color: #6b6b6b;
      text-align: center;
      font-size: 1.3rem;
      margin-bottom: 2.5rem;
      font-weight: 500;

      @include breakpoint-down("md") {
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
      }
      @include breakpoint-down("xs") {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
    }
    &--box {
      @include display("flex");
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      &--img {
        &-1 {
          background-image: url("../../../assets/images/resources/34534536.png");
          height: 450px;
        }
        &-2 {
          background-image: url("../../../assets/images/resources/135956.webp");
          height: 400px;
        }
        &-bottom {
          background-image: url("../../../assets/images/resources/6531.png");
          height: 270px;
        }
        @include breakpoint-down("sm") {
          &-1 {
            height: 250px;
          }
          &-2 {
            height: 200px;
          }
          &-bottom {
            height: 150px;
          }
        }
      }

      &--p {
        margin-bottom: 1rem;
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        color: #6b6b6b;
        @include transition("color 0.7s ease-in-out");
        &:hover {
          color: #6b6b6b;
        }
      }

      @include breakpoint-down("sm") {
        &--p {
          margin-bottom: 0.5rem;
          font-size: 14px;
          line-height: 19px;
        }
      }
      .text-icon {
        &--p {
          color: getTheme(text);
          @include breakpoint-down(sm) {
            font-size: 1.3rem !important;
            line-height: 1.4rem;
          }
        }
        &--svg {
          @include breakpoint-down(sm) {
            svg {
              @include size(20px, 20px);
            }
          }
        }
      }
      &-1 {
        .text-icon {
          &--p {
            @include breakpoint-down(sm) {
              font-size: 0.8rem !important;
              line-height: 1rem;
            }
          }
          margin: 15px 0 !important;
          &--svg svg {
            @include size(23px, 23px);
            path {
              fill: $primary;
            }
          }
          &--p {
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
      &-2 {
        .text-icon--svg {
          svg {
            @include size(33px, 33px);
            #website > path {
              stroke: $primary;
              fill: $primary;
            }
          }
        }
        .text-icon--p {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    &--bottomBox2 {
      &--btn {
        .btn {
          padding: 10px 40px;
          p {
            font-size: 15px;
            line-height: 17px;
          }
          svg {
            @include size(30px, 30px);
          }
          @include breakpoint-down(sm) {
            padding: 5px 20px;
            p {
              font-size: 11px;
              line-height: 13px;
            }
            svg {
              @include size(20px, 20px);
              margin-right: 10px;
            }
          }
        }
      }
      &--h2 {
        font-size: 2rem;
        line-height: 2.3rem;
        margin-bottom: 1rem;
        font-weight: 600;
        color: getTheme(text);
        @include breakpoint-down(sm) {
          font-size: 1.3rem;
          line-height: 1.6rem;
          margin-bottom: 0.7rem;
          text-align: center;
        }
      }
      h4 {
        font-size: 1.1rem;
        line-height: 1.4rem;
        text-align: center;
        @include breakpoint-down(sm) {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
    }
    &--bottomBox {
      div:nth-child(1) {
        order: 2;
      }
      div:nth-child(2) {
        order: 1;
      }
      div:nth-child(3) {
        order: 3;
        text-align: right;
        @include breakpoint-down("sm") {
          text-align: left;
        }
      }
      &--h2 {
        color: getTheme(text);
        font-size: 1.7rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        font-weight: 600;
        @include breakpoint-down(sm) {
          font-size: 1.3rem;
          line-height: 1.6rem;
          margin-bottom: 0.7rem;
          text-align: left;
        }
      }
    }
  }
}

:root[data-theme="dark"] .mobileApp {
  .info {
    &--box {
      &--p:hover {
        color: $primary;
      }
      &--img {
        &-bottom {
          background-image: url("../../../assets/images/resources/6531-dark.png");
        }
      }
    }
  }
}
