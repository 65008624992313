// ======================================================
// Section :: couponsList
// ======================================================

.coupons {
  .couponsList {
    &--card {
      @extend .shadow-45;
      border-radius: 20px;
      border: 1px solid transparent;
      @include transition("border-color 0.5s ease-in");
      @include breakpoint-between(sm, md) {
        margin: 0 auto;
        width: 70%;
      }
      .img-1 {
        border-top-left-radius: 20px;
        height: 150px;
        width: 100%;

        @include breakpoint-down("sm") {
          height: 100px;
          // width: 150px;
        }
        @include breakpoint-down("xs") {
          height: 80px;
          // width: 110px;
        }
      }
      .img-2 {
        width: auto;
        height: auto;
        background-color: $white;
        max-height: 26px;
        @include breakpoint-down("xs") {
          max-height: 26px;
          margin: 3px 10px;
        }
      }
      &:hover {
        border-color: $primary;
      }
    }
    &--box1 {
      &--p {
        color: #b6b6b6;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        text-transform: capitalize;
        @include breakpoint-down("sm") {
          font-size: 15px;
        }
        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
    &--box2 {
      &--h4 {
        color: getTheme(text);
        text-align: left;
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;
        text-transform: capitalize;

        @include breakpoint-down(md) {
          font-size: 17px;
          line-height: 18px;
        }
        @include breakpoint-down(xs) {
          font-size: 12px;
          line-height: 13px;
        }
      }
      &--logo {
        @extend .shadow-2;
        @include display(flex);
        justify-content: center;
        align-items: center;
        background-color: #fff;
        margin: 5px 10px;
        padding: 4px;
        border-radius: 3px;
        height: 35px;
      }

      &--p {
        margin-left: 10px;
        color: #b6b6b6;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        @include breakpoint-down("sm") {
          font-size: 15px;
        }
        @include breakpoint-down("xs") {
          font-size: 10px;
          line-height: 10px;
          margin-top: 5px;
        }
      }
      &--btn {
        margin-left: 10px;
        margin-top: 1rem;
        .btn {
          @include display(flex);
          justify-content: center;
          flex-flow: row;
          border-radius: 5px;
          padding: 4px 12px;
          p {
            font-weight: 500;
            z-index: 5;
            color: white;
            @include transition("color 0.4s ease-in");
            font-size: 14px;
          }
          span {
            color: getTheme(text);
            margin-left: 10px;
          }
          background-color: transparent;
          border: 1px solid $primary;

          &::before {
            border-radius: 5px;
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: $primary;
            clip-path: polygon(0% 0%, 72% 0, 90% 100%, 0% 100%);
            transform-origin: 0 100%;
            transform: scaleY(1) scaleX(1);
            @include transition("transform 0.5s ease-in");
            @include breakpoint-down("xs") {
              display: none;
            }
          }

          &:hover p {
            color: black;
          }
          &:hover::before {
            transform: scale(0);
          }

          @include breakpoint-down("md") {
            p,
            span {
              font-size: 13px;
              line-height: 17px;
            }
          }
          @include breakpoint-down("xs") {
            padding: 4px 15px;
            background-color: $primary;
            &:hover p {
              color: white;
            }
            p,
            span {
              font-size: 9px;
              line-height: 13px;
            }
            span {
              color: white;
              z-index: 5;
            }
          }
        }

        &--date {
          margin-left: 10px;
          color: #b6b6b6;
          text-align: left;
          font-size: 13px;
          line-height: 17px;
          font-weight: 300;
          text-transform: capitalize;

          span {
            margin-right: 5px;
          }

          @include breakpoint-down("md") {
            font-size: 11px;
            line-height: 13px;
          }
          @include breakpoint-down("sm") {
            font-size: 8px;
            line-height: 8px;
            flex-flow: row;
            margin-left: 0;
            margin-bottom: 5px;
          }
        }
        @include breakpoint-down("sm") {
          flex-flow: column-reverse;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
    &--h2 {
      color: getTheme(text);
      text-align: center;
      font-size: 3rem;
      margin-bottom: 2rem;
      font-weight: 600;
      @include breakpoint-down("md") {
        font-size: 2rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1.5rem;
        margin-bottom: 0.6rem;
      }
    }
    &--h5 {
      color: getTheme(text);
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 2rem;
      @include breakpoint-down("md") {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
      @include breakpoint-down("xs") {
        font-size: 0.8rem;
        margin-bottom: 1rem;
      }
    }
  }
  &--form {
    flex-flow: row;
    margin: 0.7rem 0;
    padding-bottom: 20px;

    .iconFilter {
      margin-right: 20px;
      @include size(22px, 22px);

      @include breakpoint-down("sm") {
        margin-right: 20px;
        @include size(20px, 20px);
      }

      @include breakpoint-down("xss") {
        margin-right: 20px;
        @include size(18px, 18px);
      }
    }

    &--box {
      flex-flow: row;
      &--input {
        width: 400px;
        margin-right: 20px;
        line-height: 15px;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid getTheme(border);
        border-radius: 25px;
        @include transition("box-shadow 0.4s ease-in, transform 0.4s ease-in");
        .iconSearch {
          @include size(18px, 18px);
          margin-right: 10px;
          margin-left: 5px;
          @include transition("stroke 0.4s ease-in");
        }
        input {
          width: 100%;
          background-color: transparent;
          &::placeholder {
            line-height: 17px;
            color: #797979;
            vertical-align: middle;
            font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
              "Lucida Sans", Arial, sans-serif;
            letter-spacing: 1px;
          }
        }

        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
          @extend .shadow-6;
          transform: scale(1.001);
        }
        @include breakpoint-down("sm") {
          width: 100% !important;
          padding: 7px;
          .iconSearch {
            @include size(15px, 15px);
          }
          @include on-event() {
            box-shadow: none;
            transform: none;
          }
        }
        @include breakpoint-down("xss") {
          width: 100% !important;
          padding: 6px;
          .iconSearch {
            @include size(14px, 14px);
          }
        }
      }
      &--btn {
        padding: 8px 45px;
        text-transform: capitalize;
        border-radius: 25px;
        font-weight: 500;
        font-size: 14px;

        @include breakpoint-down("sm") {
          padding: 5px 29px;
          line-height: 21px;
          font-weight: 400;
          font-size: 13px;
        }
        @include breakpoint-down("xss") {
          padding: 4px 26px;
        }
      }
    }
  }
}

:root[data-theme="dark"] .coupons {
  .couponsList {
    .MuiPaginationItem-root {
      color: white !important;
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: #6d6d6d;
    }
    .MuiPaginationItem-page:hover {
      background-color: #6d6d6d;
    }
    &--card {
      background-color: getTheme(border);
      box-shadow: none;
    }
    &--box2 {
      &--btn {
        .btn {
          &:hover p {
            color: white;
          }
        }
      }
    }
  }
  &--form {
    &--box {
      &--input {
        background-color: getTheme(border);
        input {
          background-color: getTheme(border);
          color: $white;
          &::placeholder {
            color: $white;
          }
        }
        .iconSearch {
          stroke: $white;
        }
        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
        }
      }
    }
  }
}
