// ======================================================
// Components :: Cards
// ======================================================

.iconCard {
  @include display(flex);
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  border-radius: 15px;
  border: 1px solid transparent;
  padding: 20px 15px;
  @include transition(
    "transform 0.4s ease-in-out , box-shadow 0.4s ease-in-out"
  );
  &:hover {
    // transform: scale(1.01);
    // box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    transform: translateY(-20px);
    @include box-shadow(
      "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;"
    );
  }
  & > .circle {
    @include transition(
      "transform 0.4s ease-in-out , box-shadow 0.4s ease-in-out"
    );
    svg {
      @include size(25px, 25px);
      path {
        fill: $primary;
      }
      #Solutions_designed {
        fill: $white;
      }
      #Path_11813,
      #Path_11905,
      #Path_11906,
      #Path_11701,
      #Path_11702 {
        fill: none !important;
      }
    }
  }
  &--h4 {
    font-size: $fontSize-large;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: getTheme(text);
  }
  &--p {
    color: #8a8a8a;
    text-align: center;
    font-weight: 300;
    line-height: 19px;
  }
  @include breakpoint-down("sm") {
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    padding: 10px;
    &--h4 {
      font-size: $fontSize-medium;
    }
  }
}

:root[data-theme="dark"] .iconCard {
  border-color: transparent;
  // @include transition("border-color 0.5s ease-in ");
  @include box-shadow("none");

  & > .circle {
    > * line,
    > * path {
      fill: $white;
      stroke: $white;
      @include transition("fill 0.7s ease-in ");
    }
    border-color: transparent;
    @include box-shadow("none");
    @include transition(
      "border-color 0.7s ease-in ,background-color 0.9s ease-in-out"
    );
  }
  &:hover .circle {
    border-color: $primary;
    background-color: $primary;
  }
  &:hover {
    // border-color: $primary;
    // @include box-shadow("none");
    // transform: none;
    @include box-shadow("rgba(255, 255, 255, 0.1) -4px 9px 25px -6px;");
  }
  @include breakpoint-down(sm) {
    @include box-shadow("rgba(255, 255, 255, 0.1) -4px 9px 25px -6px;");
    .circle {
      border-color: $primary;
      background-color: $primary;
    }
  }
}

// ======================================================
// Components :: statisticCard
// ======================================================

.statisticCard {
  @include display(flex);
  justify-content: center;
  align-items: center;
  flex-flow: row;
  padding: 30px 10px;
  border: 1px solid getTheme(border);
  border-radius: 20px;
  margin: 20px;
  width: 380px;
  height: auto;

  @include breakpoint-down(xs) {
    padding: 15px 5px;
    margin: 10px;
    width: 320px;
  }

  &--box {
    @include display(flex);
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
    padding: 5px;
  }
  &--icon {
    margin-right: 15px;
    svg {
      @include size(45px, 45px);
      path {
        fill: $primary;
      }
      @include breakpoint-down(xs) {
        @include size(35px, 35px);
      }
      @include breakpoint-down(xss) {
        @include size(30px, 30px);
      }
    }
  }

  &--h4 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    color: getTheme(text);
    white-space: nowrap;
    b {
      color: $primary;
      margin-right: 5px;
    }
    @include breakpoint-down(xs) {
      font-size: 25px;
      line-height: 30px;
    }
    @include breakpoint-down(xss) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  &--p {
    color: #8a8a8a;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
  }
}
