// ======================================================
// Section :: analytics
// ======================================================

.monetize {
  .earnCards {
    &--box {
      @include display(flex);
      justify-content: space-evenly;
      align-items: stretch;
      flex-flow: row wrap;
      &--card {
        @include display(flex);
        justify-content: center;
        align-items: center;
        flex-flow: column;
        width: 240px;
        border-radius: 10px;
        border: 1px solid getTheme(border);
        padding: 10px;
        .circle {
          @include size(60px, 60px);
        }
      }
      .img {
        width: 100%;
        height: 25px;
      }
      &--img_1 {
        background-image: url("../../../assets/images/forecast/98712374-go-to-web-icon-vector-line-website-symbol-.png");
      }
      &--img_2 {
        background-image: url("../../../assets/images/forecast/Capture.png");
      }
      &--img_3 {
        background-image: url("../../../assets/images/forecast/25-512.png");
      }
      &--img_4 {
        background-image: url("../../../assets/images/forecast/share-1982993-1673430.png");
      }
      &--img_5 {
        background-image: url("../../../assets/images/forecast/Captureaa.png");
      }
    }
  }
}

:root[data-theme="dark"] .monetize {
  .earnCards {
    &--box--card {
      .circle {
        background-color: getTheme(border);
      }
    }
  }
}
