// ======================================================
// Components :: DropDown
// ======================================================
.dropdown {
  cursor: pointer;
  &--action {
    user-select: none;
    h4 {
      font-size: 1.2rem;
      padding: 5px 0px;
      font-weight: 500;
      color: getTheme(text);
      @include transition("color 0.4s ease-in");
    }
    button {
      margin-left: 10px;
      svg {
        @include transition("fill 0.4s ease-in");
        fill: getTheme(text);
        @include size("20px", "20px");
      }
    }
    @include breakpoint-down("xs") {
      button {
        svg {
          @include size("15px", "15px");
        }
      }
      h4 {
        font-size: 0.9rem;
      }
    }
    @include breakpoint-down("xs") {
      padding: 5px;
    }
  }
  &--details {
    padding: 5px 0px;
    color: #b3b3b3;
    margin-bottom: 0;
    @include display("flex");
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
  &--acc {
    overflow: hidden;
  }
}

[data-isOpen="true"] .accordion--action {
  h4 {
    color: $primary;
  }
  button {
    svg {
      fill: $primary;
    }
  }
}

:root[data-theme="dark"] .dropdown {
  &--action {
    h4 {
      color: $white;
    }
    button {
      svg > * {
        fill: $white;
        stroke: $white;
      }
    }
  }
}
