// ======================================================
// Layout :: TermsOfUse
// ======================================================

.pageNotFound {
  .btn {
    border-radius: 8px;
  }
  &--img {
    background-image: url("../../assets/images/notfound.png");
    @include size(500px, 500px);
  }
  &--h3 {
    h3 {
      color: getTheme(text-light);
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    p {
      color: getTheme(text-light);
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 3.5rem;
      font-weight: 400;
    }
  }

  .goBack {
    &--info {
      @include display(flex);
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
    }
    &--btn {
      @include display(flex);
      justify-content: flex-start;
      align-items: center;
      flex-flow: row;
    }
  }

  @include breakpoint-down("lg") {
    &--img {
      @include size(350px, 350px);
    }
  }
  @include breakpoint-down("sm") {
    &--img {
      @include size(100%, 270px);
    }
    &--h3 {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
    .goBack {
      &--info {
        justify-content: space-around;
      }
      &--btn {
        justify-content: space-around;
      }
    }
  }
  @include breakpoint-down("xs") {
    &--h3 {
      h3 {
        color: getTheme(text-light);
        font-size: 1.3rem;
        line-height: 2rem;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
}
