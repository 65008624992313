// ======================================================
// Section :: lexicon details
// ======================================================

.lexicon {
  &--details {
    @include display(flex);
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background-color: #fff;
    border-radius: 20px;
    z-index: 9999;
    position: relative;
    margin: 25%;
    padding: 30px 40px;
    text-align: center;

    &--logo {
      @include display(flex);
      justify-content: center;
      align-items: center;
      margin: 5px;
      padding: 5px;
      svg {
        @include size(70px, 70px);
      }
    }
    &--close {
      position: absolute;
      top: 12px;
      right: 12px;
      border-radius: 5px;
      padding: 6px;
      @extend .shadow-45;
      background-color: #fff;
      svg {
        fill: $primary;
        @include size(23px, 23px);
      }
    }

    @include breakpoint-down(sm) {
      padding: 10px 20px;
    }

    &--title {
      color: getTheme(text);
      font-size: 21px;
      line-height: 21px;
      font-weight: 700;
      text-transform: capitalize;

      @include breakpoint-down(md) {
        font-size: 17px;
        line-height: 18px;
      }
      @include breakpoint-down(xs) {
        font-size: 12px;
        line-height: 13px;
      }
    }

    &--desc {
      color: getTheme(text);
      font-size: 14px;
      line-height: 15px;
      font-weight: 600;
      @include breakpoint-down(md) {
        font-size: 12px;
        line-height: 14px;
      }
      @include breakpoint-down(xs) {
        font-size: 10px;
        line-height: 13px;
      }
    }

    &--views {
      color: #b6b6b6;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      @include breakpoint-down(md) {
        font-size: 12px;
        line-height: 14px;
      }
      @include breakpoint-down(xs) {
        font-size: 10px;
        line-height: 13px;
      }
    }

    @include breakpoint-down(lg) {
      margin: 15%;
    }
    @include breakpoint-down(md) {
      margin: 5%;
    }
    @include breakpoint-down(xs) {
      margin: 20px;
    }
  }
}

:root[data-theme="dark"] .lexicon {
  &--details {
    background-color: getTheme(border);
    &--close {
      box-shadow: none;
    }
  }
}
