// ======================================================
// Component :: slide
// ======================================================

.history {
  .slide {
    background-image: linear-gradient(
      0deg,
      rgba(172, 30, 222, 0.05),
      rgba(172, 30, 222, 0.05)
    );

    &--img {
      background-image: url("../../../assets/images/history/Group\ 3160.png");
      width: 450px;
      height: 350px;
      position: absolute;
      right: 0;
      margin-top: -70px;
    }
    .img_1 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/history/PORTRAIT-\(13\).jpg");
    }
    .img_2 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/history/dan-nelson-hIz3vbUbOoI-unsplash.jpg");
    }
    .img_3 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/history/annie-spratt-PM4Vu1B0gxk-unsplash.jpg");
    }
    .img_4 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/history/timon-studler-ABGaVhJxwDQ-unsplash.jpg");
    }
    .img_5 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/history/dan-nelson-hIz3vbUbOoI-unsplash.jpg");
    }
    .img_6 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/history/annie-spratt-PM4Vu1B0gxk-unsplash.jpg");
    }
    &--content {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
    }
    &--card {
      @include display(flex);
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      margin: 5px 20px;
      &--img {
        @include display(flex);
        justify-content: center;
        align-items: center;
        flex-flow: column;
        width: 100%;
        height: 300px;
        background-size: cover;
        border-radius: 25px;
      }

      &--title {
        font-weight: 600;
        font-size: 19px;
        margin-bottom: 10px;
        color: white;
        text-align: center;
      }
      &--desc {
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 10px;
        color: white;
      }

      &--h5 {
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 5px;
        color: getTheme(text-light2);
        text-align: center;
      }
      &--p {
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 10px;
        color: getTheme(text-light2);
        text-align: center;
      }
    }
  }
}
