// ======================================================
// Layout :: forCards
// ======================================================

.forCards {
  border-radius: 5px;
  padding: 20px;
  border: 1px solid getTheme(border);
  .img_1 {
    background-image: url("../../../assets/images/home/123.jpg");
  }
  .img_2 {
    background-image: url("../../../assets/images/home/312.jpg");
  }

  &--img {
    height: 230px;
    width: 100%;
    background-size: cover;
    @include breakpoint-down(xs) {
      height: 290px;
    }
  }
  &--h4 {
    font-size: $fontSize-larger;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
    color: getTheme(text);
  }
  &--p {
    font-size: $fontSize-medium;
    line-height: 22px;
    color: getTheme(text-light);
  }
  &--btn {
    &-1 {
      border-radius: 30px;
      color: $white;
      background-color: $primary;
    }
    &-2 {
      border-radius: 30px;
      text-transform: uppercase;
      background-color: #111;
      color: $white;
      @include on-event() {
        background-color: #000;
      }
    }
  }

  @include on-event() {
    @extend .shadow-49;
  }
}

:root[data-theme="dark"] .forCards {
  &--btn {
    &-2 {
      @include transition("background-color 0.7s ease, color 0.9s ease");
      @include on-event() {
        color: #111;
        background-color: $white;
      }
    }
  }
}
