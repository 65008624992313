// ======================================================
// Layout :: Wordpress Widget
// ======================================================

// Include components
// ------

@import "info";

.affiliate {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.67),
        rgba(24, 25, 35, 0.67)
      ),
      url("../../../assets/images/resources/luke-chesser-JKUTrJ4vK00-unsplash.webp");
  }
}
