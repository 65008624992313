// ======================================================
// Components :: Button
// ======================================================

// .slick-list {
//   margin: 0 2rem;
// }
// .slick-slider {
//   padding: 2rem 0;
// }

// .slick-arrow svg {
//   padding: 5px;
// }

// .slick-arrow {
//   cursor: pointer;
//   position: absolute;
//   top: 50%;
//   width: 25px;
//   height: 35px;
// }
// .slick-arrow::before {
//   content: none;
// }
// .slick-prev {
//   left: 3px;
// }

// .slick-next {
//   right: 3px;
// }

// .slick-prev polyline,
// .slick-prev polyline {
//   stroke-width: 2;
// }
// .slick-prev:hover polyline,
// .slick-prev:focus polyline {
//   stroke-width: 3;
// }

// .slick-prev:active polyline {
//   stroke-width: 6;
//   transition: all 100ms ease-in-out;
// }

// .slick-next:hover polyline,
// .slick-next:focus polyline {
//   stroke-width: 3;
// }

// .slick-next:active polyline {
//   stroke-width: 6;
//   transition: all 100ms ease-in-out;
// }

// polyline {
//   transition: all 250ms ease-in-out;
// }

// .slick-dots li {
//   width: auto;
//   height: auto;
//   padding: 3px;
// }
// .slick-dots li button::before {
//   content: none;
// }
// .slick-dots li button {
//   content: "";
//   width: 7px;
//   height: 7px;
//   border: 1px solid transparent;
//   border-radius: 50%;
//   background-color: $primary;
//   opacity: 0.8;
// }

// .slick-dots li button:hover {
//   opacity: 1;
//   @include transition("opacity 0.2s ease-in");
// }
// .slick-dots li.slick-active {
//   border-color: $primary;
//   border-radius: 50%;
// }
// .slick-dots li.slick-active button {
//   transform: scale(1.1);
// }

.carousel {
  .slick-list {
    margin: 0 2rem;
    @include breakpoint-down(sm) {
      margin: 0 10px;
    }
  }
  .slick-slider {
    padding: 2rem 0;
  }

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: 25px;
    height: 35px;
    &::before {
      content: none;
    }
    svg {
      padding: 5px;
    }
  }
  .slick-prev {
    left: 3px;
    polyline {
      stroke-width: 2;
    }
    &:hover polyline,
    &:focus polyline {
      stroke-width: 3;
    }
    &:active polyline {
      stroke-width: 6;
      transition: all 100ms ease-in-out;
    }
  }

  .slick-next {
    right: 3px;
    &:hover polyline,
    &:focus polyline {
      stroke-width: 3;
    }
    &:active polyline {
      stroke-width: 6;
      transition: all 100ms ease-in-out;
    }
  }

  polyline {
    transition: all 250ms ease-in-out;
  }

  .slick-dots li {
    width: 20px;
    height: 20px;
    padding: 3px;
    border-radius: 50%;
    @include transition("transform 0.3s ease-in");

    transform: scale(0.9);
    button {
      &::before {
        content: none;
      }
      content: "";
      width: 15px;
      height: 15px;
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: $primary;
      opacity: 0.6;
    }
  }

  .slick-dots li button:hover {
    opacity: 1;
  }
  .slick-dots li.slick-active {
    border-color: $primary;
    transform: scale(1.4);
  }
  .slick-dots li.slick-active button {
    opacity: 1;
  }
}
