// ======================================================
// Layout :: Home
// ======================================================

// Include components
// ------

@import "banner";
@import "forCards";
@import "serviceCards";
@import "learnCards";
@import "ourPlatform";
@import "topServices";
@import "visit";

.home {
  .partnersBrand {
    &--icon {
      @include display(flex);
      justify-content: center;
      align-items: center;

      svg {
        width: 100%;
        height: 40px;
        path {
          fill: getTheme(text);
        }
      }
      #logoDark34 {
        g > path {
          fill: transparent;
          stroke: getTheme(text) !important;
        }
        text {
          fill: getTheme(text) !important;
        }
      }
      #debank34 {
        text {
          fill: getTheme(text) !important;
        }
      }
    }
  }
}
