// ======================================================
// Layout :: OurStory
// ======================================================

// Include components
// ------
@import "releaseStorie";
@import "allStories";

.ourStory {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.6),
        rgba(24, 25, 35, 0.6)
      ),
      url("../../../assets/images/premium/headway-5QgIuuBxKwM-unsplash.webp");
  }

  &--h1 {
    color: getTheme(text);
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 1rem;
    font-weight: 500;
    text-align: center;
  }
  &--p {
    color: getTheme(text-light2);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
    font-weight: 400;
    text-align: center;
  }
  @include breakpoint-down("sm") {
    &--p {
      font-size: $fontSize-base;
      line-height: 22px;
    }
    &--h1 {
      font-size: 22px;
      line-height: 24px;
    }
  }
}
