// ======================================================
// Base :: Keyframes
// ======================================================

// -- Slide Animations -- //

// Slide Out Top from Center
@include keyframes(slide-out-up) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@include keyframes(slide-out-down) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 100%);
  }
}

// Slide Out Left from Center
@include keyframes(slide-out-left) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

// Slide out Right from Center
@include keyframes(slide-out-right) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@include keyframes(slide-in-up) {
  0% {
    transform: translate(0, 100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@include keyframes(slide-in-down) {
  0% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

// Slide in Left to Center
@include keyframes(slide-in-left) {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

// Slide in Right to Center
@include keyframes(slide-in-right) {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

// Icon pulse to right
@include keyframes(icon-pulse-right) {
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, 0);
  }
}
