// ======================================================
// Section :: OurTeam Banner
// ======================================================

.referralProgram {
  .startEarning {
    &--box {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 20px 0;
    }
    &--icon {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      margin-bottom: 1rem;
      svg {
        width: 60px;
        height: 60px;
      }
      svg {
        fill: $primary;
      }
      i {
        position: absolute;
        svg {
          @include size(75px, 75px);
        }
      }
    }
    &--h3 {
      color: getTheme(text);
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      margin-bottom: 2rem;
    }
    &--p {
      color: getTheme(text);
      font-size: 22px;
      line-height: 26px;
      text-align: center;
    }
  }
}

:root[data-theme="dark"] .referralProgram {
  .startEarning {
    &--icon {
      i {
        position: absolute;
        svg {
          @include size(75px, 75px);
        }
      }
      svg {
        path {
          fill: white;
        }
      }
    }
    &--p {
      color: getTheme(text-light);
    }
    &--box {
      border: 1px solid transparent;
      border-radius: 8px;
      @include transition("border-color 0.6s ease-in");

      &:hover {
        border-color: $primary;
      }
    }
  }
}
