// ======================================================
// Layout :: OutPlatform
// ======================================================

.ourPlatform {
  &--img {
    background-image: url("../../../assets/images/home/312.jpg");
    height: 450px;
    width: 100%;
  }
  &--box {
    @include display(flex);
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
  }
  &--h2 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    text-transform: capitalize;
    color: $primary;
  }
  &--p {
    font-size: $fontSize-large;
    line-height: 26px;
    color: getTheme(text-light);
    font-weight: 500;
  }
  &--btn {
    &-1 {
      border-radius: 30px;
      color: $white;
      background-color: $primary;
      font-size: 14px;
      //   padding: 8px 36px;
    }
    &-2 {
      border-radius: 30px;
      font-size: 14px;
      //   padding: 8px 36px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: #111;
      color: $white;
      @include on-event() {
        background-color: #000;
      }
    }
  }

  @include breakpoint-down("xs") {
    &--img {
      height: 290px;
    }
    &--box {
      align-items: center;
    }
    &--h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    // &--btn {
    //   &-1 {
    //     padding: 6px 28px;
    //   }
    //   &-1 {
    //     padding: 6px 28px;
    //   }
    // }
  }
  //   @include breakpoint-down("xss") {
  //     &--btn {
  //       &-1 {
  //         padding: 4px 24px;
  //       }
  //       &-1 {
  //         padding: 4px 24px;
  //       }
  //     }
  //   }
}

:root[data-theme="dark"] .ourPlatform {
  &--h2 {
    color: white;
  }
  &--btn {
    &-2 {
      @include transition("background-color 0.7s ease, color 0.9s ease");
      @include on-event() {
        color: #111;
        background-color: $white;
      }
    }
  }
}
