// ======================================================
// Component :: history
// ======================================================

.history {
  .info {
    @include display(flex);
    justify-content: center;
    flex-flow: column;
    @include breakpoint-down("sm") {
      .img {
        order: 1;
      }
    }

    &--img {
      background-image: url("../../../assets/images/about/you-x-ventures-Oalh2MojUuk-unsplash.png");
      height: 400px;
      width: 90%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 150px;
      background-size: cover;
      opacity: 0.9;
      &:hover {
        opacity: 1;
        @include transition("opacity 0.5s ease-in");
      }
      @include breakpoint-down("sm") {
        height: 300px;
      }
    }
    &--box {
      @include display(flex);
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
      h1,
      p {
        text-align: left;
      }
      @include breakpoint-down("sm") {
        align-items: flex-start;
        h1,
        p {
          text-align: left;
        }
      }
    }
    &--h1 {
      color: getTheme(text);
      font-size: 32px;
      line-height: 58px;
      margin-bottom: 1rem;
      font-weight: 600;
      text-align: center;
      &::before {
        content: "";
        position: absolute;
        width: 70px;
        border: 2px solid $primary;
      }
    }
    &--p {
      color: getTheme(text-light2);
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 1rem;
      font-weight: 400;
      text-align: center;
    }
    @include breakpoint-down("sm") {
      &--p {
        font-size: $fontSize-base;
        line-height: 22px;
      }
      &--h1 {
        font-size: 22px;
        line-height: 34px;
      }
    }
  }
}
