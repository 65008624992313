// ======================================================
// Section :: all stories
// ======================================================

.lastnews {
  .shapeleft {
    z-index: -1;
    margin-top: 474px !important;
    position: absolute;
    @include breakpoint-down("lg") {
      margin-top: 464px !important;
    }
    @include breakpoint-down("sm") {
      margin-top: 454px !important;
    }
  }

  .shaperight {
    margin-bottom: -1px;
  }
  .shaperight,
  .shapeleft {
    transform: skewY(-2deg);
  }
  .allStories {
    &--bg {
      background-image: url("../../../assets/images/career/yannis-papanastasopoulos-myKAyItJW_Y-unsplash.webp");
      width: 100%;
      transform: skewY(-2deg);
      height: 450px;
      z-index: -1;
      position: absolute;
    }

    .img {
      height: 450px;
      width: 100%;
      border-radius: 0 0 32px 32px;
      background-size: cover;
    }
    .img_1 {
      background-image: url("../../../assets/images/premium/sebastien-uwagwk2FywU-unsplash.jpg");
    }
    .img_2 {
      background-image: url("../../../assets/images/premium/jack-finnigan-g4B4x6hhkO0-unsplash.jpg");
    }
    .img_3 {
      background-image: url("../../../assets/images/premium/paz-arando-qZIM5rutBZM-unsplash.jpg");
    }
    .lastnews--h2 {
      color: #fff !important;
    }

    &--boxes {
      div:nth-child(even) .img {
        order: 1;
        border-radius: 32px 32px 0 0;
      }
      div:nth-child(even) .allStories--boxes--card--text {
        order: 2;
      }
      &--card {
        border: 1px solid getTheme(border);
        border-radius: 32px;
        background-color: #fff;
        @include display(flex);
        justify-content: center;
        align-items: center;
        flex-flow: column;
        @include transition("border-color 0.5s ease-in");
        &--text {
          @include display(flex);
          justify-content: center;
          align-items: center;
          flex-flow: column;
          padding: 10px;
        }
        &--h4 {
          color: getTheme(text-light);
          font-size: 21px;
          line-height: 26px;
          margin-bottom: 0.5rem;
        }
        &--h6 {
          color: getTheme(text-light);
          font-size: 17px;
          line-height: 22px;
          margin-bottom: 0.5rem;
          color: $primary;
        }
        hr {
          color: getTheme(border);
          width: 40%;
          height: 2px;
          margin: 10px auto;
        }
        &--p {
          color: getTheme(text-light2);
          font-size: 15px;
          line-height: 19px;
          margin-bottom: 1rem;
        }

        @include breakpoint-down("sm") {
          &--h4 {
            font-size: 17px;
            line-height: 20px;
          }
        }
        &:hover {
          border-color: $primary;
        }
      }
    }
  }
}
