// ======================================================
// Components :: Breadcrumbs
// ======================================================

.breadcrumbs {
  @include display("flex");
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;

  &--link {
    font-weight: 500;
    font-size: 20px;
    height: 25px;
    padding: 3px;
    // color: getTheme("text");
    color: #8f8f8f;
  }
  &--p {
    font-weight: 500;
    font-size: 20px;
    height: 25px;
    padding: 3px;
    color: getTheme("text");
  }
  &--icon {
    color: #8f8f8f;
    stroke-width: 1px;
    @include size(16px, 16px);
    g {
      fill: #8f8f8f;
    }
  }
  &--iconHome {
    @include size(30px, 30px);
    color: #8f8f8f;
    stroke-width: 1px;
    g {
      fill: #8f8f8f;
    }
  }

  @include breakpoint-down(sm) {
    padding: 20px 5px !important;
    &--link {
      font-size: 18px;
    }
    &--p {
      font-size: 18px;
    }
    &--icon {
      @include size(18px, 18px);
    }
  }
  @include breakpoint-down(xs) {
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
    padding: 5px !important;
    &--link {
      font-size: 15px;
    }
    &--p {
      font-size: 15px;
    }
    &--icon {
      @include size(15px, 15px);
    }
    &--iconHome {
      @include size(20px, 20px);
    }
  }
}

:root[data-theme="dark"] .breadcrumbs {
  &--p {
    color: #fff;
  }
}
