// ======================================================
// Section :: OurTeam Team
// ======================================================

.ourTeam {
  .team {
    &--cards {
      @include display(flex);
      justify-content: space-evenly;
      align-items: flex-start;
      flex-flow: row wrap;
    }
    &--1 {
      background-image: url("../../../assets/images/about/adam.png");
    }
    &--2 {
      background-image: url("../../../assets/images/about/eldad.png");
    }
    &--3 {
      background-image: url("../../../assets/images/about/kristy.png");
    }
    &--4 {
      background-image: url("../../../assets/images/about/lior.png");
    }
    &--5 {
      background-image: url("../../../assets/images/about/paul.png");
    }
    &--6 {
      background-image: url("../../../assets/images/about/stephen.png");
    }
    &--7 {
      background-image: url("../../../assets/images/about/aviv.png");
    }
    &--8 {
      background-image: url("../../../assets/images/about/ran.png");
    }
    &--9 {
      background-image: url("../../../assets/images/about/gil.png");
    }
    &--10 {
      background-image: url("../../../assets/images/about/neomi.png");
    }
    &--11 {
      background-image: url("../../../assets/images/about/ehud.png");
    }
    &--12 {
      background-image: url("../../../assets/images/about/gshir.png");
    }
  }
}
