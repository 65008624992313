// ======================================================
// Layout :: jobs
// ======================================================

// Include components
// ------

@import "jobOpen";
@import "jobList";

.jobs {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.5),
        rgba(24, 25, 35, 0.5)
      ),
      url("../../../assets/images/career/brooke-cagle-g1Kr4Ozfoac-unsplash.webp");
  }
  &--h2 {
    color: getTheme(text);
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 1rem;
    font-weight: 600;

    @include breakpoint-down("sm") {
      font-size: 20px;
      line-height: 22px;
    }
    @include breakpoint-down("xs") {
      font-size: 17px;
      line-height: 22px;
    }
  }

  &--p {
    color: getTheme(text);
    font-size: 19px;
    line-height: 22px;
    font-weight: 500;
    @include breakpoint-down("sm") {
      font-size: 18px;
      line-height: 22px;
    }
    @include breakpoint-down("xs") {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .laofficeMap-img1 {
    background-image: url("../../../assets/images/home/austin-distel-wawEfYdpkag-unsplash.png");
  }
  .laofficeMap-img2 {
    background-image: url("../../../assets/images/home/campaign-creators-gMsnXqILjp4-unsplash.png");
  }
  .laofficeMap-img3 {
    background-image: url("../../../assets/images/about/you-x-ventures-Oalh2MojUuk-unsplash.png");
  }
  .input--tooltip--form {
    border-radius: 35px;
    width: 270px;
  }
}
