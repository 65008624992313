//
// Base :: Utility Classes
// --------------------------------------------------

/// Margin, Padding
/// Used to override styles on components without need for
/// additional modifier classes
///
/// Usage:
/// <div class="mb0"> // margin-bottom: 0
/// <div class="mt20"> // margin-top: 20px

$property-map: (
  m: margin,
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left,
  p: padding,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left,
  br: border-radius,
);
$sizes-list: 0 2 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
$sizes-list2: 0 2 4 8 12 16 20 24 28 32 36 40 44 48 52 56 60 64 68 70 74 78 82
  86 90 94 98 100;

@each $size in $sizes-list {
  $val: $size + px;

  @each $keyword, $property in $property-map {
    .u-#{$keyword}-#{$size} {
      #{$property}: $val !important ;
    }
  }
}

@each $size in $sizes-list2 {
  $val: #{$size + "%"};

  @each $keyword, $property in $property-map {
    .z-#{$keyword}-#{$size} {
      #{$property}: $val !important ;
    }
  }
}

/// Auto
@each $keyword, $property in $property-map {
  .u-#{$keyword}-auto {
    #{$property}: auto;
  }
}

// Border circle
.br-half {
  border-radius: 50% !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  @include transition("visibility 0s, opacity .5s linear");
}
.show {
  visibility: visible;
  opacity: 1;
  @include transition("visibility 0s, opacity .5s linear");
}

.flexbox-center {
  @include display(flex);
  justify-content: center;
  &-c {
    align-items: center;
    @extend .flexbox-center;
  }

  &-e {
    align-items: flex-end;
    @extend .flexbox-center;
  }

  &-b {
    align-items: baseline;
    @extend .flexbox-center;
  }

  &-s {
    align-items: flex-start;
    @extend .flexbox-center;
  }
}
.flexbox-between {
  @include display(flex);
  justify-content: space-between;
  &-c {
    align-items: center;
    @extend .flexbox-between;
  }

  &-e {
    align-items: flex-end;
    @extend .flexbox-between;
  }

  &-b {
    align-items: baseline;
    @extend .flexbox-between;
  }

  &-s {
    align-items: flex-start;
    @extend .flexbox-between;
  }
}
.flexbox-around {
  @include display(flex);
  justify-content: space-around;
  &-c {
    align-items: center;
    @extend .flexbox-around;
  }

  &-e {
    align-items: flex-end;
    @extend .flexbox-around;
  }

  &-b {
    align-items: baseline;
    @extend .flexbox-around;
  }

  &-s {
    align-items: flex-start;
    @extend .flexbox-around;
  }
}
.flexbox-evenly {
  @include display(flex);
  justify-content: space-evenly;
  &-c {
    align-items: center;
    @extend .flexbox-evenly;
  }

  &-e {
    align-items: flex-end;
    @extend .flexbox-evenly;
  }

  &-b {
    align-items: baseline;
    @extend .flexbox-evenly;
  }

  &-s {
    align-items: flex-start;
    @extend .flexbox-evenly;
  }
}
.flexbox-start {
  @include display(flex);
  justify-content: flex-start;
  &-c {
    align-items: center;
    @extend .flexbox-start;
  }

  &-e {
    align-items: flex-end;
    @extend .flexbox-start;
  }

  &-b {
    align-items: baseline;
    @extend .flexbox-start;
  }

  &-s {
    align-items: flex-start;
    @extend .flexbox-start;
  }
}
.flexbox-end {
  @include display(flex);
  justify-content: flex-end;
  &-c {
    align-items: center;
    @extend .flexbox-end;
  }

  &-e {
    align-items: flex-end;
    @extend .flexbox-end;
  }

  &-b {
    align-items: baseline;
    @extend .flexbox-end;
  }

  &-s {
    align-items: flex-start;
    @extend .flexbox-end;
  }
}
.items-stretch {
  align-items: stretch;
}
