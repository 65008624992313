// ======================================================
// Layout :: Advertisers
// ======================================================

// Include components
// ------

@import "shopProces";
@import "allFeatures";

.advertisers {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/home/banner.webp");
    height: 620px;

    @include breakpoint-down(md) {
      height: 500px;
    }
    @include breakpoint-down(sm) {
      height: 600px;
    }
    &--h4 {
      text-align: left;
    }
  }

  .bannerImg {
    width: 100%;
    height: 300px;
    background-image: url("../../../assets/images/ads/Component2.webp");
    @include breakpoint-down(md) {
      height: 200px;
    }
  }

  .monetize {
    &--img {
      background-image: url("../../../assets/images/ads/Image49.webp");
      width: 100%;
      height: 350px;
      @include breakpoint-down(sm) {
        height: 300px;
      }
    }
    .btn {
      border-radius: 25px;
    }
  }
  .trusted {
    &--img {
      background-image: url("../../../assets/images/ads/Image50.webp");
      width: 100%;
      height: 500px;
      @include breakpoint-down(sm) {
        height: 300px;
      }
    }
    .btn {
      border-radius: 25px;
    }
  }
  &--h1 {
    color: getTheme(text);
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 2rem;
  }
  &--p {
    color: getTheme(text-light2);
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 2rem;
  }
  @include breakpoint-down(sm) {
    &--h1 {
      font-size: 25px;
      line-height: 30px;
    }
    &--p {
      font-size: 15px;
      line-height: 24px;
    }
  }
}
