// ======================================================
// Section :: sliderGallery
// ======================================================

.about {
  .sliderGallery {
    &--content {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      margin: 0px;
      height: auto;
    }
    .slick-list {
      height: 600px;
    }
    img {
      margin: 8px;
    }
    .img {
      height: 100%;
    }
    .img_about1 {
      background-image: url("../../../assets/images/about/Image59.webp");
    }
    .img_about2 {
      background-image: url("../../../assets/images/about/Image62.jpg");
    }
    .img_about3 {
      background-image: url("../../../assets/images/about/Image60.webp");
    }
    .img_about4 {
      background-image: url("../../../assets/images/about/Rectangle\ 361.jpg");
    }
    .img_about5 {
      background-image: url("../../../assets/images/about/Image63.webp");
    }

    .slick-dots li {
      width: 15px;
      height: 15px;
      padding: 3px;
      border-radius: 50%;
      @include transition("transform 0.3s ease-in");

      transform: scale(0.9);
      button {
        &::before {
          content: none;
        }
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid transparent;
        border-radius: 50%;
        background-color: $primary;
        opacity: 0.6;
      }
    }

    .slick-dots li button:hover {
      opacity: 1;
    }
    .slick-dots li.slick-active {
      border-color: $primary;
      transform: scale(1.2);
    }
    .slick-dots li.slick-active button {
      opacity: 1;
    }

    .slick-dots {
      margin-bottom: -33px;
    }
    .slick-arrow {
      cursor: pointer;
      position: absolute;
      @include display("flex");
      justify-content: center;
      align-items: center;
      top: 100%;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      z-index: 5;
      padding: 6px;
      margin-top: 50px !important;
      &::before {
        content: none;
      }
      svg {
        padding: 5px;
        polyline {
          stroke: $black;
        }
      }
      background-color: transparent;

      @include transition("background-color 0.2s ease-in");
      &:hover {
        background-color: #aaa;
      }
      &:hover svg {
        polyline {
          stroke: $white;
        }
      }
    }
    .slick-prev {
      left: 40%;
      polyline {
        stroke-width: 6;
      }
      &:hover polyline,
      &:focus polyline {
        stroke-width: 8;
      }
      &:active polyline {
        stroke-width: 10;
        transition: all 100ms ease-in-out;
      }
    }

    .slick-next {
      right: 40%;
      polyline {
        stroke-width: 6;
      }
      &:hover polyline,
      &:focus polyline {
        stroke-width: 7;
      }
      &:active polyline {
        stroke-width: 10;
        transition: all 100ms ease-in-out;
      }
    }
  }
}
