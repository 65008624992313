// ======================================================
// Section :: OurTeam Banner
// ======================================================

.referralProgram {
  .referralAccount {
    &--box {
      @include display(flex);
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
      @include breakpoint-down("sm") {
        margin-bottom: 80px;
      }
    }

    &--img {
      background-image: url("../../../assets/images/resources/bca48885632001.5d81b7e76036b.png");
      @include size(100%, 100%);
      @include breakpoint-down("sm") {
        @include size(300px, 300px);
      }
    }
    &--h3 {
      color: getTheme(text);
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    &--p {
      color: getTheme(text);
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 1rem;
    }
    .btn {
      margin-top: 1rem;
    }
  }
}

:root[data-theme="dark"] .referralProgram {
  .referralAccount {
    &--icon {
      color: #a1a1a1;
    }
  }
}
