// ======================================================
// Section :: filter
// ======================================================

.trends {
  .image {
    &--wrap {
      border: 1px solid getTheme(border);
      border-radius: 40px;
      @include breakpoint-down("sm") {
        border-radius: 20px;
      }
    }
    &--list {
      background-color: #fdf8fe;
      border-radius: 40px;
      height: 600px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      @include breakpoint-down("sm") {
        &::-webkit-scrollbar {
          width: 3px;
        }
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: #e6e6e6;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(171, 30, 222, 0.801);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 30, 222);
      }
      &--text {
        @include display(flex);
        justify-content: space-evenly;
        align-items: flex-start;
        flex-flow: column;
      }
    }
    &--h4 {
      color: $primary;
      font-size: 28px;
      line-height: 30px;
      font-weight: 600;

      margin-bottom: 10px;
      @include breakpoint-down(sm) {
        font-size: 20px;
        line-height: 23px;
      }
    }
    hr {
      border-color: $primary;
      width: 100%;
      border-width: thin;
      margin-bottom: 10px;
    }
    img {
      border-radius: 30px;
      @include breakpoint-down(sm) {
        padding: 10px;
        height: 200px;
      }
    }
    &--p {
      color: getTheme(text-light2);
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      text-transform: capitalize;
      margin-bottom: 10px;

      @include breakpoint-down("sm") {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

:root[data-theme="dark"] .trends {
  .image {
    &--list {
      background-color: #222;
    }
  }
}
