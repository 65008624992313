// ======================================================
// Section :: info
// ======================================================

.webinars {
  .info--boxes {
    .img {
      width: 100%;
      border-radius: 15px;
      background-size: cover;
      @include display(flex);
      justify-content: center;
      align-items: flex-end;
      p {
        color: white;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 24px;
      }
    }
    &--img_1 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/andrew-stutesman-l68Z6eF2peA-unsplash.jpg");
      height: 200px;
    }
    &--img_2 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/giorgio-trovato-WyxqQpyFNk8-unsplash.jpg");
      height: 200px;
    }
    &--img_3 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/jakob-owens-056F845W1eQ-unsplash.jpg");
      height: 200px;
    }
    &--img_4 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/john-schnobrich-2FPjlAyMQTA-unsplash.jpg");
      height: 200px;
    }
    &--img_5 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/home/City\ \(4\).jpg");
      height: 200px;
    }
    &--img_6 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/resources/jonathan-kemper-IZcbhj6tbos-unsplash.webp");
      height: 425px;
      @include breakpoint-down("sm") {
        height: 200px;
      }
    }
    &--img_7 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/caspar-camille-rubin-XA0v5hbb7HY-unsplash.jpg");
      height: 200px;
    }
    &--img_8 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/headway-5QgIuuBxKwM-unsplash.jpg");
      height: 200px;
    }
    &--img_9 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/dan-nelson-ah-HeguOe9k-unsplash.jpg");
      height: 200px;
    }
    &--img_10 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/what-is-forex_body_what_is_forex.jpg");
      height: 425px;
      @include breakpoint-down("sm") {
        height: 200px;
      }
    }
    &--img_11 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/patrick-tomasso-LczBrW5Z6FU-unsplash.jpg");
      height: 200px;
    }
    &--img_12 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.6),
          rgba(24, 25, 35, 0.6)
        ),
        url("../../../assets/images/forecast/bob-ghost-ZmLXBRfjSpo-unsplash.jpg");
      height: 200px;
    }
  }
}
