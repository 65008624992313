// ======================================================
// Layout :: Lexicon
// ======================================================

// Include components
// ------

@import "topics";
@import "details";

.lexicon {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.5),
        rgba(24, 25, 35, 0.5)
      ),
      url("../../../assets/images/lexicon/Laptop-10.jpg");
  }
}
