// ======================================================
// Section :: Ads shopProces
// ======================================================

.advertisers {
  .shopProces {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/ads/cardmapr-XH2JFgT4Abc-unsplash.webp");
    height: auto;

    .iconCard {
      background-color: $white;
      .circle {
        @include transition("transform 0.5s ease-in-out");
      }
      &:hover .circle {
        transform: translate(0, -10px);
      }
      &--p {
        padding-top: 10px;
        line-height: 25px;
      }
      @include breakpoint-down("xs") {
        width: 80%;
        margin: 10px auto;
        &--p {
          width: 80%;
          margin: auto;
        }
      }
    }
    &--h2 {
      color: $white;
      text-align: left;
      font-size: 2rem;
      margin-bottom: 2.2rem;
      font-weight: 600;
      @include breakpoint-down("md") {
        margin-bottom: 1.2rem;
        font-size: 1.7rem;
        margin: 0 auto;
        text-align: center;
        width: 90%;
      }
      @include breakpoint-down("xs") {
        font-size: 1rem;
        margin-bottom: 0.8rem;
      }
    }
  }
}

:root[data-theme="dark"] .advertisers {
  .shopProces {
    .iconCard {
      background-color: getTheme(border);
    }
  }
}
