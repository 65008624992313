// ======================================================
// Example:
// .class {
//   @extend .className;
// }
// Base :: Shadow
// ======================================================

.shadow-1 {
  @include box-shadow("rgba(149, 157, 165, 0.2) 0px 8px 24px;");
}
.shadow-2 {
  @include box-shadow("rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;");
}
.shadow-3 {
  @include box-shadow("rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;");
}
.shadow-4 {
  @include box-shadow("rgba(0, 0, 0, 0.35) 0px 5px 15px;");
}
.shadow-5 {
  @include box-shadow("rgba(0, 0, 0, 0.16) 0px 1px 4px;");
}
.shadow-6 {
  @include box-shadow("rgba(0, 0, 0, 0.24) 0px 3px 8px;");
}
.shadow-7 {
  @include box-shadow("rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;");
}
.shadow-8 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"
  );
}
.shadow-9 {
  @include box-shadow("rgba(0, 0, 0, 0.1) 0px 4px 12px;");
}
.shadow-10 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;"
  );
}
.shadow-11 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;"
  );
}
.shadow-12 {
  @include box-shadow("rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;");
}
.shadow-13 {
  @include box-shadow(
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"
  );
}
.shadow-14 {
  @include box-shadow(
    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
  );
}
.shadow-15 {
  @include box-shadow(
    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
  );
}
.shadow-16 {
  @include box-shadow(
    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;"
  );
}
.shadow-17 {
  @include box-shadow(
    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
  );
}
.shadow-18 {
  @include box-shadow(
    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
  );
}
.shadow-19 {
  @include box-shadow(
    "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;"
  );
}
.shadow-20 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
  );
}
.shadow-21 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;"
  );
}
.shadow-22 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;"
  );
}
.shadow-23 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;"
  );
}
.shadow-24 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;"
  );
}
.shadow-25 {
  @include box-shadow(
    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
  );
}
.shadow-26 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;"
  );
}
.shadow-27 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
  );
}
.shadow-28 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;"
  );
}
.shadow-29 {
  @include box-shadow("rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;");
}
.shadow-30 {
  @include box-shadow("rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;");
}
.shadow-31 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
  );
}
.shadow-32 {
  @include box-shadow("rgba(0, 0, 0, 0.1) 0px 10px 50px;");
}
.shadow-33 {
  @include box-shadow("rgba(0, 0, 0, 0.04) 0px 3px 5px;");
}
.shadow-34 {
  @include box-shadow(
    "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; "
  );
}
.shadow-35 {
  @include box-shadow(
    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;"
  );
}
.shadow-36 {
  @include box-shadow("rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;");
}
.shadow-37 {
  @include box-shadow(
    "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;"
  );
}
.shadow-38 {
  @include box-shadow("rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;");
}
.shadow-39 {
  @include box-shadow("rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;");
}
.shadow-40 {
  @include box-shadow("rgba(17, 17, 26, 0.1) 0px 1px 0px;");
}
.shadow-41 {
  @include box-shadow(
    "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;"
  );
}
.shadow-42 {
  @include box-shadow("rgba(17, 17, 26, 0.1) 0px 0px 16px;");
}
.shadow-43 {
  @include box-shadow(
    "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;"
  );
}
.shadow-44 {
  @include box-shadow("rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;");
}
.shadow-45 {
  @include box-shadow("rgba(0, 0, 0, 0.08) 0px 4px 12px;");
}
.shadow-46 {
  @include box-shadow("rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;");
}
.shadow-47 {
  @include box-shadow("rgba(0, 0, 0, 0.18) 0px 2px 4px;");
}
.shadow-48 {
  @include box-shadow("rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;");
}
.shadow-49 {
  @include box-shadow(
    "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;"
  );
}
