// ======================================================
// Layout :: Ads Format
// ======================================================

// Include components
// ------

@import "banner";
@import "formats";
@import "meet";
@import "mobile";

.adsFormat {
  .campaings {
    background-color: #f1f1f1;
  }
}

[data-theme="dark"] .adsFormat {
  .campaings {
    background-color: getTheme(bg);
  }
}
