// ======================================================
// Component :: slider
// ======================================================

.laOffice {
  .slider {
    &--flag {
      margin-left: 30px;
      & > svg {
        width: 50px;
        height: auto;
        border-radius: 4px;
      }
    }
  }
  .laofficeMap-img1 {
    background-image: url("../../../assets/images/home/austin-distel-wawEfYdpkag-unsplash.png");
  }
  .laofficeMap-img2 {
    background-image: url("../../../assets/images/home/campaign-creators-gMsnXqILjp4-unsplash.png");
  }
  .laofficeMap-img3 {
    background-image: url("../../../assets/images/about/you-x-ventures-Oalh2MojUuk-unsplash.png");
  }
}
