// ======================================================
// Layout :: Faq Question
// ======================================================

.question {
  .accordion {
    margin-bottom: 3rem;

    @include breakpoint-down("md") {
      margin-bottom: 2rem;
    }

    @include breakpoint-down("sm") {
      margin-bottom: 1rem;
    }

    &--details {
      @include display("flex");
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
    }
  }
}
