/// ======================================================
/// @author Albrim Paqarizi
/// Abstracts :: Mixins
/// ======================================================

// Responsive
// @include breakpoint-down(sm) {
//   code here
// }

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints

$breakpoints: (
  "xss": 329.98px,
  "xs": 480.98px,
  "sm": 767.98px,
  "md": 991.98px,
  "lg": 1199.98px,
  "xl": 1600px,
);

@mixin breakpoint-down($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media screen and (max-width: #{$query}) {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin breakpoint-up($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media screen and (min-width: #{$query}) {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}
@mixin breakpoint-between($from, $to) {
  $raw-query1: map-get($breakpoints, $from);
  $raw-query2: map-get($breakpoints, $to);

  @if $raw-query1 $raw-query2 {
    $query1: if(
      type-of($raw-query1) == "string",
      unquote($raw-query1),
      inspect($raw-query1)
    );
    $query2: if(
      type-of($raw-query2) == "string",
      unquote($raw-query2),
      inspect($raw-query2)
    );

    @media screen and (min-width: #{$query1}) and (max-width: #{$query2}) {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Position
// ======================================================
// Example:
//
// .class {
//   @include position(absolute, 10px, 10px, 10px, 10px);
// }
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Width !== Height
// ======================================================
// Example:
//
// .class {
//   @include size(10px, 20px);
// }
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

// Flexbox
// ======================================================
// Example:
//
// .class {
//   @include display(flex);
// }
@mixin display($str) {
  --webkit-display: #{$str};
  --moz-display: #{$str};
  --o-display: #{$str};
  --ms-display: #{$str};
  display: #{$str};
}

// Width === Height
// ======================================================
// Example:
//
// .class {
//   @include square(10px);
// }
@mixin square($size) {
  @include size($size, $size);
}

// Width === Height + Border Radius
// ======================================================
// Example:
//
// .class {
//   @include circle(10px);
// }
@mixin circle($size) {
  @include size($size, $size);
  border-radius: 50%;
}

// Reset UL OL
// ======================================================
// Example:
//
// .class {
//   @include list-unstyled();
// }
@mixin list-unstyled() {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Truncate oneline
// ======================================================
// Already supported in Bootstrap 4 mixin: @include text-truncate()
// Example:
//
// .class {
//   @include truncate-oneline();
// }
@mixin truncate-oneline() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Truncate multiline with CSS3
// ======================================================
// Example:
//
// .class {
//   @include truncate-multiline-new(3);
// }
@mixin truncate-multiline-new($line-number) {
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-line-clamp: $line-number;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

// Truncate multiline with CSS2
// Example:
//
// .class {
//   @include truncate-multiline-old({ $line-number: 2 });
// }
@mixin truncate-multiline-old(
  $line-height: 1.2em,
  $line-number: 1,
  $bg-color: $white
) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: $line-height * $line-number;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bg-color;
  }
}

// ===========================================================
// Mixin for BEM - Elements
// ===========================================================
@mixin e($selector) {
  &__#{$selector} {
    @content;
  }
}

// ===========================================================
// Mixin for BEM - Modifier
// ===========================================================
@mixin m($selector) {
  &--#{$selector} {
    @content;
  }
}

// ===========================================================
// Keyframes animate
// Example:
//
// .class {
//   @include keyframes('keyframes-key') {
//     50% {
//         //something else
//       }
//    }
// }
// ===========================================================
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}
// ===========================================================
// Hovering icon animated
// Example:
//
// .class {
//   @include animated-hover(animate-key);
// }
// ===========================================================
@mixin animated-hover($keyframe, $duration: 0.5s, $count: 3) {
  &:hover {
    .animated-hover {
      animation: #{$keyframe} $duration;
      animation-iteration-count: $count;
    }
  }
}
// ===========================================================
// Animation
// Example:
//
// .class {
//   @include animation('animate-key');
// }
// ===========================================================
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// ===========================================================
// Transition
// Example:
//
// .class {
//    @include transition('all 3s cubic-bezier(1, 0, 0, 1)');
// }
// ===========================================================
@mixin transition($str) {
  -webkit-transition: #{$str};
  -moz-transition: #{$str};
  -ms-transition: #{$str};
  -o-transition: #{$str};
  transition: #{$str};
}

// ===========================================================
// Box shadow
// Example:
//
// .class {
//    @include box-shadow('rgba(149, 157, 165, 0.2) 0px 8px 24px;');
// }
// ===========================================================
@mixin box-shadow($str) {
  -webkit-box-shadow: #{$str};
  -moz-box-shadow: #{$str};
  -ms-box-shadow: #{$str};
  -o-box-shadow: #{$str};
  box-shadow: #{$str};
}

// ===========================================================
// Image Background Responsive
// ===========================================================
@mixin background-image-responsive() {
  max-width: 100%;
  height: 0;
  // padding-bottom: ($height/$width) * 100%;
}

// ===========================================================
// Image Background Responsive
// ===========================================================
@mixin bg-img-responsive($height, $width) {
  max-width: 100%;
  height: 0;
  padding-bottom: ($height/$width) * 100%;
}

// ===========================================================
// Max Width Centering
// ===========================================================
@mixin width-centering($width) {
  width: $width;
  max-width: 100%;
  margin: 0 auto;
}

// ===========================================================
// Width
// ===========================================================
@mixin width($width) {
  width: $width;
  max-width: 100%;
}

// ===========================================================
// Event wrapper
// @param {Bool} $self (false) - Whether or not to include current selector
// Example:
// .class {
//  @include on-event-after(true) {
//     // something...
//   }
// }
// ===========================================================
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

// ===========================================================
// Event wrapper after classname
// Example:
// .class {
//  @include on-event-after(':after', true) {
//     // something...
//   }
// }
//
//  If you need for classname you must set space before classname
// .class
//  @include on-event-after(' className') {
//     // something...
//   }
// }
// ===========================================================
@mixin on-event-after($str, $self: false) {
  @if $self {
    &,
    &:hover #{$str},
    &:active #{$str},
    &:focus #{$str} {
      @content;
    }
  } @else {
    &:hover #{$str},
    &:active #{$str},
    &:focus #{$str} {
      @content;
    }
  }
}

// ===========================================================
// Drawing effect
// Example:
// .class {
//  @include border-drawing(#58afd1, #ff6600, 4px, bottom, right , 0.25s);
// }
// ===========================================================
@mixin border-drawing(
  $color,
  $hover,
  $width,
  $vertical,
  $horizontal,
  $duration
) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == "left", "right", "left");

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == "top", "bottom", "top");

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}
