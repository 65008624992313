// ======================================================
// Layout :: OurTeam
// ======================================================

// Include components
// ------

@import "banner";
@import "team";

.ourTeam {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.92),
      rgba(255, 255, 255, 0.92)
    ),
    url("../../../assets/images/about/pattern2.png");
  background-repeat: repeat;
  background-size: auto;
}

:root[data-theme="dark"] .ourTeam {
  background-image: linear-gradient(0deg, #131313eb, #131313eb),
    url("../../../assets/images/about/pattern2.png");
}
