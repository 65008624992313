// ======================================================
// Component :: Support
// ======================================================

.autoOptimization {
  .support {
    &--mainBox {
      @include display("flex");
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      &--img_1 {
        background-image: url("../../../assets/images/resources/8432.jpg");
        height: 300px;
      }
      &--img_2 {
        background-image: url("../../../assets/images/resources/Illustration.svg");
        height: 300px;
      }
      &--h4 {
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 3rem;
        margin-bottom: 2rem;
        text-transform: capitalize;
        color: getTheme(text);
      }
      &--p {
        margin-bottom: 2rem;
        font-size: 20px;
        line-height: 30px;
        color: #979797;
      }
      &--link {
        font-size: 20px;
        line-height: 24px;
        color: $primary;
      }
      @include breakpoint-down("sm") {
        &--h4 {
          font-size: 1.6rem;
          line-height: 2rem;
          margin-bottom: 0.5rem;
        }
        &--p {
          margin-bottom: 0.5rem;
          font-size: 18px;
          line-height: 22px;
        }
        &--link {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    &--card {
      @include display("flex");
      justify-content: center;
      align-items: center;
      flex-flow: column;
      border-radius: 15px;
      border: 1px solid transparent;
      padding: 20px 15px;
      @include transition("transform 0.3s ease-in , box-shadow 0.3s ease-in");
      &:hover {
        transform: scale(1.02);
        @extend .shadow-34;
      }
      @include breakpoint-down("xs") {
        padding: 10px;
      }
      & > .circle {
        @include size(90px, 90px);
        svg {
          @include size(35px, 35px);
          path {
            fill: $primary;
          }
        }
        #adjustments-horizontal > path {
          stroke: $primary;
          fill: none !important;
        }
      }

      &--h4 {
        font-size: $fontSize-large;
        font-weight: 500;
        line-height: 28px;
        text-transform: capitalize;
        color: getTheme(text);
        text-align: center;
      }
      &--p {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #979797;
        text-align: center;
      }
    }
    @include breakpoint-down(sm) {
      &--box {
        flex-flow: column;
      }
    }
  }
}

:root[data-theme="dark"] .autoOptimization {
  .support {
    &--card {
      border-color: transparent;
      @include transition("border-color 0.5s ease-in ");
      @include box-shadow("none");

      & > .circle {
        border-color: $primary !important;
        svg {
          path {
            fill: $white;
            @include transition("fill 0.7s ease-in ");
          }
        }
        #adjustments-horizontal > path {
          stroke: $white;
        }
        border-color: transparent;
        @include box-shadow("none");
        @include transition("border-color 0.7s ease-in ");
      }

      &:hover {
        border-color: $primary;
        @include box-shadow("none");
        transform: none;
      }

      @include breakpoint-down("xs") {
        padding: 10px;
      }
    }
  }
}
