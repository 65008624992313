.shape-chevron {
  &--left {
    transform: rotate(180deg);
  }

  &--sm {
    -webkit-clip-path: polygon(
      75% 0%,
      100% 53%,
      75% 100%,
      0% 100%,
      25% 50%,
      0% 0%
    );
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 18px 50%, 0% 0%);
    width: 80px;
    height: 35px;
    background-color: $black;
  }
  &--md {
    -webkit-clip-path: polygon(
      75% 0%,
      100% 53%,
      75% 100%,
      0% 100%,
      25% 50%,
      0% 0%
    );
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 18px 50%, 0% 0%);
    width: 160px;
    height: 35px;
    background-color: $primary;
  }

  @include breakpoint-down(sm) {
    &--sm {
      @include size(50px, 20px);
      clip-path: polygon(
        100% 0%,
        100% 50%,
        100% 100%,
        0% 100%,
        13px 50%,
        0% 0%
      );
    }
    &--md {
      @include size(100px, 20px);
      clip-path: polygon(
        100% 0%,
        100% 50%,
        100% 100%,
        0% 100%,
        13px 50%,
        0% 0%
      );
    }
  }
  @include breakpoint-down(xs) {
    &--sm {
      @include size(30px, 15px);
      clip-path: polygon(
        100% 0%,
        100% 50%,
        100% 100%,
        0% 100%,
        13px 50%,
        0% 0%
      );
    }
    &--md {
      @include size(60px, 15px);
      clip-path: polygon(
        100% 0%,
        100% 50%,
        100% 100%,
        0% 100%,
        13px 50%,
        0% 0%
      );
    }
  }
}

:root[data-theme="dark"] .shape-chevron {
  &--sm {
    display: none;
  }
  &--md {
    display: none;
  }
}
