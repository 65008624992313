// ======================================================
// Section :: publishCard
// ======================================================

.monetize {
  .publishCard {
    .increase--h2 {
      color: $primary;
      font-size: 2rem;
      margin-bottom: 1.4rem;
      font-weight: 600;
      @include breakpoint-down("sm") {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }

    .imgIncrease {
      background-image: url("../../../assets/images/forecast/Increase-Site-Traffic-Techniques.png");
      width: 100%;
      height: 300px;
      @include breakpoint-down("sm") {
        height: 200px;
      }
    }
    &--img_1 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/forecast/andrew-stutesman-l68Z6eF2peA-unsplash.jpg");
    }
    &--img_2 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/home/City\ \(4\).jpg");
    }
    &--img_3 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/forecast/giorgio-trovato-WyxqQpyFNk8-unsplash.jpg");
    }
    &--img_4 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/forecast/patrick-tomasso-LczBrW5Z6FU-unsplash.jpg");
    }
    &--img_5 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/forecast/markus-winkler-EKdM6EZ6HDY-unsplash.jpg");
    }
    &--img_6 {
      background-image: linear-gradient(
          0deg,
          rgba(24, 25, 35, 0.4),
          rgba(24, 25, 35, 0.4)
        ),
        url("../../../assets/images/forecast/john-schnobrich-2FPjlAyMQTA-unsplash.jpg");
    }
    &--card {
      @include display(flex);
      justify-content: space-evenly;
      align-items: flex-start;
      flex-flow: column;
      width: 100%;
      height: 240px;
      border-radius: 10px;
      border: 1px solid getTheme(border);
      background-size: cover;

      .img {
        @include size(50px, 50px);
      }
      &--img_1 {
        background-image: url("../../../assets/images/forecast/26-512.png");
      }
      &--img_2 {
        background-image: url("../../../assets/images/forecast/102649.png");
      }
      &--img_3 {
        background-image: url("../../../assets/images/forecast/images.png");
      }
      &--img_4 {
        background-image: url("../../../assets/images/forecast/Capture35.png");
      }
      &--img_5 {
        background-image: url("../../../assets/images/forecast/Referrals-512.png");
      }
      &--img_6 {
        background-image: url("../../../assets/images/forecast/Referrals-512a.png");
      }
      &--h4 {
        color: white;
        font-size: 22px;
        line-height: 22px;
        font-weight: 500;
        &::after {
          display: block;
          content: "";
          width: 20%;
          margin-left: 0;
          padding-top: 2px;
          border-bottom: 2px solid $primary;
        }
      }
      &--p {
        color: white;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 1rem;
        font-weight: 400;
      }
      @include breakpoint-down("sm") {
        &--p {
          font-size: $fontSize-base;
          line-height: 17px;
        }
        &--h4 {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}
