// ======================================================
// Components :: Banner
// ======================================================

.banner {
  height: 520px;
  padding-top: 90px;

  &--h1 {
    text-align: center;
    color: $white;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.7rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }
  &--h4 {
    text-align: center;
    color: $white;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .btn {
    padding: 6px 36px;
    margin: 10px 0;
  }

  @include breakpoint-down(lg) {
    height: 440px;
    padding-top: 70px;
  }
  @include breakpoint-down(md) {
    &--h1 {
      font-size: 2.5rem;
      line-height: 2.8rem;
    }
  }

  @include breakpoint-down(sm) {
    height: 380px;
    &--h1 {
      font-size: 2rem;
      line-height: 2.6rem;
    }
    &--h4 {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }
  @include breakpoint-down(xs) {
    &--h1 {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    &--h4 {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
}
