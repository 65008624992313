// ======================================================
// Layout :: Wordpress Widget
// ======================================================

// Include components
// ------

@import "info";

.wordpressWidget {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.6),
        rgba(24, 25, 35, 0.6)
      ),
      url("../../../assets/images/resources/stephen-phillips-hostreviews-co-uk-zs98a0DtKL4-unsplash.webp");
  }
}
