// ======================================================
// Components :: Button
// ======================================================

.btn {
  color: $white;
  background-color: $primary;
  opacity: 0.9;
  padding: 5px 24px initial;
  font-size: $fontSize-base initial;
  font-family: $fontPoppins;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px initial;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  cursor: pointer;
  margin: 0 inherit;
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  @include transition(
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), 0ms box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  );
  @include on-event() {
    background-color: #9023b8;
  }

  @include breakpoint-down(xs) {
    padding: 3px 20px;
    font-size: $fontSize-small;
  }

  &--disable {
    opacity: 0.9;
    cursor: not-allowed !important;
  }
  &--not-disable {
    opacity: 1;
    cursor: pointer;
  }

  &--outline {
    border: 1px solid $primary;
    background-color: transparent !important;
    color: $primary;
    @include transition("background-color 0.4s ease-in, color 0.4s ease-in");
    &:hover {
      background-color: $primary !important;
      color: #fff;
    }
  }
  &-focused {
    border: 1px solid transparent !important;
    &:focus {
      border-color: getTheme(bg) !important;
    }
  }

  &-xss {
    padding: 2px 16px;
    font-size: 12px;
  }
  &-xs {
    padding: 4px 22px;
    font-size: 14px;
    @include breakpoint-down(xs) {
      padding: 2px 18px;
      font-size: $fontSize-small;
    }
  }
  &-md {
    padding: 4px 24px;
    font-size: 16px;
    @include breakpoint-down(xs) {
      padding: 3px 20px;
      font-size: $fontSize-small;
    }
  }
  &-lg {
    padding: 6px 28px;
    font-size: 22px;
    @include breakpoint-down(xs) {
      padding: 4px 24px;
      font-size: $fontSize-medium;
    }
  }
  &-xl {
    padding: 9px 34px;
    font-size: 30px;
    @include breakpoint-down(xs) {
      padding: 4px 24px;
      font-size: $fontSize-medium;
    }
  }
  &-xxl {
    padding: 10px 40px;
    font-size: 34px;
    @include breakpoint-down(xs) {
      padding: 4px 24px;
      font-size: $fontSize-medium;
    }
  }

  .icon {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    margin-right: -10px;

    &--hover:hover > span > svg {
      @include animation("icon-pulse-right 0.8s ease-in-out infinite");
    }
  }
  &--dark {
    background-color: #111;
    color: $white;
    @include on-event() {
      background-color: #000;
    }
  }
  &--light {
    background-color: #fff;
    color: $primary;
    @include on-event() {
      background-color: #fff;
    }
  }

  &--pulse {
    &:hover {
      @include box-shadow("0 0 0 6px transparent");
      @include animation("pulse 1.5s");
    }
  }
}

@include keyframes(pulse) {
  from {
    @include box-shadow(0 0 0 0 $primary);
  }
}

:root[data-theme="dark"] .btn {
  &--dark {
    @include transition("background-color 0.7s ease, color 0.9s ease");
    @include on-event() {
      color: #111;
      background-color: $white;
    }
  }
}
