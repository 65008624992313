// ======================================================
// Component :: Ask
// ======================================================

.helpCenter {
  .ask {
    &--box {
      padding: 2rem;
      @include display("flex");
      justify-content: center;
      align-items: center;
      flex-flow: column;

      &--h4 {
        font-size: 34px;
        font-weight: 500;
        color: getTheme(text);
        margin-bottom: 1.5rem;
      }
      &--p {
        line-height: $fontSize-large;
        color: $gray-21;
        margin-bottom: 1.5rem;
        font-weight: 500;
      }
    }
    &--form {
      @include display("flex");
      justify-content: center;
      align-items: flex-start;
      flex-flow: row;
      width: 100%;
      margin: 0.7rem 0;

      &--input {
        width: 100%;
        margin-right: 10px;
        line-height: 16px;
        padding: 13px;
        padding-left: 15px;
        border: 1px solid #cecece;
        border-radius: 5px;
        &::placeholder {
          line-height: 17px;
          color: #797979;
          vertical-align: middle;
          font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
            "Lucida Sans", Arial, sans-serif;
          letter-spacing: 1px;
        }
      }
      &--btn {
        padding: 10px 85px;
        text-transform: capitalize;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        width: 150px;
      }
    }
    @include breakpoint-down(md) {
      &--box {
        padding: 0.8rem;
        &--h4 {
          font-size: 29px;
        }
      }
    }

    @include breakpoint-down(xs) {
      &--box {
        padding: 0.5rem;
        &--h4 {
          font-size: 24px;
          margin-bottom: 1rem;
        }
        &--p {
          margin-bottom: 0.5rem;
          font-size: 14px;
          text-align: center;
        }
      }
      margin-bottom: 2rem;
      &--form {
        margin-bottom: 0.6rem;
        &--btn {
          padding: 4px 15px;
          font-size: 13px;
        }
        &--input {
          padding: 6px;
          font-size: 12px;
          &::placeholder {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }
}

:root[data-theme="dark"] .helpCenter {
  .ask {
    &--form {
      &--input {
        background-color: #505050;
        border-color: #505050;
        color: white;
        &::placeholder {
          color: #cecece;
        }
      }
    }
  }
}
