// ======================================================
// Section :: info
// ======================================================

.retargeting {
  .info {
    &--card {
      padding: 20px;
      border-radius: 10px;
      border: 1px solid getTheme(border);
      .circle {
        @include size(80px, 80px);
        background-color: $primary;
        svg {
          @include size(30px, 30px);
          fill: #fff;
        }
      }
      .link {
        @include display("flex");
        justify-content: flex-start;
        align-items: center;
        flex-flow: row;
        svg {
          @include size(22px, 22px);
        }
        text-transform: uppercase;
      }
      &--h4 {
        color: getTheme(text-light);
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 1rem;
        font-weight: 400;
        @include breakpoint-down("sm") {
          font-size: 14px;
        }
      }
      &--p {
        color: getTheme(text-light2);
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 1rem;
        font-weight: 300;
        @include breakpoint-down("sm") {
          font-size: 14px;
          line-height: 22px;
        }
      }
      @include breakpoint-down("sm") {
        height: 160px;
      }
    }
  }
}
