// ======================================================
// Section :: topics
// ======================================================

.lexicon {
  .topics {
    &--h2 {
      color: getTheme(text);
      text-align: center;
      font-size: 2.8rem;
      margin-bottom: 2rem;
      font-weight: 600;
      @include breakpoint-down("md") {
        margin-bottom: 1.4rem;
        font-size: 2rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
    }
    &--box {
      border-radius: 10px;
    }
    &--nav {
      @include display("flex");
      justify-content: space-between;
      align-items: center;
      flex-flow: row;
      padding: 10px 25px;
      background-color: getTheme(bg);
      width: 100%;
      border: 1px solid #ededed;
      p,
      span {
        font-size: 1.1rem;
        padding: 7px;
        font-weight: 500;
        @include breakpoint-down("xs") {
          font-size: 0.9rem;
        }
      }
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
    .accordion {
      margin: 30px 10px;
      padding: 0;
      border-radius: 15px;
      &--acc {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
      .modal {
        cursor: default;
      }
      &--action {
        padding: 10px;
        // border-radius: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        @include transition("background-color 0.7s ease-in");
        h4 {
          color: getTheme(text-light2);
          @include transition("color 0.7s ease-in");
        }
        button {
          svg > path {
            fill: getTheme(text-light2);
            @include transition("fill 0.7s ease-in");
          }
        }
      }
      &--details {
        padding: 1px;
        background-color: #ededed;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}
.lexicon {
  [data-isopen="true"] .accordion--action {
    background-color: $primary !important;
    h4 {
      color: #fff;
    }
    button {
      svg > path {
        fill: #fff;
      }
    }
  }
}
