// ======================================================
// Layout :: learn Cards
// ======================================================

.learnCards {
  &--h3 {
    color: getTheme(text);
    font-weight: 600;
    font-size: 3rem;
  }
  &--p {
    color: $primary;
    font-weight: 400;
    font-size: 1.8rem;
  }
  @include breakpoint-down("xs") {
    &--h3 {
      font-size: 1.4rem;
      margin-top: 2rem;
    }
    &--p {
      font-size: 1.1rem;
    }
  }

  .box {
    &--p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: getTheme(text-light);
      font-weight: 400;
      @include breakpoint-down("xs") {
        font-size: 0.9rem;
        line-height: 1.3rem;
      }
    }
  }

  .icon {
    @include display(flex);
    justify-content: center;
    align-items: center;
    flex-flow: column;

    & > .fill {
      border: 1px solid transparent;
      border-radius: 10px;
      padding: 15px;
      @include size(105px, 120px);
      svg {
        @include size(42px, 42px);
      }
      svg > path {
        fill: $white;
        stroke: $white;
      }
      svg {
        @include transition("transform 0.6s ease-in-out");
      }
      @include transition("transform 0.6s ease-in-out");
      &:hover {
        transform: scale(0.95);
      }
      &:hover svg {
        transform: scale(0.95);
      }
    }
    &--text {
      font-weight: 500;
      line-height: 1.8rem;
      font-size: 1.5rem;
      color: getTheme(text-light);
      text-align: center;
    }

    @include breakpoint-down("sm") {
      justify-content: flex-start;
      &--text {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      & > .fill {
        padding: 10px;
        @include size(60px, 65px);
        svg {
          @include size(25px, 25px);
        }
      }
    }
  }
}

:root[data-theme="dark"] .learnCards {
  .icon {
    & > .fill {
      background-color: getTheme(bg);
      &:hover svg,
      &:hover {
        transform: none;
      }
    }
    border: 1px solid transparent;
    border-radius: 5px;
    @include transition("border-color 0.7s ease-in ");
    &--text {
      @include transition("color 0.7s ease-in ");
    }
    &:hover {
      border-color: $primary;
    }
    &:hover .icon--text {
      color: $primary;
    }
  }
}
