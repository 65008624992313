// ======================================================
// Component :: Ads Format
// ======================================================
.adsFormat {
  .formats {
    &--title {
      color: $primary;
      text-align: center;
      font-size: 2.6rem;
      line-height: 3rem;
      margin-bottom: 1.5rem;
      b {
        color: getTheme(text);
      }
      @include breakpoint-down("md") {
        font-size: 1.8rem;
        line-height: 2rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
    &--tab {
      @include display("flex");
      justify-content: center;
      align-items: center;
      flex-flow: column;
      &--content {
        @include display("flex");
        justify-content: center;
        align-items: center;
        flex-flow: column;
        &--img {
          @include display("flex");
          justify-content: center;
          .img {
            @include size(500px, 300px);
          }
        }
        .tab1_1,
        .tab2_1,
        .tab3_1,
        .tab4_1,
        .tab5_1 {
          background-image: url("../../../assets/images/about/Phone\ Dark-01.png");
        }
        .tab1_2,
        .tab2_2,
        .tab2_3,
        .tab2_4,
        .tab2_5 {
          background-image: url("../../../assets/images/about/PcDark-01.webp");
        }

        &--p {
          font-size: 17px;
          color: getTheme(text);
          text-align: center;
          width: 80%;
        }

        @include breakpoint-down("md") {
          .img {
            @include size(400px, 200px);
          }
          &--p {
            font-size: 15px;
            width: 90%;
          }
        }
      }

      .tabs {
        margin-bottom: 50px;
        font-size: 15px;
        padding: 0px;
        list-style: none;
        border: 1px solid #acacac;
        display: inline-block;
        border-radius: 50px;
        position: relative;

        &--btn {
          position: relative;
          color: #acacac;
          padding: 10px 40px;
          display: inline-block;
          z-index: 1;
          transition-duration: 0.7s;
          cursor: pointer;
          svg {
            @include size(30px, 30px);
          }

          &--active {
            color: #fff;
          }
          @include breakpoint-down("md") {
            &-content {
              overflow: hidden;
            }
            padding: 6px 30px;
            svg {
              @include size(25px, 25px);
            }
          }
        }
      }
    }
    .tabs--selector {
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
      border-radius: 50px;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 1.265, 1.55);
      background-color: $primary;
    }
    .listDrop {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        margin-bottom: 20px;
      }
      &--p {
        font-size: 2rem;
        font-weight: 500;
        color: getTheme(text);
        cursor: pointer;
      }
      @include breakpoint-down("md") {
        margin-bottom: 20px;
        li {
          margin-bottom: 10px;
        }
        &--p {
          font-size: 1.5rem;
        }
      }
    }
    .dropdown--action {
      padding: 0;
      h4 {
        padding: 0;
        font-size: 2rem;
      }
      button {
        font-size: 1.7rem;
      }
      @include breakpoint-down("md") {
        h4 {
          font-size: 1.5rem;
        }
        button {
          font-size: 1.2rem;
        }
      }
    }
    .dropdown--active {
      color: $primary;
    }
    .dropdown--details {
      margin: 5px 0;
      p {
        padding: 10px 0;
        font-size: 1.7rem;
        @include transition("color 0.4s ease-in");
        @include on-event() {
          color: $primary;
        }
        @include breakpoint-down("md") {
          padding: 5px 0;

          font-size: 1rem;
        }
      }
    }
    @include breakpoint-down("sm") {
      &--tab {
        margin-bottom: 20px;
      }
    }
  }
}

:root[data-theme="dark"] .adsFormat {
  .formats {
    .tabs {
      border-color: #666666;
      &--btn {
        color: #666666;
        &--active {
          color: #fff;
        }
      }
    }
    &--tab {
      &--content {
        .tab1_1,
        .tab2_1,
        .tab3_1,
        .tab4_1,
        .tab5_1 {
          background-image: url("../../../assets/images/about/Phone\ Light-01.png");
        }
        .tab1_2,
        .tab2_2,
        .tab2_3,
        .tab2_4,
        .tab2_5 {
          background-image: url("../../../assets/images/about/Pc\ Light-01.webp");
        }
      }
    }
  }
}
