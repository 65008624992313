// ======================================================
// Section :: Banner
// ======================================================

.signUp {
  .advertiser {
    &--h2 {
      color: #fff;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 600;
      margin-bottom: 30px;
      text-align: center;
      @include breakpoint-down(lg) {
        text-align: center;
      }
      @include breakpoint-down(sm) {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
    &--card {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      & > .fill {
        border: 1px solid transparent;
        border-radius: 10px;
        padding: 15px;
        background-color: rgba(214, 214, 214, 0.6);

        @include size(90px, 90px);
        svg {
          @include size(37px, 37px);
        }
        svg > path {
          fill: $white;
          stroke: $white;
        }

        @include transition("background-color 0.6s ease-in-out");
        &:hover {
          background-color: $primary;
        }
      }
      &--p {
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: white;
        text-align: center;
        width: 80%;
      }

      @include breakpoint-down("sm") {
        justify-content: flex-start;
        & > .fill {
          padding: 5px;

          @include size(60px, 60px);
          svg {
            @include size(23px, 23px);
          }
        }

        &--p {
          font-size: 11px;
          line-height: 14px;
        }
      }
    }
    .slick-dots {
      li {
        button {
          &::before {
            color: #fff !important;
            font-size: 15px;
            opacity: 0.8;
          }
          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }
      }
      .slick-active > button {
        &::before {
          opacity: 1;
          color: $primary !important;
        }
      }
    }
  }
}
