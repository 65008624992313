// ======================================================
// Section :: analytics
// ======================================================

.mobileApp {
  .analytics {
    &--h2 {
      color: getTheme(text);
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 1rem;
      @include breakpoint-down("md") {
        font-size: 2rem;
      }

      @include breakpoint-down("sm") {
        font-size: 1.3rem;
      }
    }
    &--h5 {
      color: getTheme(text);
      font-size: 1.1rem;
      line-height: 1.5rem;
      font-weight: 500;
      margin-bottom: 1rem;

      @include breakpoint-down("md") {
        font-size: 0.9rem;
        line-height: 1.2rem;
      }
      @include breakpoint-down("xs") {
        line-height: 1rem;
        font-size: 0.7rem;
      }
    }
    &--card {
      border-radius: 20px;
      background-color: #fff;
      @extend .shadow-1;
      padding: 20px 30px;
      @include transition("transform 0.2s ease-in");

      &:hover {
        transform: translateX(-5px);
      }

      &--h2 {
        color: $primary;
        font-size: 2.3rem;
        font-weight: 500;
        margin: 8px 0;

        @include breakpoint-down("md") {
          margin: 5px 0;
          font-size: 2.1rem;
        }
        @include breakpoint-down("xs") {
          font-size: 1.9rem;
        }
      }
      &--h4 {
        color: getTheme(text-light2);
        font-size: 1.2rem;
        line-height: 1.3rem;
        font-weight: 500;

        @include breakpoint-down("md") {
          font-size: 1.1rem;
          line-height: 1.2rem;
        }
        @include breakpoint-down("xs") {
          line-height: 1rem;
          font-size: 0.9rem;
        }
      }
      &--rate {
        @include display(flex);
        justify-content: center;
        align-items: center;
        flex-flow: row;
        border-radius: 20px;
        background-color: rgba(172, 30, 222, 0.1);
        padding: 5px 15px;
        margin-bottom: 7px;
        svg {
          @include size(20px, 20px);
          fill: $primary;
          margin-right: 5px;
        }
        p {
          font-weight: bold;
          color: getTheme(text);
        }
      }
      &--p {
        color: getTheme(text-light2);
        font-size: 13px;
        line-height: 15px;
        font-weight: 600;
        text-align: center;
      }

      @include breakpoint-up("sm") {
        &:nth-child(2) {
          align-self: flex-end;
        }
      }
    }

    @include breakpoint-down("sm") {
      & > div:first-child {
        &:nth-child(1) {
          order: 1;
        }
      }
    }
  }
}

:root[data-theme="dark"] .mobileApp {
  .analytics {
    &--card {
      background-color: #363636;
      box-shadow: none;
    }
  }
}
