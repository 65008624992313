// ======================================================
// Layout :: Last news
// ======================================================

// Include components
// ------

@import "info";
@import "blog";
@import "lifestyle";
@import "allStories";

.lastnews {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/career/daniel-roe-lpjb_UMOyx8-unsplash.webp");
  }
  &--h2 {
    color: getTheme(text);
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    @include breakpoint-down("sm") {
      font-size: 1.5rem;
    }
    @include breakpoint-down("xs") {
      font-size: 1rem;
    }
  }
  &--p {
    color: getTheme(text-light);
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 1rem;
    font-weight: 400;
    @include breakpoint-down("sm") {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
