// ======================================================
// Component :: Location
// ======================================================

.contact {
  .location {
    &--h1 {
      text-align: center;
      color: getTheme(text);
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.3rem;
      padding-top: 1.5rem;
    }

    @include breakpoint-down(md) {
      &--h1 {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
    @include breakpoint-down(sm) {
      &--h1 {
        font-size: 1.8rem;
        line-height: 1.9rem;
      }
    }
    @include breakpoint-down(xs) {
      &--h1 {
        font-size: 1.5rem;
        line-height: 1.6rem;
      }
    }

    &--map {
      height: 400px;
      background-size: cover;
      @include display("flex");
      justify-content: flex-end;
      align-items: center;
      flex-flow: column;
    }
    .mapImg_1 {
      background-image: url("../../../assets/images/contact/map.webp");
    }
    .mapImg_2 {
      background-image: url("../../../assets/images/contact/map2.webp");
    }
    .mapImg_3 {
      background-image: url("../../../assets/images/contact/map3.webp");
    }

    &--content {
      background-color: $primary;
      @include display("flex");
      justify-content: space-around;
      align-items: center;
      flex-flow: row;
      width: 100%;
      border-radius: 20px;
      padding: 20px;
      @include box-shadow(
        "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
      );
      &--card {
        @include display("flex");
        justify-content: center;
        align-items: baseline;
        flex-flow: column;
      }
      &--h5 {
        color: #dddddd;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px;
        font-weight: 300;
        &::after {
          display: block;
          content: "";
          width: 20px;
          margin: 0;
          padding-top: 3px;
          border-bottom: 2px solid #fff;
        }
      }
      &--p {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
      }

      @include breakpoint-down(sm) {
        flex-flow: column;
      }
    }
    .slick-list {
      margin: 0;
    }
    .slick-slider {
      padding: 2rem 0;
    }

    .slick-arrow {
      cursor: pointer;
      position: absolute;
      @include display("flex");
      justify-content: center;
      align-items: center;
      top: 50%;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 5;
      padding: 8px;
      &::before {
        content: none;
      }
      svg {
        padding: 5px;
        polyline {
          stroke: $primary;
        }
      }
      @include transition("background-color 0.2s ease-in");
      &:hover {
        background-color: #fff;
      }
    }
    .slick-prev {
      left: 30px;
      polyline {
        stroke-width: 6;
      }
      &:hover polyline,
      &:focus polyline {
        stroke-width: 8;
      }
      &:active polyline {
        stroke-width: 10;
        transition: all 100ms ease-in-out;
      }
    }

    .slick-next {
      right: 30px;
      polyline {
        stroke-width: 6;
      }
      &:hover polyline,
      &:focus polyline {
        stroke-width: 7;
      }
      &:active polyline {
        stroke-width: 10;
        transition: all 100ms ease-in-out;
      }
    }
  }
}

:root[data-theme="dark"] .contact {
  .location {
    .mapImg_1 {
      background-image: url("../../../assets/images/contact/map.webp");
    }
    .mapImg_2 {
      background-image: url("../../../assets/images/contact/map-dark.webp");
    }
    .mapImg_3 {
      background-image: url("../../../assets/images/contact/map.webp");
    }
  }
}
