// ======================================================
// Layout :: webinars
// ======================================================

// Include components
// ------

@import "info";

.webinars {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.6),
        rgba(24, 25, 35, 0.6)
      ),
      url("../../../assets/images/forecast/radek-grzybowski-eBRTYyjwpRY-unsplash.webp");
  }
}
