// ======================================================
// Section :: makes
// ======================================================

.retargeting {
  .makes {
    &--card {
      @include display("flex");
      justify-content: space-evenly;
      align-items: flex-start;
      flex-flow: column;
      padding: 20px;
      border-radius: 10px;
      height: 200px;
      border: 1px solid getTheme(border);

      &--h4 {
        color: $primary;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 1rem;
        font-weight: 500;
        @include breakpoint-down("sm") {
          font-size: 14px;
        }
      }
      &--p {
        color: getTheme(text-light);
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 1rem;
        font-weight: 400;
        @include breakpoint-down("sm") {
          font-size: 14px;
        }
      }
      @include breakpoint-down("sm") {
        height: 160px;
      }
    }
  }
}
