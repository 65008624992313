// ======================================================
// Layout :: trends Overview
// ======================================================

// Include components
// ------

@import "info";
@import "map";
@import "filter";
@import "image";
@import "video";
@import "keyword";
@import "topics";

.trends {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.3),
        rgba(24, 25, 35, 0.3)
      ),
      url("../../../assets/images/trends/sean-pollock-PhYq704ffdA-unsplash.webp");
  }
  .analyser {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.3),
        rgba(24, 25, 35, 0.3)
      ),
      url("../../../assets/images/trends/patrick-tomasso-LczBrW5Z6FU-unsplash.webp");
    height: 400px;
    @include breakpoint-down(xs) {
      height: 300px;
    }
    &--form {
      width: 60%;
      margin: auto;
      input {
        width: 100%;
        border-radius: 40px;
        margin: 20px auto;
        padding: 18px;
        line-height: 19px;
        font-size: 16px;
        background-color: #fff;
        &::placeholder {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          color: $primary;
          vertical-align: middle;
        }
        @include breakpoint-down(xs) {
          border-radius: 30px;
          margin: 10px auto;
          padding: 10px;
          line-height: 14px;
          font-size: 13px;
          &::placeholder {
            font-size: 13px;
            line-height: 14px;
          }
        }
      }

      @include breakpoint-down(md) {
        width: 80%;
      }
      @include breakpoint-down(xs) {
        width: 90%;
      }
    }
  }
}
