// ======================================================
// Components :: Select Lang
// ======================================================

.selectLang {
  margin-left: 30px;
  .active {
    color: $white;
    background-color: $primary;
  }
  &--item {
    background-color: getTheme("card");
    color: getTheme(text);
    margin: 2px 0;
    font-size: 11px;
    line-height: 11px;
    width: 100%;
    padding: 2px;
    @include display(flex);
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    &:hover {
      color: $white;
    }

    .img {
      height: 18px;
      width: 18px;
      margin-right: 4px;
      @include breakpoint-down(sm) {
        // margin-right: 10px;
      }
    }
    &_ar-AR {
      background-image: url("../../assets/flags/ar-AR.svg");
    }
    &_sq-AL {
      background-image: url("../../assets/flags/sq-AL.svg");
    }
    &_de-DE {
      background-image: url("../../assets/flags/de-DE.svg");
    }
    &_nl-NL {
      background-image: url("../../assets/flags/nl-NL.svg");
    }
    &_en-GB {
      background-image: url("../../assets/flags/en-GB.svg");
    }
    &_es-ES {
      background-image: url("../../assets/flags/es-ES.svg");
    }
    &_it-IT {
      background-image: url("../../assets/flags/it-IT.svg");
    }
    &_el-GR {
      background-image: url("../../assets/flags/el-GR.svg");
    }
    &_jp-JP {
      background-image: url("../../assets/flags/jp-JP.svg");
    }
    &_hr-HR {
      background-image: url("../../assets/flags/hr-HR.svg");
    }
    &_fr-FR {
      background-image: url("../../assets/flags/fr-FR.svg");
    }
    &_tr-TR {
      background-image: url("../../assets/flags/tr-TR.svg");
    }
  }
  &--btnIcon {
    cursor: pointer;
    svg {
      @include size(21px, 21px);
      @include breakpoint-down(xs) {
        @include size(18px, 18px);
      }
    }
    svg > path {
      fill: $white;
      @include transition("fill 0.4s ease-in");
    }
    &:hover {
      svg > path {
        fill: $primary;
      }
    }
  }
  .popover {
    &--wrap--overlay {
      bottom: 100%;
      top: auto;
    }
    @include breakpoint-down(sm) {
      &--wrap--content {
        right: -25px;
        justify-content: flex-end;
      }
    }
    &--content {
      width: 110px;
      border-radius: 10px;
      margin: 5px;
      bottom: 100%;
      top: auto;
      padding: 5px;
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;

      &::before,
      &:after {
        transform: rotate(180deg);
        bottom: auto;
        top: 100%;
      }
      &:after {
        border-width: 7px;
        margin-left: -7px;
      }
      &:before {
        border-width: 8px;
        margin-left: -8px;
      }
      @include breakpoint-down(sm) {
        &::before,
        &::after {
          left: initial;
          right: 20%;
        }
      }
    }
  }
}
