// ======================================================
// Components :: Mobile Menu
// ======================================================

.mobileMenu {
  .activeMobile {
    color: $primary !important;
  }

  &--link {
    text-transform: capitalize;
    color: #757575;
    font-size: 1.2rem !important;
    line-height: 1.3rem !important;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    text-decoration: none !important;
    :hover,
    :focus {
      color: #757575;
    }
  }

  .subMenu_text {
    background-color: #a5a5a5;
    color: white;
    padding: 5px 9px;
    margin: 1px 0;
    transition: all 0.9s ease-in-out;
    :hover,
    :focus {
      color: black;
    }
  }
  .activeMobile_2 {
    background-color: $primary;
  }
}
