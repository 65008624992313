// ======================================================
// Layout :: la office
// ======================================================

// Include components
// ------

@import "slider";

.laOffice {
  .banner--h1 {
    color: #111;
  }
  .info {
    &--h4 {
      color: $primary;
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 1rem;
      font-weight: 700;
      padding: 10px;
      @include breakpoint-down("sm") {
        font-size: 20px;
        line-height: 22px;
      }
      @include breakpoint-down("xs") {
        font-size: 17px;
        line-height: 22px;
      }
    }
    &--p {
      color: getTheme(text-light);
      font-size: $fontSize-large;
      line-height: 28px;
      margin-bottom: 1rem;
      font-weight: 400;
      padding: 10px;
      @include breakpoint-down("sm") {
        padding: 3px;
      }
      @include breakpoint-down("xs") {
        font-size: $fontSize-base;
        line-height: 22px;
      }
    }
  }
  .mapImg {
    background-image: url("../../../assets/images/office/MAP.webp");
    width: 100%;
    height: 500px;
    background-size: cover;
    @include breakpoint-down("md") {
      height: 400px;
    }
    @include breakpoint-down("sm") {
      height: 300px;
    }
    @include breakpoint-down("xs") {
      height: 200px;
    }
  }
  .sliderImg {
    height: 400px;
    width: 100%;
  }
  .slick-list {
    margin: 0;
  }
  &--simg1 {
    background-image: url("../../../assets/images/office/TEAM.webp");
  }
  &--simg2 {
    background-image: url("../../../assets/images/about/you-x-ventures-Oalh2MojUuk-unsplash.png");
  }
  &--simg3 {
    background-image: url("../../../assets/images/home/austin-distel-wawEfYdpkag-unsplash.png");
  }
  &--simg4 {
    background-image: url("../../../assets/images/home/campaign-creators-gMsnXqILjp4-unsplash.png");
  }
}
