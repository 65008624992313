// ======================================================
// Layout :: Wordpress Widget
// ======================================================

// Include components
// ------

@import "form";
@import "events";

.events {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/events/jakob-dalbjorn-cuKJre3nyYc-unsplash.webp");
  }
}
