// ======================================================
// Section :: info
// ======================================================

.service {
  .cardServices {
    &--h2 {
      color: getTheme(text);
      text-align: center;
      font-size: 2.5rem;
      margin-bottom: 2rem;
      font-weight: 400;
      @include breakpoint-down("md") {
        margin-bottom: 1.4rem;
        font-size: 2rem;
      }
      @include breakpoint-down("sm") {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    &--h4 {
      color: getTheme(text);
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    .img_1 {
      background-image: url("../../../assets/images/service/Rectangle\ 362.jpg");
    }
    .img_2 {
      background-image: url("../../../assets/images/service/Rectangle\ 363.jpg");
    }
    .img_3 {
      background-image: url("../../../assets/images/service/Rectangle\ 364.jpg");
    }
    .img_4 {
      background-image: url("../../../assets/images/service/Rectangle\ 365.jpg");
    }
    .img_5 {
      background-image: url("../../../assets/images/service/Rectangle\ 3622.jpg");
    }
    .img_6 {
      background-image: url("../../../assets/images/service/Rectangle\ 3655.jpg");
    }
    .img_7 {
      background-image: url("../../../assets/images/service/Rectangle\ 3626.jpg");
    }
    .img_8 {
      background-image: url("../../../assets/images/service/Rectangle\ 36234.jpg");
    }
    .img_9 {
      background-image: url("../../../assets/images/service/Rectangle\ 366334.jpg");
    }

    &--card {
      border: 1px solid getTheme(border);

      &--box {
        @include display(flex);
        justify-content: space-evenly;
        align-items: flex-start;
        flex-flow: column;
        padding: 5px;
        padding-left: 10px;
      }

      &--img {
        height: 170px;
        width: 100%;
        background-size: cover;
      }
      &--title {
        font-size: 19px;
        font-weight: 400;
        color: getTheme(text-light);
        margin-bottom: 10px;
      }
      &--desc {
        font-size: 13px;
        line-height: 22px;
        font-weight: 400;
        color: getTheme(text-light2);
        margin-bottom: 10px;
      }
      .link {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
