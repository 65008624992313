// ======================================================
// Layout :: Top Services
// ======================================================

.topServices {
  &--h3 {
    color: getTheme(text);
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3rem;
  }

  &--card {
    border-radius: 15px;
  }
  &--img {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    width: 100%;
    height: 170px;

    &-1 {
      background-image: url("../../../assets/images/home/sharon-mccutcheon-8lnbXtxFGZw-unsplash.png");
    }
    &-2 {
      background-image: url("../../../assets/images/home/joe-yates-Cc4sToR2Oc0-unsplash.png");
    }
    &-3 {
      background-image: url("../../../assets/images/home/sydney-rae-5aMDaV-8nA8-unsplash.png");
    }
  }
  @include breakpoint-between("xs", "sm") {
    &--card {
      margin: 1rem 3rem;
    }

    &--img {
      height: 250px;
    }
  }
  &--box {
    border-bottom: 1px solid getTheme(border);
    border-left: 1px solid getTheme(border);
    border-right: 1px solid getTheme(border);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 1.5rem;

    &--h4 {
      font-size: 25px;
      font-weight: 600;
      color: getTheme(text);
      margin-bottom: 1.5rem;
    }
    &--p {
      font-size: $fontSize-medium;
      line-height: $fontSize-larger;
      color: $gray-21;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    &--link {
      text-transform: uppercase;
      color: $primary;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @include breakpoint-down(xs) {
    &--box--h4 {
      font-size: 23px;
    }
    &--h3 {
      font-size: 1.8rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}

:root[data-theme="dark"] .topServices {
  &--box {
    @include transition("border-color 0.5s ease-in ");
    &:hover {
      border-color: $primary;
    }
    &--link {
      color: $gray-21;
    }
  }
}
