// ======================================================
// Components :: Modal
// ======================================================

.modal {
  position: fixed;
  z-index: 1111;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);

  @include display(flex);
  justify-content: center;
  align-items: center;
  overflow: auto;
}
