// // ======================================================
// // Layout :: newsLetter
// // ======================================================

.newsLetter {
  .wrapInput--error {
    margin: 0;
  }
  background-color: getTheme("bg");
  &--img {
    width: auto;
    height: 200px;
  }

  &--box {
    height: 200px;
    background-color: #ececec;
    padding: 2rem;
    @include display("flex");
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;

    &--h4 {
      font-size: 30px;
      font-weight: 600;
      color: getTheme(text);
      text-transform: capitalize;
      margin-bottom: 0.5rem;
    }
    &--p {
      line-height: $fontSize-large;
      text-transform: capitalize;
      color: $gray-21;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }
  &--form {
    @include display("flex");
    justify-content: center;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    margin: 0.7rem 0;

    &--input {
      width: 100%;
      margin-right: 10px;
      line-height: 16px;
      padding: 13px;
      font-size: 14px;
      padding-left: 15px;
      background-color: #cecece;
      &::placeholder {
        text-transform: uppercase;
        line-height: 16px;
        font-weight: 600;
        color: #797979;
        vertical-align: middle;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
          "Lucida Sans", Arial, sans-serif;
        letter-spacing: 1px;
      }
    }
    &--btn {
      padding: 9px 35px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      width: 40%;
      border-radius: 0px;
    }
  }
  @include breakpoint-down(md) {
    &--img {
      height: 180px;
    }
    &--box {
      height: 180px;
      padding: 0.8rem;
    }
  }
  @include breakpoint-down(sm) {
    &--box {
      &--h4 {
        font-size: 20px;
      }
      &--p {
        font-size: 13px;
      }
    }
    &--img {
      width: 100%;
      height: 220px;
    }
  }
  @include breakpoint-down(xs) {
    margin-bottom: 2rem;
    &--form {
      margin-bottom: 0.6rem;
      &--btn {
        padding: 3px 20px;
        font-size: 13px;
      }
      &--input {
        padding: 7px;
        font-size: 12px;
        line-height: 12px;
        &::placeholder {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
  @include breakpoint-down(xss) {
    &--form {
      margin-bottom: 0.6rem;
      justify-content: space-between;
      &--btn {
        padding: 3px 14px;
        font-size: 11px;
      }
      &--input {
        padding: 6px;
        font-size: 10px;
        line-height: 10px;
        &::placeholder {
          font-weight: 400;
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}

:root[data-theme="dark"] .newsLetter {
  &--box {
    background-color: transparent;
    border-right: 1px solid getTheme(border);
    border-top: 1px solid getTheme(border);
    border-bottom: 1px solid getTheme(border);
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;

    @include transition("border-color 0.5s ease-in ");
    &:hover {
      border-color: $primary;
    }
    @include breakpoint-down(sm) {
      border-right: 1px solid getTheme(border);
      border-left: 1px solid getTheme(border);
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0;
      border-top: none;
      &--h4 {
        margin-top: 1rem;
      }
    }
  }
  &--form {
    background-color: #505050;
    border-radius: 30px;
    border: 1px solid transparent;
    &--input {
      border-radius: 30px;
      margin-right: 0;
      background-color: transparent;
      color: white;
      &::placeholder {
        color: #cecece;
      }
    }
    &--btn {
      border-radius: 30px;
    }
  }
}
