// ======================================================
// Layout :: Tools Overview
// ======================================================

// Include components
// ------

@import "support";

.premium {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/resources/BG.webp");
  }
  .slide {
    .support--h4 {
      color: $primary;
      text-align: center;
      font-size: 17px;
      font-weight: 500;
    }
  }
  .slick-track {
    // div:nth-child(2),
    // div:nth-child(4),
    // div:nth-child(6),
    // div:nth-child(8),
    div:nth-child(even) {
      .partners--content {
        &--boxC {
          order: 1;
          &--border {
            border: 1px solid getTheme(border);
            border-radius: 0 15px 15px 0;
            border-left: 0;
          }
        }
        .img {
          border-radius: 15px 0 15px 15px;
        }
      }
    }
  }

  .partners {
    &--h2 {
      color: #fff;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 10px;
      text-align: left;
    }
    &--content {
      padding: 10px;
      background-color: $white;
      border-radius: 20px;
      border: none;
      margin: 20px;
      &--h4 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 6px;
      }
      &--link {
        font-size: 12px;
        line-height: 15px;
      }
      &--p {
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
      }
      .btn {
        width: 84%;
        border-radius: 5px !important;
        margin: 20px auto;
        @include breakpoint-down(xs) {
          margin-bottom: 40px;
        }
      }
      &--img {
        background-size: cover;
        height: 380px;
        border-radius: 0 15px 15px 15px;
        @include breakpoint-down(md) {
          height: 420px;
        }
        @include breakpoint-down(sm) {
          height: 470px;
        }
        @include breakpoint-down(xs) {
          height: 500px;
          border-radius: 15px !important;
        }
        @include breakpoint-down(xss) {
          height: 370px;
        }
      }
      div:first-child {
        padding: 0px;
        justify-content: space-between;
      }
      & > div:first-child div {
        border: 1px solid getTheme(border);
        padding: 10px;
        border-radius: 15px 0 0 15px;
        border-right: 0;
        @include breakpoint-down(xs) {
          border: none;
        }
      }
    }
    &--tab--btn {
      margin-bottom: 25px;

      i {
        font-size: 25px;
        svg {
          fill: #707070;
        }
      }
      button {
        svg {
          @include size(100px, 60px);

          text,
          rect,
          path {
            fill: #707070;
          }
        }
        @include breakpoint-down(sm) {
          svg {
            @include size(70px, 30px);
          }
        }
        @include breakpoint-down(xs) {
          svg {
            @include size(50px, 25px);
          }
        }
      }
      &--active {
        i > svg {
          fill: $white;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $white;
          }
        }
      }
      &:hover {
        i > svg {
          fill: $white;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $white;
          }
        }
      }

      @include breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}
