// ======================================================
// Layout :: Tools Overview
// ======================================================

// Include components
// ------

.login {
  &--form {
    width: 100%;
    @include display(flex);
    justify-content: center;
    align-items: center;
    flex-flow: column;
    .btnWith {
      & > button {
        span {
          border-radius: 50%;
          background-color: whitesmoke;
          padding: 8px;
          @include display(flex);
          justify-content: center;
          align-items: center;
          margin: 5px;
          svg {
            @include size(15px, 15px);
          }
        }
      }
      button:nth-child(2) {
        background-color: transparent;
        color: getTheme(text);
        border: 1px solid getTheme(border);
      }
    }

    &--h4 {
      color: getTheme(text);
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 700;
      @include breakpoint-down("sm") {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    .btn-back {
      background-color: transparent;
      color: getTheme(text);
      padding: 0 10px;
    }
  }
  .content {
    &--box {
      &--img {
        width: 100%;
        height: 250px;
      }
      .img_1 {
        background-image: url("../../../assets/images/login/515470-PIHD72-370.png");
      }
      .img_2 {
        background-image: url("../../../assets/images/login/3574882.png");
      }
      div:nth-child(1) > div:nth-child(2) {
        h2 {
          color: $primary;
        }
        ul {
          ::marker {
            color: $primary;
          }
        }
      }

      .link {
        margin-left: 20px;
        color: getTheme(text);
      }
      &--list {
        list-style-type: disc;
        margin-left: 20px;
        ::marker {
          color: getTheme(text);
        }
      }
      &--text {
        @include display(flex);
        justify-content: center;
        align-items: flex-start;
        flex-flow: column;
        margin-left: 20px;
      }
      &--h2 {
        color: getTheme(text);
        font-size: 24px;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
        font-weight: 500;
        @include breakpoint-down("md") {
          margin-bottom: 1.4rem;
          font-size: 21px;
        }
        @include breakpoint-down("xs") {
          font-size: 18px;
          margin-bottom: 1rem;
        }
      }
      &--p {
        color: getTheme(text-light2);
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 5px;

        @include breakpoint-down("sm") {
          font-size: 14px;
        }
      }
    }
  }
}
