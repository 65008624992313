// ======================================================
// Components :: Popover
// ======================================================

.popover {
  .active {
    .navlink::after {
      transform: scale(1);
    }
  }

  &--link {
    @include transition("color 0.1s ease-in !important");
  }
  &--wrap--content {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--wrap--overlay {
    position: absolute;
    top: 0;
  }

  .navlink:hover::after {
    transform: scale(1);
  }

  &--icon {
    margin-left: 3px;
    @include transition("color 0.2s ease-in-out !important");
    &--color {
      color: getTheme(text);
    }
    &--transparent {
      color: $white;
    }
    &--scroll {
      color: getTheme(text);
    }
  }

  &--content {
    position: relative;
    background-color: getTheme("card");
    border: 0 solid transparent;
    width: 550px;
    border-radius: 10px;
    margin: 30px;
    top: 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 25px 20px;

    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      position: absolute;
      border: 1px solid transparent;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $white;
      border-width: 11px;
      margin-left: -11px;
    }
    &:before {
      border-color: rgba(254, 254, 254, 0);
      border-bottom-color: #dfdfdf;
      border-width: 12px;
      margin-left: -12px;
    }
  }
}

:root[data-theme="dark"] .popover {
  &--content {
    background-color: #333333;
    box-shadow: none;
    border-color: #333333;
    &:before,
    &:after {
      border-bottom-color: #333333;
    }
  }
}
