// ======================================================
// Component :: Main Form
// ======================================================
.signUp {
  .mainForm {
    margin: 0 5%;
    &--title {
      @include display(flex);
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      &--h3 {
        font-size: 2rem;
        color: $primary;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &--p {
        color: getTheme(text-light);
        font-size: 16px;
        line-height: 17px;
        a {
          margin-left: 5px;
          color: $primary;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      @include breakpoint-down("sm") {
        &--h3 {
          text-align: center;
          font-size: 1.5rem;
        }
        margin-bottom: 30px;
        justify-content: center;
        align-items: center;
      }
    }
    &--tab {
      @include display(flex);
      justify-content: space-between;
      align-items: baseline;
      flex-flow: row;

      .tabs {
        margin-bottom: 50px;
        font-size: 15px;
        padding: 0px;
        list-style: none;
        border: 1px solid transparent;
        display: inline-block;
        border-radius: 50px;
        position: relative;

        &--btn {
          position: relative;
          color: #acacac;
          padding: 8px 20px;
          display: inline-block;
          z-index: 1;
          transition-duration: 0.7s;
          cursor: pointer;

          &--active {
            color: #fff;
          }
          @include breakpoint-down("md") {
            &-content {
              overflow: hidden;
            }
            padding: 6px 24px;
          }
          // &:hover {
          //   color: #c2c2c2 !important;
          // }
        }
        @include breakpoint-down("xs") {
          margin-bottom: 10px;
        }
      }
      .tabs--selector {
        height: 100%;
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        border-radius: 50px;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 1.265, 1.55);
        background-color: $primary;
      }
      @include breakpoint-down("sm") {
        justify-content: center;
        flex-flow: column;
        align-items: center;
      }
    }
  }
  .advertiserForm,
  .publisherForm {
    .link {
      font-weight: 600;
    }
    .btn {
      border-radius: 35px;
      padding: 6px 30px;
      font-size: 17px;
      border: 1px solid $primary;
      text-transform: none !important;
      @include breakpoint-down(xs) {
        padding: 4px 26px;
        font-size: 14px;
      }
      @include breakpoint-down(xss) {
        padding: 2px 18px;
        font-size: $fontSize-small;
      }
    }
  }
}

:root[data-theme="dark"] .signUp {
  .mainForm {
    &--tab {
      .tabs {
        box-shadow: none;
        // border-color: transparent;
        &--btn {
          color: #666666;
          &--active {
            color: #fff;
          }
        }
      }
    }
  }
}
