// ======================================================
// Section :: PromoteCard Banner
// ======================================================

.referralProgram {
  .promoteCard {
    &--box {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      border: 1px solid getTheme(border);
      border-radius: 8px;
      padding: 30px;
      @include transition("box-shadow 0.2s ease-in");
      &--p {
        color: getTheme(text);
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin: 10px 0;
      }
      &:hover {
        @extend .shadow-27;
      }
    }
    &--icon {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      border: 1px solid getTheme(border);
      padding: 10px;
      @extend .shadow-5;
      svg {
        @include size(30px, 30px);
        path {
          fill: $primary;
        }
      }
    }

    &--h3 {
      color: getTheme(text);
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    &--p {
      color: getTheme(text);
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 2rem;
    }
    @include breakpoint-down(xs) {
      &--box {
        margin: 0 10px;
        padding: 10px 5px;
      }
      &--icon {
        svg {
          @include size(20px, 20px);
        }
      }
    }
  }
}

:root[data-theme="dark"] .referralProgram {
  .promoteCard {
    &--box {
      @include transition("background-color 0.6s ease-in");
      &:hover {
        box-shadow: none;
        background-color: getTheme(border);
      }
      &:hover .promoteCard--icon {
        border-color: $primary;
      }
      &:hover .promoteCard--icon > svg > path {
        fill: #cacaca;
      }
    }
    &--icon {
      @include transition("border-color 0.6s ease-in");
      svg > path {
        @include transition("fill 0.6s ease-in");
      }
    }
  }
}
