// ======================================================
// Section :: ourSuccess
// ======================================================

.service {
  .ourSuccess {
    &--cards {
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: row wrap;
    }
    &--h4 {
      font-size: 34px;
      font-weight: 500;
      line-height: 43px;
      color: getTheme(text-light);
      margin-bottom: 2rem;
    }
    &--p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: getTheme(text-light2);
      margin-bottom: 2rem;
    }
    .btn {
      border-radius: 20px;
      margin-bottom: 2rem;
    }
    &--successCard {
      border-radius: 15px;
      margin: 20px;
      border: 1px solid getTheme(border);
      @include display(flex);
      justify-content: center;
      align-items: center;
      flex-flow: column;
      @include size(200px, 230px);

      .circle {
        border: 1px solid getTheme(border);
        margin: 10px;
        svg {
          @include size(30px, 30px);
          path {
            fill: $primary;
          }
        }
      }

      &--h4 {
        font-size: 19px;
        font-weight: 500;
        color: getTheme(text-light);
        margin-bottom: 10px;
        margin-top: 10px;
      }
      &--p {
        font-size: 13px;
        line-height: 22px;
        font-weight: 400;
        color: getTheme(text-light2);
        margin-bottom: 10px;
      }
      &:hover {
        .circle {
          animation: bounce 0.5s cubic-bezier(0.95, 0.05, 0.795, 0.035);
        }
      }
    }
  }
}
