// ======================================================
// Section :: keyword
// ======================================================

.trends {
  .keyword {
    &--h4 {
      color: getTheme(text-light);
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
      margin-bottom: 15px;
      @include breakpoint-down(sm) {
        font-size: 20px;
        line-height: 23px;
      }
    }
    img {
      height: 180px;

      @include breakpoint-down(sm) {
        padding: 10px;
        height: 160px;
        width: 100%;
        background-size: cover;
      }
    }

    &--p {
      color: $primary;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      text-transform: capitalize;
      margin-bottom: 10px;

      @include breakpoint-down("sm") {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
