// ======================================================
// Section :: search filter
// ======================================================

.admarket {
  &--form {
    flex-flow: row;
    margin: 0.7rem 0;
    padding-bottom: 20px;

    .iconFilter {
      margin-right: 20px;
      @include size(22px, 22px);

      @include breakpoint-down("sm") {
        margin-right: 20px;
        @include size(20px, 20px);
      }

      @include breakpoint-down("xss") {
        margin-right: 20px;
        @include size(18px, 18px);
      }
    }

    &--box {
      flex-flow: row;
      &--input {
        width: 400px;
        margin-right: 20px;
        line-height: 15px;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid getTheme(border);
        border-radius: 25px;
        @include transition("box-shadow 0.4s ease-in, transform 0.4s ease-in");
        .iconSearch {
          @include size(18px, 18px);
          margin-right: 10px;
          margin-left: 5px;
          @include transition("stroke 0.4s ease-in");
        }
        input {
          width: 100%;
          background-color: transparent;
          &::placeholder {
            line-height: 17px;
            color: #797979;
            vertical-align: middle;
            font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
              "Lucida Sans", Arial, sans-serif;
            letter-spacing: 1px;
          }
        }

        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
          @extend .shadow-6;
          transform: scale(1.001);
        }
        @include breakpoint-down("sm") {
          width: 100% !important;
          padding: 7px;
          .iconSearch {
            @include size(15px, 15px);
          }
          @include on-event() {
            box-shadow: none;
            transform: none;
          }
        }
        @include breakpoint-down("xss") {
          width: 100% !important;
          padding: 6px;
          .iconSearch {
            @include size(14px, 14px);
          }
        }
      }
      &--btn {
        padding: 8px 45px;
        text-transform: capitalize;
        border-radius: 25px;
        font-weight: 500;
        font-size: 14px;

        @include breakpoint-down("sm") {
          padding: 5px 29px;
          line-height: 21px;
          font-weight: 400;
          font-size: 13px;
        }
        @include breakpoint-down("xss") {
          padding: 4px 26px;
        }
      }
    }
  }
}

:root[data-theme="dark"] .admarket {
  &--form {
    &--box {
      &--input {
        background-color: getTheme(border);
        input {
          background-color: getTheme(border);
          color: $white;
          &::placeholder {
            color: $white;
          }
        }
        .iconSearch {
          stroke: $white;
        }
        @include on-event() {
          .iconSearch {
            stroke: $primary;
          }
        }
      }
    }
  }
}
