// ======================================================
// Components :: Icon
// ======================================================

.drawerToggle {
  font-size: $fontSize-larger;
  &:hover {
    cursor: pointer;
  }
  &--color {
    color: getTheme(text) !important;
  }
  &--transparent {
    color: $white !important;
  }
  &--scroll {
    color: getTheme(text) !important;
  }
}
