// ======================================================
// Layout :: Toolbar
// ======================================================

.toolbar {
  padding: 14px 5px;

  &--container {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-flow: row !important;
  }

  &--scroll {
    background-color: getTheme(bg) !important;
    padding: 3px;
    @include box-shadow(
      "0 1px 4px 0px rgba(0,0,0,.02),0 1px 4px 0 rgba(0,0,0,.04),0 1px 2px 0 rgba(0,0,0,.02)!important"
    );
    @include transition("all 0.5s ease-in !important");
  }
  &--color {
    background-color: getTheme(bg) !important;
    box-shadow: none !important;
    padding: 10px 5px;
  }
  &--transparent {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 20px 5px;
  }

  .navList {
    &--signUp {
      font-size: 14px;
      border-radius: 40px;
      padding: 4px 26px;
      line-height: 26px;
      text-transform: uppercase;
    }
    &--logIn {
      color: getTheme(text);
      padding: 4px 24px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      @include transition("color 0.1s ease-in ");
      @include on-event() {
        color: $primary !important;
      }
    }
  }

  @include breakpoint-down(lg) {
    .navList {
      &--signUp {
        font-size: 12px;
        padding: 3px 15px;
        line-height: 20px;
      }
      &--logIn {
        padding: 3px 10px;
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
  @include breakpoint-up(md) {
    &--mobileOnly {
      display: none !important;
    }
  }

  @include breakpoint-down(xs) {
    padding: 6px 3px;
    &--container {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }

    &--scroll {
      padding: 3px;
    }
  }
}

.navList {
  @include list-unstyled();
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  @include breakpoint-down(xl) {
    justify-content: center;
  }
  @include breakpoint-down(md) {
    display: none !important;
  }
}
