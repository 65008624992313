// Section :: topics
// ======================================================

.trends {
  .topics {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.8),
        rgba(24, 25, 35, 0.8)
      ),
      url("../../../assets/images/trends/simon-abrams-k_T9Zj3SE8k-unsplash.webp");

    .filter--p {
      color: #ddd;
    }
    .input--tooltip {
      width: 300px;
    }
    .map--h4 {
      color: #fff;
    }
    .map--p {
      color: #8f8f8f;
    }
    &__img {
      height: 300px;
    }

    &__ul {
      line-height: normal;
      li > p {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        color: white;
      }

      span {
        content: "\2212";
        width: 60px;
        height: 6px;
        margin-right: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        border-radius: 5px;
      }
      :nth-child(1) {
        span {
          background-color: $primary;
        }
      }
      :nth-child(2) {
        span {
          background-color: green;
        }
      }
      :nth-child(3) {
        span {
          background-color: green;
        }
      }
      :nth-child(4) {
        span {
          background-color: #4c4298;
        }
      }
    }
  }
}
