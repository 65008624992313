// ======================================================
// Layout :: 3rdParty
// ======================================================

// Include components
// ------

@import "info";

.rdParty {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/resources/markus-winkler-UXNXItayGmo-unsplash.webp");
  }
}
