// ======================================================
// Section :: info
// ======================================================

.retargeting {
  .worksCard {
    &--box {
      @include display("flex");
      justify-content: space-evenly;
      align-items: center;
      flex-flow: row;

      &--img_1 {
        background-image: url("../../../assets/images/retargeting/LAPTOP.png");
        height: 100px;
        width: 100%;
      }
      &--img_2 {
        background-image: url("../../../assets/images/retargeting/LAPTOP2.png");
        width: 100%;
        height: 100px;
      }
      &--img_3 {
        background-image: url("../../../assets/images/retargeting/LAPTOP3.png");
        width: 100%;
        height: 100px;
      }
      &--icon {
        width: 60px;
        height: 30px;
        fill: getTheme(border);
      }

      &--card {
        @include display("flex");
        justify-content: center;
        align-items: stretch;
        flex-flow: column;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid getTheme(border);
        width: 280px;
      }
      &--p {
        margin: 10px 0;
        color: getTheme(text);
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 1rem;
        font-weight: 400;
        @include breakpoint-down("sm") {
          font-size: 14px;
        }
      }

      @include breakpoint-down("sm") {
        flex-flow: column;
      }
    }
  }
}

:root[data-theme="dark"] .retargeting {
  .worksCard {
    &--box {
      &--card {
        background-color: getTheme(border);
      }
    }
  }
}
