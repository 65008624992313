// ======================================================
// Base :: Colors
// ======================================================

/*
 * Implementation of themes
 */
$colors: (
  base: #ac1ede,
  bg: #fff,
  text: #111,
  text-light: #292929,
  text-light2: #6b6b6b,
  border: #e7e7e7,
  logo: url("../../assets/images/logo/dark.svg"),
  card: #fff,
);

//dark theme
$colors-dark: (
  base: #ac1ede,
  bg: #111,
  text: #fff,
  border: #303030,
  text-light: #a1a1a1,
  text-light2: #cccccc,
  logo: url("../../assets/images/logo/light.svg"),
  card: #333333,
);

//converting scss variable into css variable
:root[data-theme="light"] {
  @each $name, $color in $colors {
    --theme-#{$name}: #{$color};
  }
  --theme: "light";
}

:root[data-theme="dark"] {
  @each $name, $color in $colors-dark {
    --theme-#{$name}: #{$color};
  }
  --theme: "dark";
}
