// ======================================================
// Components :: Navbar
// ======================================================

.navTab {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  .container {
    display: inline-flex;
    justify-content: center;
    @include breakpoint-down(sm) {
      justify-content: flex-start;
    }
  }
  &--navList {
    -webkit-overflow-scrolling: touch;
    @include list-unstyled();
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .navlink {
      color: getTheme(text);
      &::after {
        margin-top: 5px;
      }
    }
  }
}
