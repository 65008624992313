// ======================================================
// Shared :: PartnersTab
// ======================================================
.partners {
  &--h2 {
    color: getTheme(text);
    text-align: center;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 600;
    @include breakpoint-down("md") {
      margin-bottom: 1.4rem;
    }
    @include breakpoint-down("xs") {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  &--content {
    border: 1px solid getTheme(border);
    border-radius: 25px;
    @include transition("border-color 0.5s ease-in");
    &--h4 {
      color: getTheme(text);
      font-size: 2rem;
      margin-bottom: 2rem;
      font-weight: bold;
      @include breakpoint-down("md") {
        margin-bottom: 1.4rem;
      }
      @include breakpoint-down("xs") {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }
    &--p,
    &--link {
      color: getTheme(text);
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 1rem;
      @include breakpoint-down("md") {
        margin-bottom: 0.5rem;
        line-height: 18px;
      }
      @include breakpoint-down("xs") {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
      }
    }
    .btn {
      font-size: 15px;
      font-weight: 500;
      padding: 6px 28px;
    }
    &--img {
      height: 450px;
      background-position: right;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;

      &_1 {
        background-image: url("../../assets/images/home/Path-215.webp");
      }
      &_2 {
        background-image: url("../../assets/images/home/Path-214.webp");
      }
      &_3 {
        background-image: url("../../assets/images/home/Mask\ Group\ 3.jpg");
      }
      &_4 {
        background-image: url("../../assets/images/home/Mask\ Group\ 4.jpg");
      }
      &_5 {
        background-image: url("../../assets/images/home/Portrait-52.webp");
      }
      @include breakpoint-down(md) {
        height: 420px;
        background-size: cover;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0;
      }
      @include breakpoint-down(sm) {
        height: 470px;
        background-position: bottom;
        border-bottom-right-radius: 25px;
      }
      @include breakpoint-down(xs) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 25px;
        height: 500px;
      }
      @include breakpoint-down(xss) {
        height: 370px;
      }
      @include breakpoint-up(xl) {
        background-size: contain;
      }
    }
  }
  &--tab {
    nav {
      @include display("flex");
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      @include breakpoint-down(sm) {
        margin-bottom: 1rem;
        flex-flow: row;
        overflow: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
          width: 0;
        }
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        margin: 20px 0;
        padding: 10px 0;
      }
    }

    &--btn {
      margin-bottom: 30px;
      i {
        margin-left: 30px;
        font-size: 30px;
        svg {
          fill: getTheme(border);
          @include transition("fill 0.5s ease-in-out");
        }
      }
      button {
        svg {
          @include size(120px, 60px);

          text,
          rect,
          path {
            fill: getTheme(border);
            @include transition("fill 0.5s ease-in-out");
          }
        }
      }
      &--active {
        i > svg {
          fill: $black;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $black;
          }
        }
      }
      &:hover {
        i > svg {
          fill: $black;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $black;
          }
        }
      }
      @include breakpoint-down("md") {
        margin-bottom: 20px;
        i {
          margin-left: 10px;
          font-size: 30px;
        }
        button svg {
          @include size(90px, 50px);
        }
      }
      @include breakpoint-down(sm) {
        margin-right: 20px;
        margin-bottom: 0;
      }
      @include breakpoint-down("xs") {
        button svg {
          @include size(50px, 20px);
        }
      }
    }
  }
  @include breakpoint-up(xl) {
    background-size: contain;
    margin: 0 5%;
  }
}

:root[data-theme="dark"] .partners {
  &--content {
    background-color: getTheme(border);
    &:hover {
      border-color: $primary;
    }
  }
  &--tab {
    &--btn {
      &--active {
        i > svg {
          fill: $primary;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $primary;
          }
        }
      }
      &:hover {
        i > svg {
          fill: $primary;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}
