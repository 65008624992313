// ======================================================
// Section :: Banner
// ======================================================

.signUp {
  .partners {
    &--h2 {
      color: #fff;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 10px;
      text-align: left;
    }
    &--content {
      padding: 10px;
      background-color: $white;
      border-radius: 20px;

      &--h4 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 6px;
      }
      &--link {
        font-size: 12px;
        line-height: 15px;
      }
      &--p {
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
      }
      .btn {
        width: 84%;
        border-radius: 5px !important;
        margin: 20px auto;
        @include breakpoint-down(xs) {
          margin-bottom: 40px;
        }
      }
      &--img {
        background-size: cover;
        height: 380px;
        border-radius: 0 15px 15px 15px;
        @include breakpoint-down(md) {
          height: 420px;
        }
        @include breakpoint-down(sm) {
          height: 470px;
        }
        @include breakpoint-down(xs) {
          height: 500px;
          border-radius: 15px !important;
        }
        @include breakpoint-down(xss) {
          height: 370px;
        }
      }
      div:first-child {
        padding: 0px;
        justify-content: space-between;
      }
      & > div:first-child div {
        border: 1px solid getTheme(border);
        padding: 10px;
        border-radius: 15px 0 0 15px;
        border-right: 0;
        @include breakpoint-down(xs) {
          border: none;
        }
      }
    }
    &--tab--btn {
      margin-bottom: 25px;

      i {
        font-size: 25px;
        svg {
          fill: #707070;
        }
      }
      button {
        svg {
          @include size(100px, 60px);

          text,
          rect,
          path {
            fill: #707070;
          }
        }
        @include breakpoint-down(sm) {
          svg {
            @include size(70px, 30px);
          }
        }
        @include breakpoint-down(xs) {
          svg {
            @include size(50px, 25px);
          }
        }
      }
      &--active {
        i > svg {
          fill: $white;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $white;
          }
        }
      }
      &:hover {
        i > svg {
          fill: $white;
        }
        button > svg {
          text,
          rect,
          path {
            fill: $white;
          }
        }
      }

      @include breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}

:root[data-theme="dark"] .signUp {
  .partners {
    &--content {
      & > div:first-child div {
        border-color: #707070;
      }
    }
  }
}
