// ======================================================
// Layout :: serviceCards
// ======================================================

.serviceCards {
  &--box {
    border-radius: 15px;
    border: 1px solid transparent;
    padding: 20px 15px;
    @include transition(
      "transform 0.3s ease-in-out , box-shadow 0.3s ease-in-out"
    );
    &:hover {
      transform: scale(1.01);
      box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    }
    @include breakpoint-down("xs") {
      padding: 10px;
    }

    & > .circle {
      svg > path {
        fill: $primary;
      }
    }
  }
  &--h2 {
    color: getTheme(text);
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
    font-weight: 600;
    @include breakpoint-down("md") {
      margin-bottom: 2rem;
    }
    @include breakpoint-down("xs") {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  &--h4 {
    font-size: $fontSize-large;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
    color: getTheme(text);
  }
  &--p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #979797;
  }
}

:root[data-theme="dark"] .serviceCards {
  &--box {
    border-color: transparent;
    @include transition("border-color 0.5s ease-in ");
    @include box-shadow("none");

    & > .circle {
      svg > path {
        fill: $white;
        @include transition("fill 0.7s ease-in ");
      }
      border-color: transparent;
      @include box-shadow("none");
      @include transition("border-color 0.7s ease-in ");
    }
    &:hover .circle {
      border-color: $primary;
    }
    &:hover {
      border-color: $primary;
      @include box-shadow("none");
      transform: none;
    }

    @include breakpoint-down("xs") {
      padding: 10px;
    }
  }
}
