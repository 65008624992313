// ======================================================
// Layout :: Footer
// ======================================================
@import "selectNumber";

.footer {
  background-color: #181923;
  &--top {
    flex-flow: column;
    .btn {
      padding: 6px 36px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: normal;
      color: $white;
      @include breakpoint-up("xs") {
        padding: 4px 25px;
      }
    }
  }

  .menu {
    &--navlist {
      @include display(flex);
      justify-content: flex-start;
      align-items: center;
      flex-flow: column;

      .title {
        font-size: $fontSize-medium;
        color: $gray-27;
        &::after {
          display: block;
          content: "";
          width: 40%;
          margin-left: 2%;
          padding-top: 3px;
          border-bottom: 1px solid $gray-27;
        }
        @include breakpoint-down("xs") {
          font-size: $fontSize-base;
        }
      }
      &--ul {
        @include list-unstyled();
        @include display(flex);
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        &--li {
          color: $gray-08;
          &--a {
            font-size: 15px;
            line-height: 17px;
            @include on-event() {
              color: $gray-05;
            }
            @include breakpoint-down("xs") {
              font-size: $fontSize-base;
              line-height: 16px;
            }
          }
        }
      }
      @include breakpoint-between("sm", "lg") {
        margin-left: 40px;
        align-items: flex-start;
      }

      @include breakpoint-down("sm") {
        // margin-left: 40px;
        align-items: center;
        &--ul {
          align-items: center;
        }
      }
    }
  }
  &--subscribe {
    @include display(flex);
    padding: 3px;
    align-items: center;
    border-radius: 30px;
    color: $black;
    background-color: $white;
    .icon {
      font-size: 20px;
      margin-left: 10px;
      @include breakpoint-down("xs") {
        font-size: 18px;
      }
    }

    &--btn {
      font-size: $fontSize-base;
      padding: 6px 30px;
      border-radius: 30px;

      @include breakpoint-down("xs") {
        font-size: $fontSize-small;
        padding: 4px 28px;
      }
    }
    &--input {
      font-size: 0.9rem;
      align-items: center;
      font-weight: 400;
      line-height: 1em;
      letter-spacing: 0.00938em;
      margin-left: 10px;
      color: $black;
      @include breakpoint-down("md") {
        line-height: 1rem;
        font-size: 0.7rem;
      }
    }
  }
  form {
    @include on-event-after(" .icon > svg") {
      @include transition("color 0.3s ease-in");
      color: $primary;
    }
  }

  &--bottom {
    border-top: 1px solid #afafaf42;
    &--copyright {
      font-size: $fontSize-base;
      color: #6f6f79;
      flex-flow: row;

      .copyright {
        color: #6f6f79;
      }
      .copyright-links {
        & > a {
          color: #6f6f79;
          @include on-event() {
            color: #8f8f8f;
            text-decoration: underline;
          }
        }
      }

      @include breakpoint-down("xs") {
        flex-flow: column;
        font-size: 13px;
      }
    }
  }
}

:root[data-theme="dark"] .footer {
  background-color: #111;
}
