// ======================================================
// Layout :: Wordpress Widget
// ======================================================

// Include components
// ------

@import "info";

.csvMapper {
  .banner {
    background-image: linear-gradient(
        0deg,
        rgba(24, 25, 35, 0.4),
        rgba(24, 25, 35, 0.4)
      ),
      url("../../../assets/images/resources/csvmapper.webp");
  }
}
