// ======================================================
// Base :: Colors
// ======================================================

$primary: #ac1ede;
$textColor: white;
$secondary: #181923;
$light: #2f2f2f;
$black: #000;
$black-01: #252525;
$black-02: #505050;
$black-03: #222222;
$black-04: #3c3c3c;
$black-05: #414141;
$black-06: #2f3c4c;
$black-07: #333333;
$black-08: #2b2b2b;
$white: #fff;
$white-01: #f0f0f0;
$white-02: #ededed;
$white-03: #fdfefe;
$white-04: #e8e8e8;
$white-05: #f3f3f3;
$white-06: #fafafa;
$gray-01: #3d3d3d;
$gray-02: #acacac;
$gray-03: #d8d8d8;
$gray-05: #a4a4a4;
$gray-06: #454545;
$gray-07: #cbcbcb;
$gray-08: #eaeaea;
$gray-09: #d2d2d2;
$gray-10: #434343;
$gray-11: #dadada;
$gray-12: #ebebeb;
$gray-13: #d1d1d1;
$gray-14: #d7d7d7;
$gray-15: #cccccc;
$gray-16: #636363;
$gray-17: #202020;
$gray-18: #c9c9c9;
$gray-20: #e9e9e9;
$gray-21: #7f7f7f;
$gray-22: #e7e7e7;
$gray-23: #d5d5d5;
$gray-24: #f2f2f2;
$gray-25: #eeeeee;
$gray-26: #bebebe;
$gray-27: #888889;
$gray-28: #515151;
$gray-30: #e3e3e3;
$gray-31: #818181;
$gray-32: #b6b6b6;
$gray-33: #ececec;
$gray-34: #efefef;
$green-01: #7ed321;
$yellow-01: #f5a623;
$red-01: #f34a38;
$blue-01: #3b5999;
$blue-02: #0077b5;
$blue-03: #55acee;
